import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    chatBot: 'hub',
}

const ChatBot = createSlice({
  name: 'chatBot',
  initialState: initialState,
  reducers: {
    setRouteChatBot (state, action) {
        state.chatBot = action.payload;
    }
  },
});

export const { setRouteChatBot } = ChatBot.actions;
export default ChatBot.reducer;