import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/AppComponent/Navbar/Navbar";
import Conversation from "../../../Components/AppComponent/Conversation/Conversation";
import { Button, Dropdown, Menu } from "antd";
import Profile from "../../../Assets/Conversation/Circle.png";
import ChatInput from "../MyConversation/ChatInput/ChatInput";
import ProfileTab from "../../../Components/AppComponent/ProfileTab/ProfileTab";
import { fetchConversations } from "../../../Redux/Slice/conversationSlice";
import { useDispatch, useSelector } from "react-redux";
import ConversationComponent from "../../../Components/AppComponent/ConversationComponent/ConversationComponent";
import { LoadingOutlined } from "@ant-design/icons";
import { RightOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  Filter,
  LeaveConversation,
  MessageCheck,
  MessageCheckRead,
} from "../../../Assets/Export/Export";
import { LeaveConversations } from "../../../Utils/LeaveConversatio";
import { setSelectChat } from "../../../Redux/Slice/selectedConversation";
import ProfileDrawer from "../../../Components/AppComponent/ProfileDrawer/ProfileDrawer";
import { SolvedConversation } from "../../../Utils/SolvedConversation";
import Icon from "@ant-design/icons";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useLocation, useParams } from "react-router-dom";
import AssignModal from "../../../Components/AppComponent/AssignModal/AssignModal";

function AssigneeConversation() {
  const dispatch = useDispatch();
  const conversation = useSelector((state) => state.conversation);
  const selectChat = useSelector((state) => state.selectChat);
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState("all");
  const [searchActive, setSearchActive] = useState(false);
  const [clear, setClear] = useState(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const [modalVisible, setModalVisible] = useState(false);
  const [type, setType] = useState("allFilter");
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [tagLoading, setTagLoading] = useState(false);
  const [tagData, setTagData] = useState([])
  const [assigneeData, setAssigneeData] = useState([])
  const { name,ID } = useParams();

  const ClientInformation = useSelector(
    (state) => state?.Integration?.client_information
  );

  const avatarMenu = (() => {

      return  <Menu>
    

      <Menu.Item
        onClick={() => {
          setFilter("open");
        }}
        className={`${filter === "open" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "open" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Open Conversation
        </span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setFilter("closed");
        }}
        className={`${filter === "closed" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "closed" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Closed Conversation
        </span>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          setFilter("FACEBOOK");
        }}
        className={`${filter === "FACEBOOK" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "FACEBOOK" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Facebook
        </span>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          setFilter("INSTAGRAM");
        }}
        className={`${filter === "INSTAGRAM" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "INSTAGRAM" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Instagram
        </span>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          setFilter("WHATSAPP");
        }}
        className={`${filter === "WHATSAPP" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "WHATSAPP" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Whatsapp
        </span>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          setFilter("EMAIL");
        }}
        className={`${filter === "EMAIL" ? "bg-slate-400 classForFilter " : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "EMAIL" ? "" : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Email
        </span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setFilter("TELEGRAM");
        }}
        className={`${filter === "TELEGRAM" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "TELEGRAM" ? "" : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Telegram
        </span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setFilter("WEB");
        }}
        className={`${filter === "WEB" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "WEB" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Web
        </span>
      </Menu.Item>
   

    </Menu>
 
      })



  const searchFunction = (value) => {
    let data = [];
    conversation?.conversations?.forEach((item, index) => {
      if (
        (filter === "all" ||
          // filter === "open" ||
          // filter === "closed" ||
          item.status === filter  ||
          item.channel === filter) && item.recordId == ID &&
        item.chatbot_user.name.toLowerCase().includes(value.toLowerCase())
      ) {
        data.push(item);
      }
    });
    setSelectedConversation(data);
  };
  const [selectedConversation, setSelectedConversation] = useState([]);
  useEffect(() => {
    let data = [];
    conversation?.conversations?.forEach((item, index) => {
      let flag = false;
        console.log(item)
      if (
   (item?.agent?.record_id == ID)
      ) {
        data.push(item);
      }
    });
    console.log(data)
    console.log(ID)
    setSelectedConversation(data);

    if(id){
     conversation.conversations.forEach((item,index)=>{
      if(item.id===id){
        dispatch(
          setSelectChat({
            selectedChat: id,
            name: item.chatbot_user?.name,
            recordId: item.agent?.record_id,
            status: item.status,
          })
        );
      }
     })
    }
  }, [conversation, filter,ID]);





  

  return (
    <>
      <div className="flex h-full">
        <div className="w-[300px] flex flex-col">
          <div>
            <div
              className="bg-white p-[24px] flex justify-between items-center"
              style={{
                borderBottom: "1px solid #E6E8EA",
              }}
            >
              {!searchActive ? (
                <>
                  <div className="text-[#01050F] text-xl font-semibold flex justify-center items-center gap-3">
                    <div>{name.toUpperCase()}</div>
                  </div>
                  <div className="flex gap-3">
                    {/* <div className="cursor-pointer">
                      <SearchOutlined
                        className="text-xl"
                        onClick={() => {
                          setSearchActive(true);
                        }}
                      />
                    </div>
                    <Dropdown
                      className="mr-3"
                      overlay={avatarMenu}
                      trigger={["click"]}
                      visible={filterDropdown}
                      onVisibleChange={(event) => {
                        setFilterDropdown(event)
                        if (event === false) {
                          setType("allFilter")
                        }
                      }}
                    >
                      <Icon component={Filter} />
                    </Dropdown> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex gap-7">
                    {/* <Input
                      onChange={(e) => {
                        clearTimeout(clear);
                        setClear(
                          setTimeout(() => {
                            searchFunction(e.target.value);
                          }, 1000)
                        );
                      }}
                      className="w-full h-full flex-1"
                      placeholder="Search in Inbox"
                      style={{
                        border: "none",
                        borderBottom: "1px solid #E6E8EA",
                        width: "220px",
                      }}
                    /> */}

                    <CloseOutlined
                      onClick={() => {
                        setSearchActive(false);
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {/* <Navbar text="All Conversation" />
          </div> */}
          <div className="h-[100%] overflow-x-hidden ">
            <div className="flex flex-col overflow-y-auto sidebar-scroll h-[100%] bg-[#fff] gap-4 ">
              <>
                {selectedConversation.length !== 0 ? (
                  <>
                    {selectedConversation.map((item, index) => {
                      return <Conversation key={index} {...item} />;
                    })}
                  </>
                ) : (
                  <div className="w-full h-full flex justify-center items-center">
                    You have no conversations at the moment
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
        <div className="flex-1 flex-col flex h-full">
          <div className="">
            <div
              className="bg-white p-[20px] flex justify-between"
              style={{
                border: "1px solid #E6E8EA",
              }}
            >
              <div className="flex justify-between w-full">
                <div className="flex gap-2">
                  {
                    <>
                      {selectChat?.selectedChat && (
                        <Button
                          className="flex justify-center items-center gap-1"
                          onClick={() => {
                            SolvedConversation(
                              selectChat?.status === "closed"
                                ? "open"
                                : "closed",
                              selectChat?.selectedChat
                            );
                          }}
                        >
                          <MessageCheck />
                          Mark as{" "}
                          {selectChat?.status === "closed" ? "Open" : "Closed"}
                        </Button>
                      )}

                      {/* {selectChat?.recordId ==
                      localStorage.getItem("user_id") ? (
                        <Button
                          onClick={() => {
                            leave();
                          }}
                          icon={<LeaveConversation />}
                        >
                          Leave Conversation
                        </Button>
                      ) : (
                        ""
                      )} */}
                         {selectChat?.selectedChat &&  <Button
                          className="flex justify-center items-center gap-1"
                          onClick={() => {
                            setModalVisible(true)
                          }}
                        >
                          <MessageCheckRead />
                          Assign Conversation
                        </Button>}
                    </>
                  }
                </div>
                <div
            onClick={()=>{
              setVisible(true)
            
            }}
            className="w-[43px] h-[43px] rounded-full relative cursor-pointer"
          >
            <img style={{
              cursor: "pointer",
              width:"100%",
              borderRadius:"100%"
            }} src={localStorage.getItem("base64Image")?localStorage.getItem("base64Image"):`https://api.dicebear.com/7.x/initials/svg?seed=${ClientInformation?.name}`}/>
          </div>
              </div>
            </div>
          </div>
          {selectChat?.selectedChat ? (
            <ConversationComponent />
          ) : (
            <div className="flex justify-center items-center h-full flex-col">
              <div className="text-[#000] font-bold text-lg">
                Welcome to All Conversation! We’re glad you’re here.
              </div>
              <div className="text-[#242424] text-sm">
                Please select a chat from chat sidebar.{" "}
              </div>
            </div>
          )}
        </div>
        <ProfileDrawer visible={visible} setVisible={setVisible} />
        <AssignModal visible={modalVisible} setVisible={setModalVisible} conversation_id={selectChat?.selectedChat} />
      </div>
    </>
  );
}

export default AssigneeConversation;
