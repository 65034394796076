import React, { useState } from "react";
import Email from "../../../../Assets/Integration/Group (4).png";
import Navbar from "../../../../Components/AppComponent/Navbar/Navbar";
import { Card, Divider, Input, message,Button, Modal, Form } from "antd";
import { CheckOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { UserInfo } from "../../../../Assets/Export/Export";
import axios from "axios";
import { useEffect } from "react";
import MainImage from "../../../../Assets/Integration/Group 1000013781.png";
import First from "../../../../Assets/Integration/div.framer-yfbble.png";
import Second from "../../../../Assets/Integration/div.framer-yfbble (1).png";
import Third from "../../../../Assets/Integration/div.framer-yfbble (2).png";

function EmailIntegration() {
  const [data, setData] = useState({});
  const [active, setActive] = useState(0); 
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    try {
      const res = await axios.post("user/client/save-email/", {
        ...values,
      });
      if (res.data.status) {
        message.success("Integration request sent successfully");
        setVisible(false);
        getData();
      } else {
        message.error("Error while sending request");
      }
    } catch (err) {
      message.error("Error while sending request");
    }
  }
  const getData = async () => {
    try {
      const res = await axios.get(
        "user/client/integration/status?channel=EMAIL"
      );
      setData(res.data.data);
      if(res.data.data.smtp_host!==""){
        setActive(1)
      }
      else{
        setActive(0)
      }
    } catch (err) {
      message.error("Error while fetching data");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Navbar text={"Email Integration"} image />
      <div className="p-3">
        <Card
          title={
            <div className="flex gap-1 items-center">
              <div>
                <img src={Email} />
              </div>
              <div>Email Integration</div>
            </div>
          }
        >
          {
            active === 0 ? 
            <>
            <div className="flex justify-center w-[70%] m-auto  flex-col items-center gap-5">
              <div>
                <img src={MainImage} />
              </div>
              <div className="font-bold text-base">
                Integrate Email with SellSwift
              </div>
              <div className="font-normal text-sm text-center">
              Integrate your email seamlessly to streamline customer communication through the 
              unified SellSwift Inbox experience!
              </div>
              <div className="flex gap-3 justify-center">
                <div className="text-center w-[33%]">
                  <div>
                    <img src={First} />
                  </div>
                  <div>Respond to all private messages from customers</div>
                </div>
                <div className="text-center w-[33%]">
                  <div>
                    <img src={Second} />
                  </div>
                  <div>
                    Ensure responses to customer messages are addressed
                    within a 24-hour timeframe.
                  </div>
                </div>
                <div className="text-center w-[33%]">
                  <div>
                    <img src={Third} />
                  </div>
                  <div>
                    Enhance your efficiency with automated bots streamlining
                    everyday conversations.
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-8">
              <Button
                className="w-[30%] m-auto bg-[#19334E]"
                type="primary"
                onClick={()=>{
                    // setActive(1)
                    setVisible(true)
                }}
              >
                Integrate Email
              </Button>
            </div>
          </>:
           <div className="w-full">
           <div className="flex gap-2 bg-[#E8F2FF] w-[70%] p-3 items-center">
             {data?.status === "Connected" ? (
               <>
                 <div className="text-[#fff] bg-[#58cb6a] rounded-full w-6 h-6 flex justify-center items-center">
                   <CheckOutlined />
                 </div>
                 <div className="text-base font-bold">
                   Congratulations! Your Email has been seamlessly integrated
                   with SellsSwift
                 </div>
               </>
             ) : (
               <>
                 <div className="text-[#ffeb66] rounded-full text-xl flex justify-center items-center">
                   <ClockCircleOutlined />
                 </div>
                 <div className="text-base font-bold">
                   Email integration pending for approval. Contact Support
                 </div>
               </>
             )}
           </div>
           <div className="mt-5">
             <div className="flex items-center">
               <UserInfo /> Account
             </div>
             <Divider />
             <div className="flex flex-col gap-3">
             <div className="flex gap-2">
                 <div className="w-[20%] font-bold">SMTP HOST </div>
                 <Input value={data?.smtp_host} disabled />
               </div>

               <div className="flex gap-2">
                  <div className="w-[20%] font-bold">SMTP PORT</div>
                  <Input value={data?.smtp_port} disabled />
                </div>

             <div className="flex gap-2">
                <div className="w-[20%] font-bold">SMTP USERNAME</div>
                <Input value={data?.smtp_username} disabled />
              </div>

              <div className="flex gap-2">
                  <div className="w-[20%] font-bold">SMTP PASSWORD</div>
                  <Input value={data?.smtp_password} disabled />
                </div>

                <div className="flex gap-2">
                  <div className="w-[20%] font-bold">SMTP FROM EMAIL</div>
                  <Input value={data?.smtp_from_email} disabled />
                </div>
                

               <div className="flex gap-2">
                 <div className="w-[20%] font-bold">Integration Status</div>
                 <div
                   className={`text-left font-bold ${
                     data?.status === "Connected"
                       ? "text-[#58cb6a]"
                       : "text-red-700"
                   }`}
                 >
                   {data?.status === "Connected" ? data?.status : "Pending"}
                 </div>
               </div>

               <div>
                  <Button
                    className="w-[10%] m-auto bg-[#19334E]"
                    type="primary"
                    onClick={()=>{
                        setVisible(true)
                        form.setFieldsValue({
                          ...data
                        })
                    }}
                  >
                    Edit
                  </Button>

               </div>
             </div>
           </div>
         </div>
          }
        </Card>
      </div>
      <Modal
        title="Email Integration"
        className="modal-for-padding"
        visible={visible}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setVisible(false)
          form.resetFields()
        }}>
          <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Form.Item label="SMTP Host" name="smtp_host">
              <Input placeholder="Enter SMTP Host" />
            </Form.Item>
            <Form.Item label="SMTP PORT" name="smtp_port">
              <Input placeholder="Enter SMTP PORT" />
            </Form.Item>
            <Form.Item label="SMTP USERNAME" name="smtp_username">
              <Input placeholder="Enter  SMTP USERNAME "/>
            </Form.Item>
            <Form.Item label="SMTP PASSWORD" name="smtp_password">
              <Input placeholder="Enter SMTP PASSWORD" />
            </Form.Item>
            <Form.Item label="SMTP FROM EMAIL" name="smtp_from_email">
              <Input placeholder="Enter SMTP FROM EMAIL" />
            </Form.Item>
          
          </Form>
        </Modal>
    </>
  );
}

export default EmailIntegration;
