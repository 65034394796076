import React from 'react'
import { Checkbox, Form, Input } from "antd";


const CheckboxGroup = Checkbox.Group;

function Steps2() {
    
  return (
    <div>
    <div className="flex flex-col justify-center items-center mt-7 gap-3">
      <div className="text-xl font-medium">
        Shape your experience-it will only take few steps.
      </div>
      <div className="text-3xl font-extrabold mt-6 mb-12">
      Optimise Logoipsum for your company
      </div>
      <div className="w-[60%]">
        <div className="w-[100%]">
          <div className="text-xl font-bold mb-2">
            In SellSwift I primarily want to...?
          </div>
          <Form.Item>
            <div className="text-lg font-medium">
              <CheckboxGroup className="flex justify-between flex-wrap gap-4">
                {RadioMap.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="bg-[#F5F7F9] rounded-md p-[15px] w-[49%]"
                    >
                      <Checkbox value={item.id}>{item.value}</Checkbox>
                    </div>
                  );
                })}
              </CheckboxGroup>
            </div>
          </Form.Item>
        </div>
        <div className="w-[100%]">
          <div className="text-xl font-bold mb-2">
          What is your company size?
          </div>
          <Form.Item>
            <div className="text-lg font-medium">
              <CheckboxGroup className="flex justify-between flex-wrap gap-4">
                {RadioMap2.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="bg-[#F5F7F9] rounded-md p-[15px] w-[49%]"
                    >
                      <Checkbox value={item.id}>{item.value}</Checkbox>
                    </div>
                  );
                })}
              </CheckboxGroup>
            </div>
          </Form.Item>
        </div>
        <div className="w-[100%]">
          <div className="text-xl font-bold mb-2">
          How many inquiries you get each month?
          </div>
          <Form.Item>
            <div className="text-lg font-medium">
              <CheckboxGroup className="flex justify-between flex-wrap gap-4">
                {RadioMap3.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="bg-[#F5F7F9] rounded-md p-[15px] w-[49%]"
                    >
                      <Checkbox value={item.id}>{item.value}</Checkbox>
                    </div>
                  );
                })}
              </CheckboxGroup>
            </div>
          </Form.Item>
        </div>
        <div className="w-[100%]">
          <div className="text-xl font-bold mb-2">
          How was your website built?
          </div>
          <Form.Item>
            <div className="text-lg font-medium">
              <CheckboxGroup className="flex justify-between flex-wrap gap-4">
                {RadioMap4.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="bg-[#F5F7F9] rounded-md p-[15px] w-[49%]"
                    >
                      <Checkbox value={item.id}>{item.value}</Checkbox>
                    </div>
                  );
                })}
              </CheckboxGroup>
            </div>
          </Form.Item>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Steps2


const RadioMap = [
    {
      id: 1,
      value: "Retail",
    },
    {
      id: 2,
      value: "Agency",
    },
    {
      id: 3,
      value: "Online Store",
    },
    {
      id: 4,
      value: "Services",
    },
    {
      id: 5,
      value: "Restaurant & food",
    },
    {
      id: 6,
      value: "Other",
    },
  ];

  const RadioMap2 = [
    {
      id: 1,
      value: "5K+",
    },
    {
      id: 2,
      value: "1K-5K",
    },
    {
      id: 3,
      value: "250-1K",
    },
    {
      id: 4,
      value: "51-250",
    },
    {
      id: 5,
      value: "2-50",
    },
    {
        id:6,
        value:"Only Me"
    }
  ];

  const RadioMap3 = [
    {
        id: 1,
        value: "5K+",
      },
      {
        id: 2,
        value: "1K-5K",
      },
      {
        id: 3,
        value: "250-1K",
      },
      {
        id: 4,
        value: "51-250",
      },
      {
        id: 5,
        value: "2-50",
      },
      {
          id:6,
          value:"I am not sure"
      }
  ]

  const RadioMap4 = [
    {
        id:1,
        value:"Wordpress"
    },
    {
        id:2,
        value:"Shopify"
    },
    {
        id:3,
        value:"Wix"
    },
    {
        id:4,
        value:"Other"
    }
  ]