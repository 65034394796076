import React, { useState } from "react";
import Navbar from "../../../Components/AppComponent/Navbar/Navbar";
import { Button, Card, Divider, Input, message } from "antd";
import { InstallationExport } from "../../../Assets/Export/Export";

function Installation() {
  const [code, setCode] = useState(
    `<iframe id="iframe-sellswift-chatbot" src="https://app.sellswift.ai/chat-widget?bot_id=${localStorage.getItem(
      "widget_client_web_id"
    )}"  title="description"
    onload="setupIframeCommunication()" allowtransparency="true" frameborder="0" scrolling="no"
    style="display: block; border: none; position: fixed;border-radius: 20px; right: 10px; bottom: 10px; width: 120px; height: 120px; opacity: 1; color-scheme: none; background: none transparent !important; margin: 0px; max-height: 100%; max-width: 100vw; transform: translateY(0px); transition: none 0s ease 0s !important; visibility: visible; z-index: 999999999 !important;"></iframe>
  <script>
    function setupIframeCommunication() {
      var iframe = document.getElementById("iframe-sellswift-chatbot");
      iframe.contentWindow.postMessage({ event: 'iframeLoaded' }, '*');
      window.addEventListener('message', function (event) {
        if (event.data && event.data.event === 'functionToTrackCalled') {
          iframe.style.width = "260px";
          iframe.style.height = "200px";
        }
        if (event.data && event.data.event === "functionToExpandIframe") {
          iframe.style.width = "420px";
          iframe.style.height = "630px";
        }
        if (event.data && event.data.event === "functionToReset") {
          iframe.style.width = "120px";
          iframe.style.height = "120px";
        }
        if(event.data && event.data.event === "functionForLeft"){
          document.getElementById("iframe-sellswift-chatbot").style.left = "10px";
          document.getElementById("iframe-sellswift-chatbot").style.right = "auto";
        }
      });
    }
  </script>
    
    `
  );

  const clipboard = () => {


    navigator.clipboard.writeText(code);

    message.success("Copied to clipboard");
  };
  return (
    <div>
      <Navbar
        text={
          <div className="flex justify-center items-center gap-2">
            <div>
              <InstallationExport />
            </div>
            <div>Installation</div>
          </div>
        }
        image
      />
      <Card>
        <div className="font-normal text-base">
          It seems the chat code installation is pending. Select one of the
          installation guides provided below:
        </div>
        <div className="flex gap-6 mt-4">
          <div
            style={{
              paddingRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              height: "50px",
              backgroundColor: "#C6DFFF66",
              padding: "10px 40px",
            }}
          >
            <InstallationExport />
            Manual Installation
          </div>
          <div
            style={{
              borderRight: "1px solid #EAE7E7",
            }}
          ></div>
          <div className="flex-1 flex flex-col gap-2">
            <div className="font-bold text-base">
              Paste this code snippet just before the body tag.{" "}
            </div>
            <div>Code Snippet</div>
            <div>
              <Input.TextArea
                style={{
                  height: "400px",
                }}
                disabled={true}
                value={code}
              />
            </div>
            <div>
              <Button
                onClick={clipboard}
                style={{
                  backgroundColor: "#DAE5FF",
                  color: "#1655F8",
                }}
                type="primary"
              >
                Copy To Clipboard
              </Button>
              {/* <Button>Download</Button> */}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Installation;
