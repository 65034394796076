import React, { useEffect, useState } from "react";
import Tabs from "../../../../../Components/AppComponent/Tabs/Tabs";
import FirstImage from "../../../../../Assets/GetStarted/Group 1000013778.png";
import ScreenForSelector from "../../HomeComponent/ScreenForSelector";
import { useDispatch, useSelector } from "react-redux";
import { setClientInformation, setConfigureCommunicationChannels, setEnableAiSupport, setProgress, setSetupChatWidget, setTestChatbot } from "../../../../../Redux/Slice/IntegrationSlice";
import { UpdateIntegration } from "../../../../../Utils/UpdateIntegration";

function TestChatBot() {
  const [active, setActive] = useState(1);
  const setupChatWidget = useSelector(
    (state) => state?.Integration?.setup_chat_widget
  );
  const configure_communication_channels = useSelector(
    (state) => state?.Integration?.configure_communication_channels
  );
  const enable_ai_support = useSelector(
    (state) => state?.Integration?.enable_ai_support
  );
  const test_chatbot = useSelector((state) => state?.Integration?.test_chatbot);


  const Options = [
    {
      id: 1,
      name: "Review your chatbot here",
      done: test_chatbot?.test_chatbot,
    },
  ];

  const SubOptions = [
    {
      id: 1,
      title: "Explore your latest AI conversational companion.",
      subTitle:
        "Experience our newly developed feature – a chatbot designed to greet and assist every new visitor. Give it a try and let us know your thoughts!",
      buttonText: "Test your chatbot",
      linkText: "Mark as done",
      image: FirstImage,
      buttonClick: () => {
        var app = document.querySelector('#app').__vue__;
        app.hideImage();
      },
      onclick: () => {
        onMarkAsDone("test_chatbot");
      }
    },
  ];

  const dispatch = useDispatch();
  const onMarkAsDone = async (value) => {
    const res = await UpdateIntegration({
      setup_chat_widget: {
        ...setupChatWidget,
      },
      configure_communication_channels: {
        ...configure_communication_channels,
      },
      enable_ai_support: {
        ...enable_ai_support,
      },
      test_chatbot: {
        ...test_chatbot,
        [value]: true,
      },
    });
    dispatch(setProgress(res?.progress));
    localStorage.setItem("progress", (res?.progress));
    dispatch(
      setConfigureCommunicationChannels(
        res?.progress_information?.configure_communication_channels
      )
    );
    dispatch(setEnableAiSupport(res?.progress_information?.enable_ai_support));
    dispatch(setSetupChatWidget(res?.progress_information?.setup_chat_widget));
    dispatch(setTestChatbot(res?.progress_information?.test_chatbot));
    dispatch(setClientInformation(res?.client));
  };
  return (
    <>
      <div className="flex justify-between items-center h-full p-10">
        <div className="flex w-[35%] flex-col bg-[#F3F3F3] rounded-2xl py-[24px] px-[14px] gap-8 h-[400px]">
          {Options.map((item, index) => {
            return (
              <Tabs
                image={item.image}
                active={active}
                setActive={setActive}
                name={item.name}
                key={index}
                id={item.id}
                done={item.done}
              />
            );
          })}
        </div>
        <div className="flex-1 gap-3 flex flex-col justify-center items-center">
          {SubOptions.map((item, index) => {
            return (
              <>
                {active === index + 1 && (
                  <ScreenForSelector
                    key={index}
                    image={item.image}
                    title={item.title}
                    subTitle={item.subTitle}
                    buttonText={item.buttonText}
                    linkText={item.linkText}
                    onclick={item.onclick}
                    buttonClick={item.buttonClick}
                  />
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default TestChatBot;
