import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  TimePicker,
  message,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";

function AddEvent({ visible, setVisible, getData = () => {

}, conversation_id }) {
  const [form] = Form.useForm();
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [momentTime, setMomentTime] = useState("")
  const [address, setAddress] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [otherAddress,setOtherAddress] = useState("");
  const [slots,setSlots] = useState([]);

  const getLocationData = async () => {
    try {
      const res = await axios.get("core/training_model/");
      setLocationData(res.data.training_model.booking_locations_list)
    } catch (err) {
      message.error("Something went wrong");
    }
  }

  useEffect(() => {
    if (conversation_id) {
      console.log(conversation_id)
      form.setFieldsValue({
        email: conversation_id.chatbot_user?.email,
        phone_number: conversation_id.chatbot_user?.whatsapp_id
      })
    }
    getLocationData();
  }, [])

  const handleChangeDate = async(date)=>{
    const changeDate = moment(date,"DD-MM-YYYY").format("YYYY-MM-DD");
    try{
      const res = await axios.get(`booking/get_empty_slots?date=${changeDate}&client_id=${localStorage.getItem("client_id")}`)
      setSlots(res.data.data)
    }catch(err){
      message.error("Cant fetch");
    }
  }

  const onFinish = async (values) => {
    console.log(moment(momentTime, "HH:mm"), moment())
    // LOOK FROM HERE
    if ((moment(date, "DD-MM-YYYY")).isBefore(moment()) && moment(momentTime, "hh:mm a").isBefore(moment())) {
      // console.log(moment(date, "YY").format(), moment());
      message.error("You can't select past time");
      return;
    }

    if (!time) {
      message.error("Please select time");
      return;
    }
    console.log(conversation_id)
    if(otherAddress===""){
      message.error("Please fill the address")
    }
    if(otherAddress==="other"){
      if(address===""){
        message.error("Please fill the address");
        return;
      }
    }
    setLoading(true);
    try {
      const res = await axios.post("booking/", {
        ...values,
        location:otherAddress==="other"?address:otherAddress,
        date: moment(date, "DD-MM-YYYY").format("DD-MM-YYYY"),
        time: momentTime,
        conversation_id: conversation_id?.id
      });
      message.success("Booking created successfully");
      setVisible(false);
      getData();
      form.resetFields();
      setAddress("");
      setOtherAddress("");
      setLocationData([])
      setTime("")
      setMomentTime("");
    } catch (err) {
      message.error("Something went wrong");
    }
    setLoading(false);
  };
  return (
    <div>
      <Modal
        open={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
          setAddress("");
          setOtherAddress("");
          setLocationData([])
          setMomentTime("");
          setTime("");
        }}
        footer={false}
        closeIcon={false}
        width={800}
        centered
      >
        <div
          className="flex p-7 justify-between items-center"
          style={{ borderBottom: "1px solid #efefef" }}
        >
          <h2>Create New Booking</h2>

          <span onClick={() => {
            setVisible(false);
          }} className="text-[red] cursor-pointer text-3xl">&times;</span>
        </div>
        <Form className="p-7" form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name={"title"}
            label="Booking Title"
            rules={[{ required: true, message: "Please enter booking title" }]}
          >
            <Input className="h-12" placeholder="Enter Booking Title" />
          </Form.Item>
          <div className="flex justify-between">
            <Form.Item
              className="w-[46%]"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please Enter Email",
                },
                {
                  type: "email",
                  message: "Please enter valid email"
                }
              ]}
              name={"email"}
            >
              <Input
                placeholder="Enter Email"
                className="w-full h-12" />
            </Form.Item>
            <Form.Item
              className="w-[46%]"
              label="Phone"
              name={"phone_number"}
              rules={[
                {
                  required: true,
                  message: "Please Enter Phone Number",
                },

              ]}
            >
              <Input

                placeholder="Enter Number"
                className="w-full h-12"
              />
            </Form.Item>
          </div>
          <div className="flex justify-between">
            <Form.Item
              className="w-[46%]"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please select date",
                },
              ]}
              name={"date"}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                disabledDate={(current) => {
                  return current && current < moment().startOf("day")
                }}
                onChange={(str, e) => {
                  // handleChangeDate(e);
                  setDate(e)
                }} className="w-full h-12" />
            </Form.Item>
            <Form.Item
              className="w-[46%]"
              label="Time"
              rules={[
                {
                  required: true,
                  message: "Please select time",
                },
              ]}
            >
              <TimePicker
                value={time}
                format={"hh:mm a"}
                onChange={(e, str) => {
                  setTime(e)
                  setMomentTime(moment(str, "hh:mm a").format("HH:mm"))
                }}
                className="w-full h-12"
              />
            </Form.Item>
          </div>

          <div className="flex justify-between items-center  ">
            <Form.Item
              className="w-[46%]"
              label="Location"
              rules={[
                {
                  required: true,
                  message: "Please select location",
                },
              ]}
              // name={"location"}
            >                <Select onChange={(e) => {
              setOtherAddress(e)
            }} placeholder="Enter Location" className="w-full h-12">
                {
                  locationData.map((item) => {
                    return (

                      <Select.Option value={item}>{item}</Select.Option>

                    )
                  })
                }
                <Select.Option value={"other"}>Other</Select.Option>
              </Select>
            </Form.Item>
            {otherAddress === "other" && <div className="w-[46%]">
              <Input className="w-full h-12" placeholder="Enter Location" onChange={(e) => {
                setAddress(e.target.value);

              }} />
            </div>}
          </div>
          <Form.Item
            name={"description"}
            label="Description"
            rules={[
              {
                required: true,
                message: "Please enter description",
              },
            ]}
          >
            <Input.TextArea
              style={{
                resize: "none",
                height: "100px",
              }}
              placeholder="Enter Description"
            />
          </Form.Item>
          <div className="flex justify-end gap-2">
            <Form.Item>
              <Button
                onClick={() => {
                  setVisible(false);
                }}
                type="primary"
                ghost
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button loading={loading} htmlType="submit" className="w-full" type="primary">
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default AddEvent;
