import { Button, Select, Switch, message } from "antd";
import axios from "axios";
import React, { useEffect } from "react";
import { setVisible } from "../../../../../../Redux/Slice/ChatComponent";
import { useDispatch } from "react-redux";

function WidgetVisibilityComponent({ widgetVisibility, setWidgetVisibility, device, setDevice }) {
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    getData();
  }, []);

  const dispatch = useDispatch();

  const getData = async () => {
    try {
      const res = await axios.get("/conversation/widget/settings/");
      setWidgetVisibility(res.data.widget_visibility.display_widget);
      setDevice(res.data.widget_visibility.widget_devices === null ? "all" : res.data.widget_visibility.widget_devices);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const onFinish = async () => {
    setLoading(true);
    try {
      const res = await axios.post("/conversation/widget/settings/", {
        widget_visibility: {
          display_widget: widgetVisibility,
          widget_devices: device,
        },
      });
      message.success("Widget Visibility Updated");
      window.location.reload();
    } catch (err) {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  return (
    <div className="bg-slate-100 p-3 h-[350px]">
      <div className="flex gap-5 p-5">
        <div className="font-normal text-sm  w-[36%]">Display Widget</div>
        <div className="flex flex-col w-[64%] gap-3 text-[#8692A6]">
          <div>
            <Switch
              checked={widgetVisibility}
              onChange={(e) => {
                setWidgetVisibility(e);
                dispatch(setVisible(e));
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex gap-5 p-5">
        <div className="font-normal text-sm  w-[36%]">Devices</div>
        <div className="flex flex-col w-[64%] gap-3 text-[#8692A6]">
          <div>
            <Select
              onChange={(e) => {
                setDevice(e);
              }}
              value={device}
              className="w-full"
            >
              <Select.Option value="all">All Devices</Select.Option>
              <Select.Option value="desktop">Desktop</Select.Option>
              <Select.Option value="mobile">Mobile</Select.Option>
            </Select>
          </div>
        </div>
      </div>

      {/* <div className="mt-5">
        <Button
          type="primary"
          loading={loading}
          onClick={onFinish}
          className="w-36 bg-[#19334E]"
        >
          Save
        </Button>
      </div> */}
    </div>
  );
}

export default WidgetVisibilityComponent;
