import {
  Button,
  Drawer,
  Form,
  Input,
  Menu,
  Modal,
  Select,
  Switch,
  Table,
  message,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import EllipsisDropdown from "../../../../Components/AppComponent/Ellipsoid/EllipsisDropdown";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Navbar from "../../../../Components/AppComponent/Navbar/Navbar";
import { Navigate, useNavigate } from "react-router-dom";
import Home from "../../Home/Home";
import { DASHBOARD } from "../../../../Constants/Routes/RoutesConstant";

function ChatBotAdmin() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [agentData, setAgentData] = useState([]);
  const [visible2, setVisible2] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [firstForm, setFirstForm] = useState(false);

  const [form2] = Form.useForm();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (!firstForm) {
        const res = await axios.post("/user/client/", {
          name: values.client_name,
          description: values.description,
          open_ai_key: values.open_ai_key,
          // google_calendar_key: values.google_calendar_key,
          google_calendar_id: values.google_calendar_id,
        });
        message.success("Client added successfully");
        setVisible(false);

        form.resetFields();
        getData();
      } else {
        const res = await axios.put("/user/client/", {
          client_id: firstForm,
          name: values.client_name,
          description: values.description,
          open_ai_key: values.open_ai_key,
          google_calendar_id: values.google_calendar_id,
          vector_db_chunk_size: values.vector_db_chunk_size,
          training_chunk_size: values.training_chunk_size,
          training_chunk_overlap: values.training_chunk_overlap,
        });

        message.success("Client Updated Successfully");
        setVisible(false);
        setFirstForm(false);
        form.resetFields();
        getData();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error("Error adding client");
    }
  };

  //   {
  //     "record_id": 3,
  //     "name": "test_user_3",
  //     "email": "email@gmail.com",
  //     "phone": "96763116",
  //     "role": "agent",
  //     "is_active": true
  // }

  const handleEdit = async (id) => {
    try {
      const res = await axios.post("/user/agent/update/", {
        record_id: id,
        name: form2.getFieldValue("name"),
        email: form2.getFieldValue("email"),
        phone: form2.getFieldValue("phone"),
        role: form2.getFieldValue("role"),
        is_active: form2.getFieldValue("is_active"),
      });
      message.success("Agent edited successfully");
      setVisible2(false);
      form2.resetFields();
      getAgentData(clientId);
    } catch (err) {
      message.error("Error editing agent");
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.post("/user/agent/delete/", {
        record_id: id,
      });
      message.success("Agent deleted successfully");
      getAgentData(clientId);
    } catch (err) {
      message.error("Error deleting agent");
    }
  };

  const getAgentData = async (id) => {
    setClientId(id);
    try {
      const res = await axios.get(`/user/agent?client_id=${id}`);
      setAgentData(res.data.agents);
    } catch (err) {
      message.error("Error fetching data");
    }
  };

  const onFinish2 = async (values) => {
    if (!typeId) {
      try {
        setLoading(true);
        const res = await axios.post("/user/agent/", {
          name: values.name,
          email: values.email,
          phone: values.phone,
          password: values.password,
          confirm_password: values.confirm_password,
          client_id: clientId,
          role: values.role,
          is_active: values.is_active,
        });
        message.success("Agent added successfully");
        setVisible2(false);
        setLoading(false);
        form2.resetFields();
        getAgentData(clientId);
      } catch (err) {
        message.error(err.response.data.error[0]);
        setLoading(false);
      }
    } else {
      handleEdit(typeId);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "record_id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "WhatsApp ID",
      dataIndex: "whatsapp_id",
    },
    {
      title: "Web ID",
      dataIndex: "web_id",
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <EllipsisDropdown
              menu={
                <Menu>
                  <Menu.Item
                    className="cursor-pointer"
                    onClick={() => {
                      setVisible(true);
                      form.setFieldsValue({
                        client_name: record.name,
                        description: record.description,
                        open_ai_key: record.open_ai_key,
                        google_calendar_id: record.google_calendar_id,
                        vector_db_chunk_size: record.vector_db_chunk_size,
                        training_chunk_size: record.training_chunk_size,
                        training_chunk_overlap: record.training_chunk_overlap,
                      });
                      setFirstForm(record.record_id);
                    }}
                  >
                    <span>
                      <EditOutlined className="mr-2" />
                      Edit
                    </span>
                  </Menu.Item>
                  <Menu.Item
                    className="cursor-pointer"
                    onClick={() => {
                      setVisible1(true);
                      getAgentData(record.record_id);
                    }}
                  >
                    <span>
                      {" "}
                      <EyeOutlined className="mr-2 " />
                      View Details
                    </span>
                  </Menu.Item>
                </Menu>
              }
            />
          </>
        );
      },
    },
  ];

  const columnsAgent = [
    {
      title: "ID",
      dataIndex: "record_id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "User Name",
      dataIndex: "user_name",
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active) {
          return <span className="text-green-500">Active</span>;
        } else {
          return <span className="text-red-500">Inactive</span>;
        }
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (item) => {
        return item?.toUpperCase();
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <>
            <EllipsisDropdown
              menu={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setVisible2(true);
                      setTypeId(record.record_id);
                      form2.setFieldsValue({
                        name: record.name,
                        email: record.email,
                        phone: record.phone,
                        role: record.role,
                        is_active: record.is_active,
                      });
                    }}
                  >
                    <span>
                      {" "}
                      <EditOutlined className="mr-2 " />
                      Edit
                    </span>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      Modal.confirm({
                        title: "Are you sure you want to delete this agent?",
                        okText: "Yes",
                        okType: "danger",
                        className: "modal-for-padding",
                        cancelText: "No",
                        onOk: () => handleDelete(record.record_id),
                      });
                    }}
                  >
                    <span>
                      {" "}
                      <DeleteOutlined className="mr-2 " />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
            />
          </>
        );
      },
    },
  ];

  const getData = async () => {
    try {
      const res = await axios.get("user/client");
      setData(res.data.clients);
    } catch (err) {
      message.error("Error fetching data");
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("user_id") != 3) {
      navigate("/");
    }
    getData();
  }, []);

  return (
    <>
      <Navbar text={"ChatBot Admin"} image />
      <div className="flex flex-col gap-3 w-full p-5">
        <div className="flex justify-between">
          <div className="text-2xl font-bold">Clients</div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
                form.resetFields();
                form.setFieldsValue({
                  vector_db_chunk_size: 4,
                  training_chunk_size: 2000,
                  training_chunk_overlap: 200,
                });
                setFirstForm(false);
              }}
            >
              Add Client
            </Button>
          </div>
        </div>
        <Table columns={columns} dataSource={data} />
        <Modal
          visible={visible}
          onCancel={() => {
            setVisible(false);
          }}
          title="Add Client"
          confirmLoading={loading}
          centered
          onOk={() => {
            form.submit();
          }}
          className="modal-for-padding"
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="Client Name"
              name="client_name"
              rules={[
                {
                  required: true,
                  message: "Please input client name!",
                },
              ]}
            >
              <Input placeholder="Client Name" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input description!",
                },
              ]}
            >
              <Input.TextArea placeholder="Description" />
            </Form.Item>
            <Form.Item
              label="Open AI Key"
              name="open_ai_key"
              rules={[
                {
                  required: true,
                  message: "Please input Open AI Key!",
                },
                // {
                //   pattern: /^[a-zA-Z0-9]+$/,
                //   message: "Open AI Key should be alphanumeric",
                // },
              ]}
            >
              <Input placeholder="Open AI Key" />
            </Form.Item>
            {/* <Form.Item
            label="Google Calendar Key"
            name="google_calendar_key"
            rules={[
              {
                required: true,
                message: "Please input Google Calendar Key!",
              },
              // {
              //   pattern: /^[a-zA-Z0-9]+$/,
              //   message: "Google Calendar Key should be alphanumeric",
              // },
            ]}
          >
            <Input placeholder="Google Calendar Key" />
          </Form.Item> */}
            <Form.Item
              label="Google Calendar ID"
              name="google_calendar_id"
              rules={[
                {
                  required: true,
                  message: "Please input Google Calendar ID!",
                },
              ]}
            >
              <Input placeholder="Google Calendar ID" />
            </Form.Item>
            <Form.Item
              label="Vector DB Chunk Size"
              name="vector_db_chunk_size"
              rules={[
                {
                  required: true,
                  message: "Please input Vector DB Chunk Size!",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Vector DB Chunk Size should be numeric",
                },
              ]}
            >
              <Input placeholder="Vector DB Chunk Size" />
            </Form.Item>
            <Form.Item
              label="Training Chunk Size"
              name="training_chunk_size"
              rules={[
                {
                  required: true,
                  message: "Please input Training Chunk Size",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Training Chunk Size should be numeric",
                },
              ]}
            >
              <Input placeholder="Training Chunk Size" />
            </Form.Item>
            <Form.Item
              label="Training Chunk Overlap"
              name="training_chunk_overlap"
              rules={[
                {
                  required: true,
                  message: "Please input Training Chunk Overlap!",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Training Chunk Overlap should be numeric",
                },
              ]}
            >
              <Input placeholder="Training Chunk Overlap" />
            </Form.Item>
          </Form>
        </Modal>
        <Drawer
          title="Agents"
          placement="right"
          closable={true}
          onClose={() => {
            setVisible1(false);
          }}
          visible={visible1}
          width={1050}
        >
          <div className="text-right mb-3">
            <Button
              type="primary"
              onClick={() => {
                setVisible2(true);
                setTypeId(null);
                form2.resetFields();
               
              }}
            >
              Add Agent
            </Button>
          </div>
          <Table columns={columnsAgent} dataSource={agentData} />
        </Drawer>
        <Modal
          visible={visible2}
          onCancel={() => {
            setVisible2(false);
          }}
          title="Add Agent"
          confirmLoading={loading}
          centered
          onOk={() => {
            form2.submit();
          }}
          className="modal-for-padding"
        >
          <Form form={form2} layout="vertical" onFinish={onFinish2}>
            <Form.Item
              label="Agent Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input agent name!",
                },
              ]}
            >
              <Input placeholder="Agent Name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input email!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input phone!",
                },
              ]}
            >
              <Input placeholder="Phone" />
            </Form.Item>
            <Form.Item
              name="role"
              label="Role"
              rules={[
                {
                  required: true,
                  message: "Please select a role",
                },
              ]}
            >
              <Select placeholder="Select a role">
                <Select.Option value="agent">Agent</Select.Option>
                <Select.Option value="admin">Admin</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Status" name="is_active">
              <Switch defaultChecked />
            </Form.Item>
            {!typeId && (
              <>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name={"confirm_password"}
                  rules={[
                    {
                      required: true,
                      message: "Please input confirm password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
              </>
            )}
          </Form>
        </Modal>
      </div>
    </>
  );
}

export default ChatBotAdmin;
