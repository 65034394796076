import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    conversations: [],
    conversationType:{},
    loadingForChat:false
}


const ChatConversationSlice = createSlice({
    name: "conversation",
    initialState,
    reducers: {
        setConversationsForChat: (state, action) => {
         
            state.conversations = action.payload.messages
            state.conversationType = action.payload.conversation
        },
        setMessages: (state, action) => {
         
            state.conversations.push(action.payload)
        },
        setLoadingForChat: (state, action) => {
            console.log(action.payload);
            state.loadingForChat = action.payload
        }
    }
})

export const { setConversationsForChat,setMessages,setLoadingForChat } = ChatConversationSlice.actions;
export default ChatConversationSlice.reducer;