import React from "react";
import { CompanyLogoForWhiteBg } from "../../Assets/Export/Export";

function Navbar() {
  return (
    <>
      <div className="p-4">
        <CompanyLogoForWhiteBg />
      </div>
    </>
  );
}

export default Navbar;
