import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./AppView.css";
import {
  APPEARANCE,
  CHATBOTWIDGET,
  ALLCONVERSATION,
  DASHBOARD,
  GETTING_STARTED,
  MY_CONVERSATION,
  SCHEDULE,
  SETTINGS,
  SUCCESSFULLYANSWERED,
  TRANSFER,
  UNANSWERED,
  UNASSIGNED,
  WHATSAPP,
  CLOSED,
  CHAT_WIDGET,
  TOTAL_BOOKINGS,
  ALL_BOOKINGS_CONFIRMED,
  MANUAL_BOOKING,
  HUB,
  DATASOURCE,
  TRIAL,
  APPEARANCE_SETTING,
  ASSIGNEE,
  CONFIGURE,
  ADMIN,
  INSTALLATION,
  TRANSLATION_SETTING,
  CHAT_PAGE,
  WHATSAPP_INTEGRATION,
  INSTAGRAM_INTEGRATION,
  FACEBOOK_INTEGRATION,
  EMAIL_INTEGRATION,
  EMAIL,
  FACEBOOK,
  INSTAGRAM,
  WHATSAPP_FILTER,
  TAG,
  ACCOUNT,
  UPCOMING,
  COMPLETED,
  CANCELLED,
  UNREADMESSAGE,
  KEYWORD,
  TELEGRAM,
  TELEGRAM_INTEGRATION,
  ASSIGNEE_AGENT,
  MAXIMUM_INTENT,
  CONVERSATION_MESSAGE,
} from "../../Constants/Routes/RoutesConstant";
import { Layout } from "antd";
import SideBar from "../../Components/AppComponent/Sidebar/SideBar";
import { Content } from "antd/es/layout/layout";
import { LoadingOutlined } from "@ant-design/icons";
import CollapsedSidebar from "../../Components/AppComponent/Sidebar/CollapsedSidebar";
import Settings from "../AppViews/Settings/Settings";
import ChatBot from "./ChatBotTest/ChatBotTest";
import ChatBotTest from "./ChatBotTest/ChatBotTest";
import axios from "axios";
import ProtectedRoute from "../../Utils/ProtectedRoute";
import Dashboard from "./Dashboard/Dashboard";
import SuccessfullyAnsweredTable from "./Tables/SuccessfullyAnsweredTable/SuccessfullyAnsweredTable";
import Unanswered from "./Tables/UnAnsweredTable/UnAnsweredTable";
import TransferredToOperator from "./Tables/TransferredToOperator/TransferredToOperator";
import ClosedConversationOriginal from "./ClosedConversationOriginal/ClosedConversationOriginal";
import CollapsedSidebarDashboard from "../../Components/AppComponent/Sidebar/CollapsedSidebarDashboard";
import CollapsedSidebarChatBot from "../../Components/AppComponent/Sidebar/CollapsedSidebarChatBot";
import Hub from "./ChatBotTest/Hub/Hub";
import DataSourceTable from "./ChatBotTest/DataSourceTable/DataSourceTable";
import Trial from "./ChatBotTest/Trial/Trial";
import CollapsedSidebarChatSetting from "../../Components/AppComponent/Sidebar/CollapsedSidebarSetting";
import Appearence from "./Settings/SettingComponents/Appearence/Appearence";
import ChatBotAgent from "./ChatBotTest/ChatBotAgent/ChatBotAgent";
import Configure from "./Settings/SettingComponents/Configure";
import ChatBotAdmin from "./ChatBotTest/ChatBotAdmin/ChatBotAdmin";
import Installation from "./Installation/Installation";
import ChatPage from "./ChatPage/ChatPage";
import ChatWidget from "./Home/Selector/ChatWidget/ChatWidget";
import ChatWidgets from "./ChatWidget";
import WhatsappIntegration from "./Settings/WhatsAppIntergration/WhatsappIntegration";
import { Integration } from "../../Utils/Integration";
import { useDispatch, useSelector } from "react-redux";
import { setClientInformation, setConfigureCommunicationChannels, setEnableAiSupport, setProgress, setSetupChatWidget, setTestChatbot } from "../../Redux/Slice/IntegrationSlice";
import InstagramIntegration from "./Settings/InstagramIntegration/InstagramIntegration";
import FacebookIntegration from "./Settings/FacebookIntegration/FacebookIntegration";
import EmailIntegration from "./Settings/EmailIntegration/EmailIntegration";
import Channel from "../../Components/AppComponent/Sidebar/CollapsedSidebarOption.js/Channel";
import ChannelFilter from "./Channel/Channel";
import Account from "./Settings/Account/Account";
import BookingTable from "./BookingTable/BookingTable";
import UndreadMessage from "./UnreadMessage/UndreadMessage";
import Tag from "./Settings/Tag/Tag";
import KeyWords from "./Settings/SettingComponents/KeyWords/KeyWords";
import TelegramIntegration from "./Settings/TelegramIntegration/TelegramIntegration";
import AssigneeConversation from "./AssigneeConversation/AssigneeConversation";
import MaximumIntent from "./Settings/SettingComponents/MaximumIntent/Maximum";
import ConversationMessage from "./Settings/SettingComponents/ConversationMessage/ConversationMessage";

const Home = lazy(() => import("../AppViews/Home/Home"));
const MyConversation = lazy(() =>
  import("../AppViews/MyConversation/MyConversation")
);
const Unassigned = lazy(() => import("../AppViews/UnAssigned/Unassigned"));
const ClosedConversation = lazy(() =>
  import("../AppViews/ClosedConversation/ClosedConversation")
);
const Schedule = lazy(() => import("../AppViews/Schedule/Schedule"));
const Setting = lazy(() => import("../AppViews/Settings/Settings"));

function AppView() {
  const [collapsed, setCollapsed] = useState(true);
  const [dashboard, setDashboard] = useState(true);
  const [ChatBotWidget, setChatBotWidget] = useState(true);
  const [settingCollapsed, setSettingCollapsed] = useState(true);
  const shouldDisplaySidebar = !window.location.href.includes(WHATSAPP) && !window.location.href.includes(CHAT_WIDGET);


  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_name");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        localStorage.removeItem("progress");

        window.location.reload();
      }
      return Promise.reject(error);
    }
  );



  const dispatch = useDispatch();
  
  useEffect(async()=>{
    const res = await Integration();
    dispatch(setProgress(res?.progress));
    localStorage.setItem("progress", (res?.progress));
    dispatch(setConfigureCommunicationChannels(res?.progress_information?.configure_communication_channels));
    dispatch(setEnableAiSupport(res?.progress_information?.enable_ai_support));
    dispatch(setSetupChatWidget(res?.progress_information?.setup_chat_widget));
    dispatch(setTestChatbot(res?.progress_information?.test_chatbot));
    dispatch(setClientInformation(res?.client));
  },[])

  return (
    <Router>

      <Layout style={{ height: "100vh" }}>
        {shouldDisplaySidebar && (
          <>
            <SideBar collapsed={collapsed} setCollapsed={setCollapsed}
              dashboard={dashboard} setDashboard={setDashboard} 
              ChatBotWidget={ChatBotWidget} setChatBotWidget={setChatBotWidget}
              settingCollapsed={settingCollapsed} setSettingCollapsed={setSettingCollapsed}
            />
            <CollapsedSidebar collapsed={collapsed} setCollapsed={setCollapsed} />
            <CollapsedSidebarDashboard collapsed={dashboard} setCollapsed={setDashboard} />
            <CollapsedSidebarChatBot collapsed={ChatBotWidget} setCollapsed={setChatBotWidget} />
            <CollapsedSidebarChatSetting collapsed={settingCollapsed} setCollapsed={setSettingCollapsed} />

          </>
         )} 
        <Layout className="app-layout">
          <Content>
            <Suspense
              fallback={
                <div className="w-full h-full flex justify-center items-center">
                  <LoadingOutlined />
                </div>
              }
            >
              <Routes>
                <Route path={GETTING_STARTED} element={<Home />} />
                <Route path={MY_CONVERSATION} element={<MyConversation />} />
                <Route path={UNASSIGNED} element={<Unassigned />} />
                <Route path={ALLCONVERSATION} element={<ClosedConversation />} />
                <Route path={CLOSED} element={<ClosedConversationOriginal/>}/>
                <Route path={EMAIL} element={<ChannelFilter/>} />
                <Route path={FACEBOOK} element={<ChannelFilter/>} />
                <Route path={INSTAGRAM} element={<ChannelFilter/>} />
                <Route path={WHATSAPP_FILTER} element={<ChannelFilter/>}/>
                <Route path={TELEGRAM} element={<ChannelFilter/>}/>
                <Route path={SCHEDULE} element={<Schedule />} />
                <Route path={UPCOMING} element={<BookingTable/>}/>
                <Route path={COMPLETED} element={<BookingTable/>}/>
                <Route path={CANCELLED} element={<BookingTable/>}/>
                {/* <Route path={DASHBOARD} element={<Dashboard/>}/> */}
                <Route path={TOTAL_BOOKINGS} element={<Dashboard/>}/>
                <Route path={ALL_BOOKINGS_CONFIRMED} element={<Dashboard/>}/>
                <Route path={MANUAL_BOOKING} element={<Dashboard/>}/>
                <Route path={TAG} element={<Tag/>}/>
                <Route path={ACCOUNT} element={<Account/>}/>
                <Route path={ASSIGNEE_AGENT+"/:name"+"/:ID"} element={<AssigneeConversation/>}/>
                <Route path={MAXIMUM_INTENT} element={<MaximumIntent/>}/>
                <Route path={CONVERSATION_MESSAGE} element={<ConversationMessage/>}/>
                <Route path={HUB} element={<Hub/>}/>
                <Route path={DATASOURCE} element={<DataSourceTable/>}/>
                <Route path={TRIAL} element={<Trial/>}/>


                <Route path={APPEARANCE_SETTING} element={<Appearence/>}/>
                <Route path={ASSIGNEE} element={<ChatBotAgent/>}/>
                <Route path={CONFIGURE} element={<Configure/>}/>
                <Route path={KEYWORD} element={<KeyWords/>}/>
                <Route path={ADMIN} element={<ChatBotAdmin/>}/>
                <Route path={TRANSLATION_SETTING} element={<Installation/>}/>
                <Route path={CHAT_PAGE} element={<ChatPage/>}/>
                <Route path={UNREADMESSAGE} element={<UndreadMessage/>}/>
                <Route path={WHATSAPP_INTEGRATION} element={<WhatsappIntegration/>}/>
                <Route path={INSTAGRAM_INTEGRATION} element={<InstagramIntegration/>}/>
                <Route path={FACEBOOK_INTEGRATION} element={<FacebookIntegration/>}/>
                <Route path={EMAIL_INTEGRATION} element={<EmailIntegration/>}/>
                <Route path={TELEGRAM_INTEGRATION} element={<TelegramIntegration/>}/>

               {/* PROTECTED ROUTE */}
                <Route path={SETTINGS} element={<ProtectedRoute><Setting /></ProtectedRoute>} />
                {/* <Route path={CHATBOTWIDGET} element={<ProtectedRoute><ChatBotTest /></ProtectedRoute>} /> */}
                <Route path={SUCCESSFULLYANSWERED} element={<ProtectedRoute><SuccessfullyAnsweredTable/></ProtectedRoute>}/>
                <Route path={UNANSWERED} element={<ProtectedRoute><Unanswered/></ProtectedRoute>}/>
                <Route path={TRANSFER} element={<ProtectedRoute><TransferredToOperator/></ProtectedRoute>}/>
                <Route path="*" element={<Navigate to={localStorage.getItem("progress")== 100?MY_CONVERSATION:GETTING_STARTED} />} />
                <Route path={WHATSAPP} element={<></>}/>
                <Route path={CHAT_WIDGET} element={<ChatWidgets/>}/>
              </Routes>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
}

export default AppView;
