import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../../../Components/AppComponent/Navbar/Navbar";
import Conversation from "../../../Components/AppComponent/Conversation/Conversation";
import { Button } from "antd";
import Profile from "../../../Assets/Conversation/Circle.png";
import ChatInput from "../MyConversation/ChatInput/ChatInput";
import ProfileTab from "../../../Components/AppComponent/ProfileTab/ProfileTab";
import { useDispatch, useSelector } from "react-redux";
import { fetchConversations } from "../../../Redux/Slice/conversationSlice";
import ConversationComponent from "../../../Components/AppComponent/ConversationComponent/ConversationComponent";
import { LeaveConversation, MessageCheck, MessageCheckRead } from "../../../Assets/Export/Export";
import { LeaveConversations } from "../../../Utils/LeaveConversatio";
import { setSelectChat } from "../../../Redux/Slice/selectedConversation";
import ProfileDrawer from "../../../Components/AppComponent/ProfileDrawer/ProfileDrawer";
import { SolvedConversation } from "../../../Utils/SolvedConversation";
import { useLocation } from "react-router-dom";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import AssignModal from "../../../Components/AppComponent/AssignModal/AssignModal";

function ChannelFilter() {
  const conversation = useSelector((state) => state.conversation);
  const selectChat = useSelector((state) => state.selectChat);
  const [selectedConversation, setSelectedConversation] = useState([]);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [text, setText] = useState("Email");
  const [searchActive, setSearchActive] = useState(false);
  const [clear, setClear] = useState(null);
  const [searchValue, setSearchValue] = useState("")
  const [assignModal, setAssignModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const ClientInformation = useSelector(
    (state) => state?.Integration?.client_information
  );

  useEffect(() => {
    let data = [];
    let channel = "";
    setSearchValue("");
    setSearchActive(false);
    if (location.pathname.includes("email")) {
      channel = "email";
    }
    if (location.pathname.includes("facebook")) {
      channel = "facebook";
    }
    if (location.pathname.includes("instagram")) {
      channel = "instagram";
    }
    if (location.pathname.includes("whatsapp")) {
      channel = "whatsapp";
    }
    if(location.pathname.includes("telegram")){
      channel="telegram"
    }

    conversation?.conversations?.forEach((item, index) => {
      if (item.status === "open" && item.channel == channel.toUpperCase()) {
        data.push(item);
      }
    });
    setSelectedConversation(data);
  }, [conversation, location.pathname]);


  const searchFunction = (value) => {
    let data = [];
    let channel = "";
    if (location.pathname.includes("email")) {
      channel = "email";
    }
    if (location.pathname.includes("facebook")) {
      channel = "facebook";
    }
    if (location.pathname.includes("instagram")) {
      channel = "instagram";
    }
    if (location.pathname.includes("whatsapp")) {
      channel = "whatsapp";
    }
    if (location.pathname.includes("telegram")) {
      channel = "telegram";
    }
    conversation?.conversations?.forEach((item, index) => {
      if (
        item.status === "open" && item.channel == channel.toUpperCase() &&
        item.chatbot_user.name.toLowerCase().includes(value.toLowerCase())
      ) {
        data.push(item);
      }
    });
    setSelectedConversation(data);
  };

  const leave = async () => {
    const res = await LeaveConversations(
      "un-assignment",
      selectChat?.selectedChat
    );
    if (selectChat?.selectedChat) {
      dispatch(setSelectChat({ selectedChat: null }));
    }
  };

  useEffect(() => {
    ChannelFunction();
  }, [location.pathname]);

  const ChannelFunction = () => {
    if (location.pathname.includes("email")) {
      console.log("Email");
      setText("Email");
    }
    if (location.pathname.includes("facebook")) {
      setText("Facebook");
    }
    if (location.pathname.includes("instagram")) {
      setText("Instagram");
    }
    if (location.pathname.includes("whatsapp")) {
      setText("Whatsapp");
    }
    if(location.pathname.includes("telegram")){
      setText("Telegram")
    }
  };

  return (
    <>
      <div className="flex h-full">
        <div className="w-[300px] flex flex-col">
          <div>
            <div
              className={`bg-white  flex justify-between items-center p-[24px]`}
              style={{
                borderBottom: "1px solid #E6E8EA",
              }}
            >
              {!searchActive ? (
                <>
                  <div className="text-[#01050F] text-xl font-semibold flex justify-center items-center gap-3">
                    <div>{text}</div>
                  </div>
                  <div className="flex gap-3">
                    <div className="cursor-pointer">
                      <SearchOutlined
                        className="text-xl"
                        onClick={() => {
                          setSearchActive(true);
                        }}
                      />
                    </div>

                  </div>
                </>
              ) : (
                <>
                  <div className="flex gap-7">
                    <Input
                      value={searchValue}
                      onChange={(e) => {
                        clearTimeout(clear);
                        setSearchValue(e.target.value)
                        setClear(
                          setTimeout(() => {
                            searchFunction(e.target.value);
                          }, 1000)
                        );
                      }}
                      className="w-full h-full flex-1"
                      placeholder="Search in Inbox"
                      style={{
                        border: "none",
                        borderBottom: "1px solid #E6E8EA",
                        width: "220px",
                      }}
                    />

                    <CloseOutlined
                      onClick={() => {
                        setSearchActive(false);
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="h-[100%] overflow-x-hidden ">
            <div className="flex flex-col overflow-y-auto sidebar-scroll h-[100%] bg-[#fff] gap-4 ">
              <>
                {selectedConversation.length !== 0 ? (
                  <>{selectedConversation.map((item, index) => {
                    return <Conversation key={index} {...item} />;
                  })}</>
                ) :
                  (
                    <div className="w-full h-full flex justify-center items-center">
                      You have no conversations at the moment
                    </div>
                  )}
              </>
            </div>
          </div>
        </div>
        <div className="flex-1 flex-col flex h-full">
          <div className="">
            <div
              className="bg-white p-[20px] flex justify-between"
              style={{
                border: "1px solid #E6E8EA",
              }}
            >
              <div className="flex justify-between w-full">
                <div className="flex gap-2">
                  {
                    <>
                      {selectChat?.selectedChat && (
                        <Button
                          className="flex justify-center items-center gap-1"
                          onClick={() => {
                            SolvedConversation(
                              "closed",
                              selectChat?.selectedChat
                            );
                          }}
                        >
                          <MessageCheck />
                          Mark as Closed
                        </Button>
                      )}
                      {/* {selectChat?.recordId == localStorage.getItem("user_id") ? (
                        <Button onClick={()=>{
                          leave();
                        }} icon={<LeaveConversation />}>
                          Leave Conversation
                        </Button>
                      ) : (
                        ""
                      )} */}
                      {selectChat?.selectedChat &&  <Button
                          className="flex justify-center items-center gap-1"
                          onClick={() => {
                            setModalVisible(true)
                          }}
                        >
                          <MessageCheckRead />
                          Assign Conversation
                        </Button>}
                    </>
                  }
                </div>
                <div
                  onClick={() => {
                    setVisible(true)

                  }}
                  className="w-[43px] h-[43px] rounded-full relative cursor-pointer"
                >
                  <img style={{
                    cursor: "pointer",
                    width: "100%",
                    borderRadius: "100%"
                  }} src={localStorage.getItem("base64Image") ? localStorage.getItem("base64Image") : `https://api.dicebear.com/7.x/initials/svg?seed=${ClientInformation?.name}`} />
                </div>
              </div>
            </div>
          </div>
          {selectChat?.selectedChat ? (
            <ConversationComponent />
          ) : (
            <div className="flex justify-center items-center h-full flex-col">
              <div className="text-[#000] font-bold text-lg">
                Welcome to {
                  text
                } Conversation! We’re glad you’re here.
              </div>
              <div className="text-[#242424] text-sm">
                Please select a chat from chat sidebar.{" "}
              </div>
            </div>
          )}
        </div>
        <ProfileDrawer visible={visible} setVisible={setVisible} />
        <AssignModal visible={modalVisible} setVisible={setModalVisible} conversation_id={selectChat?.selectedChat} />
      </div>
    </>
  );
}

export default ChannelFilter;
