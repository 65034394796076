import { Layout } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CollapsedMenu, Search } from "../../../Assets/Export/Export";
import gsap from "gsap";
import LiveConversation from "./CollapsedSidebarOption.js/LiveConversation";
import Bookings from "./CollapsedSidebarOption.js/Bookings";
import Channel from "./CollapsedSidebarOption.js/Channel";
import SearchCollapsed from "./SearchCollapsed";
import Assignee from "./CollapsedSidebarOption.js/Assignee";

function CollapsedSidebar({ collapsed, setCollapsed }) {
  const option = useRef(null);
  const [search, setSearch] = useState(true);

  useEffect(() => {
    if (collapsed) {
      const twin = gsap.to(`.sidebar${collapsed}`, {
        duration: 0.5,
        width: 0,
        ease: "power3.inOut",
        autoAlpha: 0,
        opacity: 0,
      });

      option.current.style.display = "none";
      return () => {
        twin.kill();
      };
    } else {
      const twin = gsap.to(`.sidebar${collapsed}`, {
        duration: 0.4,
        width: 260,
        ease: "power3.inOut",
        fontSize: "auto",
        autoAlpha: 1,
        onComplete: () => {
          option.current.style.display = "block";
        },
      });

      return () => {
        twin.kill();
      };
    }
  }, [collapsed]);

  return (
    <>
    <div
      className={`sidebar${collapsed} bg-[#21324E] flex-col flex `}
      collapsed={false}
    >
      <div className="flex justify-between items-center p-[19px] chat-collapsed">
        <div className="text-lg font-bold text-[#fff]">Inbox</div>
        <div className="flex justify-center items-center gap-2">
          {/* <div
            onClick={()=>{
              setSearch((search)=>!search)
            }}
          className="cursor-pointer">
              <Search/>
          </div> */}
          <div
            className="cursor-pointer "
            onClick={() => {
              setCollapsed((collapsed) => !collapsed);
              setSearch(true)
            }}
          >
            <CollapsedMenu />
          </div>
        </div>
      </div>
      <div ref={option} className="sidebar-scroll" style={{ height: "100vh" }}>
        <LiveConversation />
        <Bookings />
        <Channel/>
        <Assignee/>
      </div>
     
    </div>
    <div className="relative">
      <SearchCollapsed collapsed={search} setCollapsed={setSearch}/>
      </div>
      </>
  );
}

export default CollapsedSidebar;
