import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../../../../Components/AppComponent/Navbar/Navbar'
import { Button, Table, Tag, message } from 'antd'
import axios from 'axios';
import { ALLCONVERSATION } from '../../../../Constants/Routes/RoutesConstant';
import { useNavigate } from 'react-router-dom';
import Csv from '../../../../Components/AppComponent/CSV/CSV';
import Search from 'antd/es/input/Search';


function TransferredToOperator() {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [exportData, setExportData] = useState([]);
  const [loadingForExport, setLoadingForExport] = useState(false);
  const ref = useRef();

  const getData = async (page, check,pageSize=10,search="") => {
    if (!check) {

      setLoading(true);
    } else {
      setLoadingForExport(true);
    }
    try {
      const response = await axios.get(
        `conversation/metrics/transferred?batch=${page}&pageSize=${check ? total : pageSize}&search=${search}`
      );
      console.log(response.data);
      if (check) {
        if(response?.data?.data.length === 0){
          message.error("No data found");

        }
        setExportData(response?.data?.data);
        setLoadingForExport(false);
        return;
      }
      if(search){
        setTotal(response?.data?.data.length);
      }else{
        setTotal(response?.data?.data_info?.count);
      }
      setData(response?.data?.data);
      setData(
        response?.data?.data?.map((item, index) => {
          return {
            ...item,
            srNo: (page - 1) * pageSize + index + 1,
          };
        })
      );
    } catch (err) {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    getData(page);
  }, []);

  const exportColumns = [
    {
      label: "Sr No",
      key: "srNo",
    },
    {
      label: "Date & Time",
      key: "assigned_at",
    },
    {
      label: "User Name",
      key: "user_name",
    },
    {
      label: "User's message",
      key: "conversation",
    },
    {
      label: "Channel",
      key: "channel",
    },
    {
      label: "Agent",
      key: "agent",
    },
    {
      label: "Assigned By",
      key: "assigned_by",
    },
    {
      label: "Chat Status",
      key: "is_assigned",
    },
    {
      label: "Status",
      key: "conversation_status",

    }
  ]
  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
    },
    {
      title: "Date & Time",
      dataIndex: "assigned_at",
    },
    {
      title: "User Name",
      dataIndex: "user_name",
    },
    {
      title: "Channel",
      dataIndex: "channel",
    },
    {
      title: "User's message",
      dataIndex: "conversation",
      width: "20%",
      render: (record) => {
        return (
          <span style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>{record?.latest_message}</span>
        )
      }
    },
    {
      title: "Agent",
      dataIndex: "agent",
      render: (record) => {
        return record.name
      }
    },
    {
      title: "Assigned By",
      dataIndex: "assigned_by",
      render: (item, record) => {
        return item === "agent" ? record.assigned_by_agent.name : item
      }
    },
    {
      title: "Chat Status",
      render: (record) => {
        let text = record.is_assigned
          ? "Assigned to Agent "
          : "Not Assigned to Agent  ";

        if (record.is_assigned) {
          return <Tag color="green">{text}</Tag>;
        } else {
          return <Tag color="orange">{text}</Tag>;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "conversation_status",
      render: (record) => {
        return record === "open" ? <Tag color="green">Open</Tag> : <Tag color="red">Closed</Tag>
      }

    },
    {
      title: "Action",
      align: "center",
      render: (record) => {
        return (
          <div>
            <Button className="btn btn-primary" onClick={() => {
              navigate(ALLCONVERSATION + "?id=" + record.conversation?.id)
            }}>Go to Chat</Button>
          </div>
        )
      }
    }
  ];

  const handleClick = () => {

    getData(1, true);

  }

  const [searchs, setSearchs] = useState("");

  const handleSearch = (search) => {
    clearTimeout(searchs)
    setSearchs(setTimeout(() => {
      setPage(1)
      getData(1, false, pageSize, search)
    }, 1000))
  }
  useEffect(() => {
    if (exportData.length !== 0) {
      ref.current.link.click();
      setExportData([]);
    }
  }, [exportData])

  return (
    <div>
      <Navbar text={"Transferred To Operator"} back />
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px"
      }}>

        <div>
          <Search placeholder='Search Here' onChange={(e) => {
            handleSearch(e.target.value)
          }} />
        </div>
<Csv header={exportColumns} handleClick={handleClick} refs={ref} loading={loadingForExport} data={
        exportData.map((item,index) => {
          return {
            srNo: index+1,
            assigned_at: item.assigned_at,
            user_name: item.user_name,
            channel: item.channel,
            conversation: item.conversation.latest_message,
            agent: item.agent.name,
            assigned_by: item.assigned_by === "agent" ? item.assigned_by_agent.name : item.assigned_by,
            is_assigned: item.is_assigned ? "Assigned to Agent" : "Not Assigned to Agent",
            conversation_status: item.conversation_status === "open" ? "Open" : "Closed"

          }
        })
      } /></div>
      <div className='p-3'><Table loading={loading} pagination={
        {
          current: page,
          pageSize: pageSize,
          showSizeChanger:true,
          total: total,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
            getData(page,false,pageSize);
          }
        }} dataSource={data} columns={columns} /></div>
    </div>
  )
}

export default TransferredToOperator