import React, { useEffect, useRef, useState } from "react";
import Tabs from "../../../../../Components/AppComponent/Tabs/Tabs";
import FirstImage from "../../../../../Assets/GetStarted/Selector1.png";
import SecondImage from "../../../../../Assets/GetStarted/Group 1000013764.png";
import { Button } from "antd";
import gsap from "gsap";
import useGsap from "../../../../../Hooks/useGsap";
import { useDispatch, useSelector } from "react-redux";
import { UpdateIntegration } from "../../../../../Utils/UpdateIntegration";
import {
  setClientInformation,
  setConfigureCommunicationChannels,
  setEnableAiSupport,
  setProgress,
  setSetupChatWidget,
  setTestChatbot,
} from "../../../../../Redux/Slice/IntegrationSlice";
import { APPEARANCE_SETTING, INSTAGRAM_INTEGRATION, TRANSLATION_SETTING } from "../../../../../Constants/Routes/RoutesConstant";
import { useNavigate } from "react-router-dom";

function ChatWidget() {
  const [active, setActive] = useState(1);
  const Screen = useRef(null);
  const setupChatWidget = useSelector(
    (state) => state?.Integration?.setup_chat_widget
  );
  const configure_communication_channels = useSelector(
    (state) => state?.Integration?.configure_communication_channels
  );
  const enable_ai_support = useSelector(
    (state) => state?.Integration?.enable_ai_support
  );
  const test_chatbot = useSelector((state) => state?.Integration?.test_chatbot);

  useEffect(() => {
    if (
      setupChatWidget?.add_chat_widget &&
      !setupChatWidget?.simulate_first_conversation
    ) {
      setActive(2);
    }
    if (
      setupChatWidget?.simulate_first_conversation &&
      !setupChatWidget?.add_chat_widget
    ) {
      setActive(1);
    }
  }, [setupChatWidget]);

  const Options = [
    {
      id: 1,
      name: "Add chat widget to your website",
      done: setupChatWidget?.add_chat_widget,
    },
    {
      id: 2,
      name: "Simulate your first conversation",
      done: setupChatWidget?.simulate_first_conversation,
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onMarkAsDone = async (value) => {
    const res = await UpdateIntegration({
      setup_chat_widget: {
        ...setupChatWidget,
        [value]: true,
      },
      configure_communication_channels: {
        ...configure_communication_channels,
      },
      enable_ai_support: {
        ...enable_ai_support,
      },
      test_chatbot: {
        ...test_chatbot,
      },
    });
    dispatch(setProgress(res?.progress));
    localStorage.setItem("progress", (res?.progress));
    dispatch(
      setConfigureCommunicationChannels(
        res?.progress_information?.configure_communication_channels
      )
    );
    dispatch(setEnableAiSupport(res?.progress_information?.enable_ai_support));
    dispatch(setSetupChatWidget(res?.progress_information?.setup_chat_widget));
    dispatch(setTestChatbot(res?.progress_information?.test_chatbot));
    dispatch(setClientInformation(res?.client));
  };

  useGsap(
    Screen.current,
    { duration: 0.5, opacity: 0, y: 100, ease: "ease-in" },
    [active]
  );

  return (
    <>
      <div className="flex justify-between items-center h-full p-10">
        <div className="flex flex-col  w-[42%]  align-top bg-[#F3F3F3] rounded-2xl py-[24px] px-[14px] gap-8 h-[400px]">
          {Options.map((item, index) => {
            return (
              <Tabs
                image={item.image}
                active={active}
                setActive={setActive}
                name={item.name}
                key={index}
                id={item.id}
                done={item.done}
              />
            );
          })}
        </div>
        <div
          ref={Screen}
          className="flex-1 gap-3 flex flex-col justify-center items-center"
        >
          {active === 1 && (
            <>
              <div>
                <img src={FirstImage} />
              </div>
              <div className="font-bold text-2xl">
                Add chat widget to your website
              </div>
              <div className="font-normal text-sm text-center w-[86%]">
                Implementing a chat widget is crucial for fostering customer
                engagement through real-time conversations, enabling the
                seamless integration of chatbots, and unlocking the potential of
                AI-driven support features.
              </div>
              <div>
                <Button
                  type="primary"
                  onClick={()=>{
                    navigate(TRANSLATION_SETTING);
                  }}
                  className="bg-[#19334E] border-none py-[4px] px-[10px] w-48 text-[#fff] font-normal text-base"
                >
                  Setup Chat Widget
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    onMarkAsDone("add_chat_widget");
                  }}
                  className="bg-none underline w-48 text-[#19334E] font-normal text-base"
                >
                  Mark as done
                </Button>
              </div>
            </>
          )}
          {active === 2 && (
            <>
              <div>
                <img src={SecondImage} />
              </div>
              <div className="font-bold text-2xl">
                Start a test conversation
              </div>
              <div className="font-normal text-sm text-center w-[86%]">
                "Explore an example of a customer interaction in a conversation
                scenario."
              </div>
              <div>
                <Button
                  type="primary"
                  onClick={()=>{
                    var app = document.querySelector('#app').__vue__;
                    app.hideImage();
                  }}
                  className="bg-[#19334E] border-none py-[4px] px-[10px] w-48 text-[#fff] font-normal text-base"
                >
                  Simulate a Conversation
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    onMarkAsDone("simulate_first_conversation");
                  }}
                  className="bg-none underline w-48 text-[#19334E] font-normal text-base"
                >
                  Mark as done
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ChatWidget;
