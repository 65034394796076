import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../../Components/AppComponent/Navbar/Navbar";
import { Button, Table, Tag, message } from "antd";
import axios from "axios";
import { ALLCONVERSATION } from "../../../../Constants/Routes/RoutesConstant";
import { useNavigate } from "react-router-dom";
import Csv from "../../../../Components/AppComponent/CSV/CSV";
import Search from "antd/es/input/Search";

function SuccessfullyAnsweredTable() {
  const [exportData, setExportData] = useState([]);
  const [loadingForExport, setLoadingForExport] = useState(false);
  const ref = useRef();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const getData = async (page,check,pageSize=10,search="") => {
    if (!check) {

      setLoading(true);
    } else {
      setLoadingForExport(true);
    }
    try {
      const response = await axios.get(
        `conversation/metrics/successful_messages?batch=${page}&pageSize=${check ? total : pageSize}&search=${search}`
      );
      if (check) {
        if(response?.data?.data.length === 0){
          message.error("No data found");

        }
        setExportData(response?.data?.data);
        setLoadingForExport(false);
        return;
      }
      if(search){
        setTotal(response?.data?.data.length);
      }else{
        setTotal(response?.data?.data_info?.count);
      }
      // setTotal(response.data?.data_info?.total_batch_count);
      setTotal(response?.data?.data_info?.count);
      setData(response?.data?.data);
      setData(
        response?.data?.data?.map((item, index) => {
          return {
            ...item,
            srNo: (page - 1) * pageSize + index + 1,
          };
        })
      );
    } catch (err) {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    getData(page);
  }, []);


  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
    },
    {
      title: "Date & Time",
      dataIndex: "created_at_formatted",
    },
    {
      title: "User Name",
      dataIndex: "user_name",
    },
    {
      title: "Channel",
      dataIndex: "channel",
    },
    {
      title: "User's message",
      dataIndex: "message",
      width: "20%",
      render: (text) => {
        return (
          <span style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>{text}</span>
        )
      }
    },
    {
      title: "AI Response",
      dataIndex: "response_message",
      width: "20%",
      render: (text) => {
        return (
          <span style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>{text}</span>
        )
      }
    },

    {
      title: "Chat Status",
      render: (record) => {
        let text = record.is_assigned
          ? "Assigned to Agent "
          : "Not Assigned to Agent  ";
      
        if (record.is_assigned) {
          return <Tag color="green">{text}</Tag>;
        } else {
          return <Tag color="orange">{text}</Tag>;
        }
      },
    },
    {
      title:"Status",
      dataIndex:"conversation_status",
      render:(record)=>{
        return record === "open" ? <Tag color="green">Open</Tag> : <Tag color="red">Closed</Tag>
      }
      
    },
    {
      title:"Action",
      align:"center",
      render:(record)=>{
        return(
          <div>
            <Button className="btn btn-primary" onClick={()=>{
              navigate(ALLCONVERSATION+"?id="+record.conversation?.id)
            }}>Go to Chat</Button>
          </div>
        )
      }
    }
  ];

  const exportColumns = [
    {
      label: "Sr No",
      key: "srNo"
    },
    {
      label: "Date & Time",
      key: "created_at_formatted"
    },
    {
      label: "User Name",
      key: "user_name"
    },
    {
      label: "Channel",
      key: "channel"
    },
    {
      label: "User's message",
      key: "message"
    },
    {
      label: "AI Response",
      key: "response_message"
    },
    {
      label: "Chat Status",
      key: "is_assigned"
    },
    {
      label: "Status",
      key: "conversation_status"
    }
  ]

  const handleClick = () => {

    getData(1, true);

  }

  useEffect(() => {
    if (exportData.length !== 0) {
      ref.current.link.click();
      setExportData([]);
    }
  }, [exportData])

  const [searchs, setSearchs] = useState("");

  const handleSearch = (search) => {
    clearTimeout(searchs)
    setSearchs(setTimeout(() => {
      setPage(1)
      getData(1, false, pageSize, search)
    }, 1000))
  }
  return (
    <div>
      <Navbar text={"Successfully Answered"} back />
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px"
      }}>

        <div>
          <Search placeholder='Search Here' onChange={(e) => {
            handleSearch(e.target.value)
          }} />
        </div>
<Csv header={exportColumns}  handleClick={handleClick} refs={ref} loading={loadingForExport} data={
        exportData.map((item,index) => {
          return {
            srNo: index+1,
            created_at_formatted: item.created_at_formatted,
            user_name: item.user_name,
            channel: item.channel,
            message: item.message,
            response_message: item.response_message,
            is_assigned: item.is_assigned ? "Assigned to Agent" : "Not Assigned to Agent",
            conversation_status: item.conversation_status === "open" ? "Open" : "Closed"
          }
        })
      } /></div>
      <div className="p-3">
        <Table
        loading={loading}
          dataSource={data}
          columns={columns}
          pagination={{
            current: page,
            total: total,
            pageSize: pageSize,
            showSizeChanger:true
          }}
          onChange={(pagination) => {
            setPage(pagination.current);
            setPageSize(pagination.pageSize);
            getData(pagination.current,false,pagination.pageSize);
          }}
        />
      </div>
    </div>
  );
}

export default SuccessfullyAnsweredTable;
