import { Button, Card, Divider, Form, Input, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import Whaatsapp from "../../../../Assets/Integration/telegram 2.png";
import Navbar from "../../../../Components/AppComponent/Navbar/Navbar";
import MainImage from "../../../../Assets/Integration/Frame 1261155002.png";
import First from "../../../../Assets/Integration/div.framer-yfbble.png";
import Second from "../../../../Assets/Integration/div.framer-yfbble (1).png";
import Third from "../../../../Assets/Integration/div.framer-yfbble (2).png";
import { CheckOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { UserInfo } from "../../../../Assets/Export/Export";
import axios from "axios";

function TelegramIntegration() {
  const [active, setActive] = useState(0);
  const [data, setData] = useState({});
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const handleFinish = async (values) => {
    try {
      const res = await axios.post("user/client/save-telegram/", {
        ...values,
      });
      if (res.data.status) {
        message.success("Integration request sent successfully");
        setVisible(false);
        getData();
      } else {
        message.error("Error while sending request");
      }
    } catch (err) {
      message.error("Error while sending request");
    }
  }
  const getData = async () => {
    try {
      const res = await axios.get(
        "user/client/integration/status?channel=TELEGRAM"
      );
      setData(res.data.data);
      
      if(res.data.data.status === "Connected"){
          setActive(1)
      }else{
          setActive(0)
      }
    } catch (err) {
      message.error("Error while fetching data");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Navbar text={"Telegram Integration"} image />
      <div className="p-3">
        <Card
          title={
            <div className="flex gap-1 items-center">
              <div>
                <img src={Whaatsapp} />
              </div>
              <div>Telegram Integration</div>
            </div>
          }
        >
          <>
            {active === 0 && (
              <>
                <div className="flex justify-center w-[70%] m-auto  flex-col items-center gap-5">
                  <div>
                    <img src={MainImage} />
                  </div>
                  <div className="font-bold text-base">
                    Integrate Telegram with SellSwift
                  </div>
                  <div className="font-normal text-sm text-center">
                    "Effortlessly manage your telegram chats within your
                    SellSwift inbox, ensuring swift responses to your customers'
                    inquiries. Streamline your workflow by eliminating the need
                    to toggle between platforms, boosting overall productivity."
                  </div>
                  <div className="flex gap-3 justify-center">
                    <div className="text-center w-[33%]">
                      <div>
                        <img src={First} />
                      </div>
                      <div>Respond to all private messages from customers</div>
                    </div>
                    <div className="text-center w-[33%]">
                      <div>
                        <img src={Second} />
                      </div>
                      <div>
                        Ensure responses to customer messages are addressed
                        within a 24-hour timeframe.
                      </div>
                    </div>
                    <div className="text-center w-[33%]">
                      <div>
                        <img src={Third} />
                      </div>
                      <div>
                        Enhance your efficiency with automated bots streamlining
                        everyday conversations.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-8">
                  <Button
                    className="w-[30%] m-auto bg-[#19334E]"
                    type="primary"
                    onClick={()=>{
                        // setActive(1)
                        setVisible(true)
                    }}
                  >
                    Integrate Telegram
                  </Button>
                </div>
              </>
            )}
            {
                active === 1 && (
             

          <div className="w-full">
            <div className="flex gap-2 bg-[#E8F2FF] w-[70%] p-3 items-center">
              {data?.status === "Connected" ? (
                <>
                  <div className="text-[#fff] bg-[#58cb6a] rounded-full w-6 h-6 flex justify-center items-center">
                    <CheckOutlined />
                  </div>
                  <div className="text-base font-bold">
                    Congratulations! Your Telegram account has been seamlessly
                    integrated with SellSwift
                  </div>
                </>
              ) : (
                <>
                  <div className="text-[#ffeb66] rounded-full text-xl flex justify-center items-center">
                    <ClockCircleOutlined />
                  </div>
                  <div className="text-base font-bold">
                    Telegram integration pending for approval. Contact Support
                  </div>
                </>
              )}
            </div>
            <div className="mt-5">
              <div className="flex items-center">
                <UserInfo /> Account
              </div>
              <Divider />
              <div className="flex flex-col gap-3">
                {/* <div className="flex gap-2">
                  <div className="w-[20%] font-bold">
                    telegram Business Account
                  </div>
                  <Input value={data?.telegram_bussiness_account} disabled />
                </div> */}
                <div className="flex gap-2">
                  <div className="w-[20%] font-bold">Telegram Token</div>
                  <Input value={data?.telegram_token} disabled />
                </div>
                <div className="flex gap-2">
                  <div className="w-[20%] font-bold">Telegram Id</div>
                  <Input value={data?.telegram_id} disabled />
                </div>
                <div className="flex gap-2">
                  <div className="w-[20%] font-bold">Integration Status</div>
                  <div
                    className={`text-left font-bold ${
                      data?.status === "Connected"
                        ? "text-[#58cb6a]"
                        : "text-red-700"
                    }`}
                  >
                    {data?.status === "Connected" ? data?.status : "Pending"}
                  </div>
                </div>

                <div>
                  <Button
                    className="w-[10%] m-auto bg-[#19334E]"
                    type="primary"
                    onClick={()=>{
                        setVisible(true)
                        form.setFieldsValue({
                          ...data,
                         
                        })
                    }}
                  >
                    Edit
                  </Button>

               </div>
              </div>
            </div>
          </div>
              
            )
          }
        </>
        </Card>
      </div>
      <Modal
        title="Telegram Integration"
        className="modal-for-padding"
        visible={visible}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setVisible(false)
          form.resetFields()
        }}>
          <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Form.Item label="Telegram Token" name="telegram_token">
              <Input placeholder="Enter Telegram Token" />
            </Form.Item>
            <Form.Item label="Telegram Id" name="telegram_id">
              <Input placeholder="Enter Telegram Id" />
            </Form.Item>
          </Form>
        </Modal>
    </>
  );
}

export default TelegramIntegration;
