import { Card } from "antd";
import React from "react";
import ChatComponent from "../../../../Components/AppComponent/ChatComponent/ChatComponent";
import Navbar from "../../../../Components/AppComponent/Navbar/Navbar";

function Trial() {
  return (
    <>
    <Navbar text="Trial" image/>
    <div className="flex flex-col gap-3 w-full">
    
      <div className="bg-[#fff] p-3 rounded-lg"
      
      >
          
          <div className="flex ">
              <div className="w-[45%]">
                <ChatComponent/>
              </div>
              <div>

              </div>
          </div>


      </div>
    </div>
    </>
  );
}

export default Trial;
