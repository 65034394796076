import React, { useEffect, useState } from "react";
import Navbar from "../../../../Components/AppComponent/Navbar/Navbar";
import {
  Table,
  Button,
  message,
  Form,
  Modal,
  Input,
  ColorPicker,
  Menu,
} from "antd";
import axios from "axios";
import EllipsisDropdown from "../../../../Components/AppComponent/Ellipsoid/EllipsisDropdown";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

function Tag() {
  const [data, setData] = useState([]);
  const [form2] = Form.useForm();
  const [visible2, setVisible2] = useState(false);
  const [color, setColor] = useState("#000000");
  const [id, setId] = useState(null);
  const column = [
    {
      title: "ID",
      dataIndex: "record_id",
    },
    {
      title: "Tag Title",
      dataIndex: "name",
    },
    {
      title: "Color Code",
      dataIndex: "color",
      render: (text) => (
        <div style={{ textAlign: "center" }}>
          {" "}
          <div
            style={{
              width: 20,
              height: 20,
              backgroundColor: text,
              borderRadius: "100%",
            }}
          ></div>
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (record) => (
        <EllipsisDropdown
          menu={
            <Menu>
              <Menu.Item
                className="cursor-pointer"
                onClick={() => {
                  setId(record.record_id);
                  setVisible2(true);
                  form2.setFieldsValue({
                    name: record.name,
                  });
                  setColor(record.color);
                }}
              >
                <span>
                  <EditOutlined className="mr-2" />
                  Edit
                </span>
              </Menu.Item>
              <Menu.Item
                className="cursor-pointer"
                onClick={() => {
                  Modal.confirm({
                    className: "modal-for-padding",
                    title: "Are you sure you want to delete this file?",
                    onOk() {
                      deleteFile(record.record_id);
                    },
                  });
                }}
              >
                <span>
                  {" "}
                  <DeleteOutlined className="mr-2 " />
                  Delete
                </span>
              </Menu.Item>
            </Menu>
          }
        />
      ),
    },
  ];
  const [loading, setLoading] = useState(false);

  const deleteFile = async (id) => {
    try {
      await axios.delete(`conversation/flags/?record_id=${id}`);

      getData();
      message.success("Tag deleted successfully");
    } catch (err) {
      message.error("Something went wrong");
    }
  };
  const onFinish2 = async (values) => {
    if(id){
      await axios.put(`conversation/flags/`, {
        name: values.name,
        color: color,
        record_id:id
      });
      setVisible2(false);
      form2.resetFields();
      setColor("#000000");
      getData();
      message.success("Tag updated successfully");
      return;
    }
    try {
      setLoading(true);
      await axios.post("conversation/flags/", {
        name: values.name,
        color: color,
      });
      setLoading(false);
      setVisible2(false);
      getData();
      message.success("Tag added successfully");
    } catch (err) {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const getData = async () => {
    try {
      const res = await axios.get("conversation/flags/");
      setData(res.data);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Navbar text={"Tag"} image />
      <div className="p-3">
        <div>
          <div className="flex gap-1 items-center justify-end py-3 px-2">
            {/* <div className="font-extrabold text-2xl">Tags</div> */}
            <div>
              <Button
                onClick={() => {
                  setId(null);
                  form2.resetFields();
                  setColor("#000000");
                  setVisible2(true);
                }}
                type="primary"
              >
                Add New
              </Button>
            </div>
          </div>
        </div>
        <Table dataSource={data} columns={column} />
      </div>
      <Modal
        visible={visible2}
        onCancel={() => {
          setVisible2(false);
        }}
        title="Tag"
        confirmLoading={loading}
        centered
        onOk={() => {
          form2.submit();
        }}
        className="modal-for-padding"
      >
        <Form form={form2} layout="vertical" onFinish={onFinish2}>
          <Form.Item
            label="Tag Title"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input tag title!",
              },
            ]}
          >
            <Input placeholder="Type here" />
          </Form.Item>
          <Form.Item label="Color Code">
            <ColorPicker
              value={color}
              onChange={(color) => {
                setColor(color.toHexString());
              }}
              showText={(color) => <span> ({color.toHexString()})</span>}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Tag;
