import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    settingRoute: 'appearence',
    dashboardRoute: 'totalBookings',


}

const settingSlice = createSlice({
  name: 'setting',
  initialState: initialState,
  reducers: {
    setRoute (state, action) {
        state.settingRoute = action.payload;
    },
    setDashboardRoute (state, action) {
        state.dashboardRoute = action.payload;
    }
  },
});

export const { setRoute,setDashboardRoute } = settingSlice.actions;
export default settingSlice.reducer;