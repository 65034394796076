import { Layout, Progress } from "antd";
import React, { useEffect } from "react";
import {
  ChatBot,
  Graph,
  InboxIcon,
  Rocket,
  Setting,
  Setup,
} from "../../../Assets/Export/Export";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ALLCONVERSATION,
  APPEARANCE,
  APPEARANCE_SETTING,
  CHATBOTWIDGET,
  DASHBOARD,
  GETTING_STARTED,
  HUB,
  MY_CONVERSATION,
  SETTINGS,
  TOTAL_BOOKINGS,
  TRANSLATION_SETTING,
} from "../../../Constants/Routes/RoutesConstant";
import { useSelector } from "react-redux";

function SideBar({ collapsed, setCollapsed, setDashboard, dashboard, ChatBotWidget, setChatBotWidget,
  settingCollapsed, setSettingCollapsed
}) {
  const { Sider } = Layout;
  const navigate = useNavigate();
  const location = useLocation();

  const progress = useSelector((state) => state?.Integration?.progress);
  // console.log(progress);

  useEffect(()=>{
    if(location.pathname===HUB || location.pathname===APPEARANCE_SETTING|| location.pathname===TRANSLATION_SETTING ){
      var app = document.querySelector('#app').__vue__;
      app.showForSellSwift();
    }else{
      var app = document.querySelector('#app').__vue__;
      app.hideForSellSwift();
    }
  },[location.pathname])

  const sidebarTab = [
    {
      icon: <InboxIcon />,
      click: () => {
        setCollapsed((collapsed) => !collapsed);
        setDashboard(true);
        setChatBotWidget(true);
        setSettingCollapsed(true);
        navigate(MY_CONVERSATION);
      },
      active: collapsed === false,
    },
    // ...((localStorage.getItem("user_id") == 3 ||
    // localStorage.getItem("role") === "admin")
    //   ? [
    //       {
    //         icon: <Setup />,
    //         click: () => {
    //           setDashboard((dashboard) => !dashboard);
    //           setCollapsed(true);
    //           setChatBotWidget(true);
    //           setSettingCollapsed(true);
    //           navigate(TOTAL_BOOKINGS);
    //         },
    //         active: dashboard === false,
    //       },
    //     ]
    //   : []),
      ...(
        (localStorage.getItem("user_id") == 3 ||
        localStorage.getItem("role") === "admin")
          ? [
              {
                icon: <ChatBot />,
                click: () => {
                  setChatBotWidget((ChatBotWidget) => !ChatBotWidget);
                  setCollapsed(true);
                  setDashboard(true);
                  setSettingCollapsed(true);
                  navigate(HUB);
                },
                active: ChatBotWidget === false,
              },
            ]
          : []
      ),
    // ...(
    //   (localStorage.getItem("user_id") == 3 ||
    //   localStorage.getItem("role") === "admin")
    //     ? [
    //         {
    //           icon: <Graph />,
    //           click: () => {
    //             setCollapsed(true);
    //           },
    //         },
    //       ]
    //     : []
    // )
  ];

  return (
    <Sider className=" flex-col flex justify-between" collapsed={false}>
      <div className="flex-1">
        {sidebarTab.map((item, index) => {
          return (
            <div
              key={index}
              className={`flex justify-center p-4 sidebar-tab-container hover:bg-[#1C2B45] ${
                item.active && "siderbar-tab-container-active"
              }`}
            >
              <div className="flex justify-center items-center">
                <div
                  onClick={item.click}
                  className="flex justify-center items-center mb-2"
                >
                  {item.icon}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col">
       {
        (localStorage.getItem("user_id") == 3 || localStorage.getItem("role") === "admin")
        &&

        <div>
          <div className="flex justify-center flex-col items-center hover:bg-[#1C2B45]">
            <div className="rounded-full cursor-pointer">
              <Link 
                onClick={()=>{
                  setSettingCollapsed((settingCollapsed) => !settingCollapsed);
                  setCollapsed(true);
                  setDashboard(true);
                  setChatBotWidget(true);
                }}
              to={APPEARANCE_SETTING}>
                <Setting />
              </Link>
            </div>
          </div>
        </div>
}
        <div
          className={`flex justify-center  flex-col p-2 cursor-pointer sidebar-tab-container hover:bg-[#0B66A5] `}
        >
          <div className="flex justify-center flex-col items-center relative">
            <div onClick={()=>{
              navigate(GETTING_STARTED);
              setCollapsed(true);
              setDashboard(true);
              setChatBotWidget(true);
              setSettingCollapsed(true);
            }} className="flex justify-center items-center mb-1  p-1  rounded-full">
              <Progress type="circle" percent={progress} width={50} trailColor="#8E99AC" strokeWidth={10} lineBorderRadius={400} strokeColor={"#1655F8"} showInfo={false} >
              </Progress>
              <div className="absolute">
              <Rocket />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sider>
  );
}

export default SideBar;
