import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/AppComponent/Navbar/Navbar";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { Button, Table, message } from "antd";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { COMPLETED, SCHEDULE, UPCOMING ,CANCELLED} from "../../../Constants/Routes/RoutesConstant";
import { LoadingOutlined } from '@ant-design/icons';

function BookingTable() {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const getData = async () => {
    setLoading(true)
    try {
      const res = await axios.get("booking/");
      if (location.pathname === SCHEDULE) {
        setData(res.data.events);

      } else if (location.pathname === UPCOMING) {
        setData(res.data.events.filter((item) => {
          return moment(item.start) > moment() && item.status==="confirmed"
        }).map((item, index) => {
          return {
            ...item,
            "Sno": index + 1
          }
        }))
      }
      else if (location.pathname === COMPLETED) {
        setData(res.data.events.filter((item) => {
          return moment(item.start) <= moment()
        }).map((item, index) => {
          return {
            ...item,
            "Sno": index + 1
          }
        }))
      }
      else if (location.pathname===CANCELLED){
        setData(res.data.events.filter((item) => {
          return item.status==="cancelled"
        }).map((item, index) => {
          return {
            ...item,
            "Sno": index + 1
          }
        }))
      }
    } catch (err) {
      message.error("Something went wrong");
    }
    setLoading(false)
  };

  useEffect(() => {
    getData();
  }, [location]);

  const columns = [
    {
      title: "Sno",
      dataIndex: "Sno",
    },
    {
      title: "Title",
      dataIndex: "title"
    },
    {
      title: "Start Date",
      dataIndex: "start",
      render: (index) => {
        return moment(index).format("DD MMM YYYY")
      }
    },
    {
      title: "End Date",
      dataIndex: "end",
      render: (index) => {
        return moment(index).format("DD MMM YYYY")
      }
    },
    {
      title: "Start Time",
      dataIndex: "start",
      render: (index) => {
        return moment(index).format("hh:mm a")
      }
    },
    {
      title: "End Time",
      dataIndex: "end",
      render: (index) => {
        return moment(index).format("hh:mm a")
      }
    },
    {
      title: "Description",
      dataIndex: "description",
    },
  ]


  return (
    <div className="w-full">
      <Navbar text={
        <div>
          {location.pathname === COMPLETED ? "Completed Bookings" : location.pathname === UPCOMING ? "Upcoming Bookings" : "Cancelled Bookings"}
          <>
            {loading ?
              <LoadingOutlined className="ml-3" /> :
              <></>
            }
          </>
        </div>
      } image />
      <div
        className="m-3"
      >
        <Table columns={columns} dataSource={data} /></div>
    </div>
  );
}

export default BookingTable;