import { message } from "antd"
import axios from "axios"

export const connectDisconnectFlag =async (flagId,convId,action) => {
    try{
       const res = await axios.post("conversation/flag/connection/",{
        flag_id:flagId,
        conversation_id:convId,
        action:action
       })
       message.success(action === "connect" ? "Connected successfully" : "Disconnected successfully");
       return res.data
    }
    catch(err){
        message.error(err.response.data.message)
    }
}