import Card from "antd/es/card/Card";
import React, { useEffect, useState } from "react";
// import { GearShapeWithoutColor } from "../../../../Assets/Export/Export";
import { Button, Form, Input, Modal, Select, Switch, message } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import Navbar from "../../../../../Components/AppComponent/Navbar/Navbar";


function ConversationMessage() {
    const [form] = Form.useForm();
    const [conversation, setConversation] = useState("");



    const getData = async () => {
        try {
            const response = await axios.get("user/client/integration/status?channel=TRANSFER_MESSAGE_WORDINGS");


            setConversation(response.data.data?.wordings);

        } catch (err) {
            message.error("Error while fetching data");
        }
    };

    const onFinish = async () => {
        if(conversation===""){
            message.error("Please Enter Message");
            return;
        }
        try {

            const res = await axios.post("user/client/save-transfer-message-wordings/", {
                wordings: conversation,
            })

            message.success("Data saved successfully");
        } catch (err) {
            message.error("Error while saving data");
        }
    };
    useEffect(() => {
        getData();
    }, []);
    return (
        <div className="flex flex-col gap-3">
            <Navbar text="Conversation Assign Message" image />
            <div className="w-full h-full  flex justify-between px-5 ">
                <Card className="w-full h-[95%]">
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        className="w-[80%]"
                    >

                        <Form.Item
                            label="Conversation Assign Message"
                          
                        // name={"add_new_response"}
                        ><div style={{
                            fontSize: "14px"
                        }}> To replace agent name use <span style={{
                            fontWeight: "bold"
                        }}>@AGENT_NAME@</span> and it will replace with actual agent name</div>
                            <div className="flex">

                                <Input
                                    onChange={(e) => {
                                        setConversation(e.target.value);
                                    }}
                                    value={conversation}
                                    placeholder="Add meeting ConversationMessage"
                                />

                            </div>
                        </Form.Item>



                    </Form>
                </Card>
            </div>
            <div className=" px-5 py-4">
                <Button
                    onClick={() => {
                        form.submit();
                    }}
                    type="primary"
                    className="bg-[#19334E]"
                >
                    Save
                </Button>
            </div>

        </div>
    );
}

export default ConversationMessage;
