import { Button, Divider, Drawer } from "antd";
import React from "react";
import ProfileImage from "../../../Assets/ProfileDrawer/Circle (1).png";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

function ProfileDrawer({ visible, setVisible }) {
  const ClientInformation = useSelector(
    (state) => state?.Integration?.client_information
  );
  return (
    <div>
      <Drawer
        placement="right"
        // closable={true}
        closeIcon={false}
        onClose={() => setVisible(false)}
        className="profileDrawer"
        visible={visible}
      >
        <div className="bg-[#21324E] h-full w-full items-center flex flex-col gap-5 p-5">
          <div className="text-right w-full cursor-pointer">
            <CloseOutlined
              className="text-[#fff]"
              onClick={() => {
                setVisible(false);
              }}
            />
          </div>
          <div className="text-center *:
            w-48 h-48 rounded-full
          ">
            <img className="w-full rounded-full h-full" src={localStorage.getItem("base64Image")?localStorage.getItem("base64Image"):`https://api.dicebear.com/7.x/initials/svg?seed=${ClientInformation?.name}`} />
          </div>
          <div className="text-[#fff] text-center flex gap-2 flex-col">
            <div className="text-xl">{ClientInformation?.name}</div>
            <div>{localStorage.getItem("role")?.toUpperCase()}</div>
          </div>
          <Button ghost style={{
            border:"none",
            background:"#2A3B5A"
          }}
            onClick={()=>{
                // localStorage.clear()
                localStorage.removeItem("token");
                localStorage.removeItem("user_id");
                localStorage.removeItem("user_name");
                localStorage.removeItem("name");
                localStorage.removeItem("email");
                localStorage.removeItem("role");
                localStorage.removeItem("progress");

                window.location.reload()
            }}
          >
            Logout
          </Button>
          <div style={{
            borderTop: "1px solid #626262",
            width: "100%",
            
          }}></div>
        </div>
      </Drawer>
    </div>
  );
}

export default ProfileDrawer;
