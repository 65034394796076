import React, { useState } from "react";
import { BackIcon } from "../../../Assets/Export/Export";
import { useNavigate } from "react-router-dom";
import Profile from "../../../Assets/Conversation/Circle.png";
import { Drawer } from "antd";
import ProfileDrawer from "../ProfileDrawer/ProfileDrawer";
import { useSelector } from "react-redux";

function Navbar({ text, back, image }) {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const ClientInformation = useSelector(
    (state) => state?.Integration?.client_information
  );
  return (
    <div>
      <div
        className="bg-white p-[24px] flex justify-between"
        style={{
          borderBottom: "1px solid #E6E8EA",
        }}
      >
        <div className="text-[#01050F] text-xl font-semibold flex justify-center items-center gap-3">
          {back && (
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              {<BackIcon />}
            </div>
          )}
          <div>{text}</div>
        </div>
        {image && (
          <div
            onClick={()=>{
              setVisible(true)
            
            }}
            className="w-[43px] h-[43px] rounded-full relative cursor-pointer"
          >
            <img style={{
              cursor: "pointer",
              width:"100%",
              borderRadius:"100%"
            }} src={localStorage.getItem("base64Image")?localStorage.getItem("base64Image"):`https://api.dicebear.com/7.x/initials/svg?seed=${ClientInformation?.name}`} />
          </div>
        )}
      </div>
      <ProfileDrawer visible={visible} setVisible={setVisible} />
    </div>
  );
}

export default Navbar;
