import { message } from "antd";
import axios from "axios";
import { Integration } from "./Integration";

export const UpdateIntegration = async (data) => {
  try {
    const res = await axios.post("/user/client/progress/", {
        ...data,
    });
    message.success("Marked as done successfully")
    return res?.data?.onboarding_progress;
  } catch (err) {
    console.log(err);
    message.error("Error updating integration");
  }
};
