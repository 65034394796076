import {
  Button,
  ColorPicker,
  Input,
  Radio,
  Switch,
  Upload,
  message,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { MoreOutlined, CheckOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Laptop from "../../../../../../Assets/ChatComponent/laptopcomputer.png";
import { setColor } from "../../../../../../Redux/Slice/appearenceSlice";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  setAvatar,
  setBackgroundColor,
  setButtonLabel,
  setButtonSizes,
  setButtonSwitchRedux,
  setChatBotName,
  setMessageChat,
  setPosition,
  setVisible,
} from "../../../../../../Redux/Slice/ChatComponent";
import ImgCrop from "antd-img-crop";

function WidgetAppearenceContainer({botName,setBotName,widgetPosition,setWidgetPosition,welcomeMessage,setWelcomeMessage,buttonSwitch,setButtonSwitch,labelText,setLabelText,buttonSize,setButtonSize,imageUrl,setImageUrl}) {
  const dispatch = useDispatch();
  const { appearence } = useSelector((state) => state);
  // const [colorHex, setColorHex] = React.useState(appearence.color);

  const [loading, setLoading] = useState(false);
  // const [botName, setBotName] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await axios.get("/conversation/widget/settings/");
      console.log(res.data.widget_appearance);
      setWelcomeMessage(res.data.widget_appearance.welcome_message);
      setButtonSwitch(res.data.widget_appearance.button_switch);
      setLabelText(res.data.widget_appearance.label_text);
      setWidgetPosition(res.data.widget_appearance.widget_position);
      setButtonSize(res.data.widget_appearance.widget_bubble_size);
      dispatch(setColor(res.data.widget_appearance.color));
      dispatch(setButtonSizes(res.data.widget_appearance.widget_bubble_size));
      dispatch(setBackgroundColor(res.data.widget_appearance.color));
      dispatch(setVisible(res.data.widget_visibility.display_widget));
      dispatch(setAvatar(res.data.widget_appearance.image_base64));
      dispatch(setMessageChat(res.data.widget_appearance.welcome_message));
      dispatch(setButtonSwitchRedux(res.data.widget_appearance.button_switch));
      dispatch(setButtonLabel(res.data.widget_appearance.label_text));
      dispatch(setChatBotName(res.data.widget_appearance.name));
      setImageUrl(res.data.widget_appearance.image_base64);
      setBotName(res.data.widget_appearance.name);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const onFinish = async () => {
    if (!welcomeMessage) {
      message.error("Welcome Message Cannot Be Empty");
      return;
    }

    if (buttonSwitch && !labelText) {
      message.error("Label Text Cannot Be Empty");
      return;
    }
    setLoading(true);
    try {
      const res = await axios.post("/conversation/widget/settings/", {
        widget_appearance: {
          color: appearence.color,
          image_base64: imageUrl,
          widget_position: widgetPosition,
          welcome_message: welcomeMessage,
          button_switch: buttonSwitch,
          label_text: labelText,
          name: botName,
          widget_bubble_size: buttonSize,
        },
      });
      message.success("Data saved successfully");
      window.location.reload();
    } catch (err) {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  const Color = [
    {
      id: 1,
      color: "linear-gradient(90deg, #2731DE, #05B9FC)",
      active: appearence.color === "linear-gradient(90deg, #2731DE, #05B9FC)",
    },
    {
      id: 2,
      color: "linear-gradient(115deg, rgb(64, 29, 186), rgb(130, 91, 240))",
      active:
        appearence.color ===
        "linear-gradient(115deg, rgb(64, 29, 186), rgb(130, 91, 240))",
    },
    {
      id: 3,
      color: "linear-gradient(115deg, rgb(255, 78, 111), rgb(251, 145, 104))",
      active:
        appearence.color ===
        "linear-gradient(115deg, rgb(255, 78, 111), rgb(251, 145, 104))",
    },
    {
      id: 4,
      color: "linear-gradient(115deg, rgb(172, 224, 249), rgb(255, 241, 235))",
      active:
        appearence.color ===
        "linear-gradient(115deg, rgb(172, 224, 249), rgb(255, 241, 235))",
    },
    {
      id: 5,
      color: "linear-gradient(115deg, rgb(41, 50, 60), rgb(72, 85, 99))",
      active:
        appearence.color ===
        "linear-gradient(115deg, rgb(41, 50, 60), rgb(72, 85, 99))",
    },
  ];

  function getHexaNumb() {
    return (
      "#" +
      Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padEnd(6, "0")
    );
  }



  const handleChange = (info) => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (url) => {
      setImageUrl(url);
      dispatch(setAvatar(url));
    });
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  // useMemo(() => {
  //   let color =
  //     typeof colorHex === "string" ? colorHex : colorHex?.toHexString();
  //   console.log(color);
  //   dispatch(setColor(color));
  // }, [colorHex]);

  return (
    <>
      <div className="bg-slate-100 p-3 h-[820px]">
        <div className="flex gap-5 p-5">
          <div className="font-normal text-sm  w-[36%]">Bot Name</div>
          <Input
            placeholder="Type here..."
            value={botName}
            onChange={(e) => {
              setBotName(e.target.value);
              dispatch(setChatBotName(e.target.value));
            }}
            className="w-[64%]"
          />
        </div>
        <div className="flex gap-5 p-5 ">
          <div className="font-normal w-[36%] text-sm flex items-center">
            Background color
          </div>
          <div className="flex gap-4 w-[64%]">
            <>
              {Color.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      dispatch(setColor(item.color));
                      dispatch(setBackgroundColor(item.color));
                    }}
                    key={index}
                    className={`w-8 cursor-pointer h-8 text-white flex justify-center items-center rounded-full`}
                    style={{ backgroundImage: `${item.color}` }}
                  >
                    {item.active ? <CheckOutlined /> : ""}
                  </div>
                );
              })}
              {/* <ColorPicker value={appearence.color} onChange={setColorHex}>
                <div
                  className="w-8 h-8 cursor-pointer rounded-full bg-white flex justify-center items-center text-center"
                  style={{
                    transform: "rotate(90deg)",
                    border: "2px solid #e3e7ee",
                  }}
                >
                  <MoreOutlined />
                </div>
              </ColorPicker> */}
            </>
          </div>
        </div>
        <div className="flex gap-5 p-5">
          <div className="font-normal text-sm w-[36%]">Upload an Avatar</div>
          <div className="flex flex-col w-[64%]">
            {/* <Radio.Group defaultValue="a"> */}
            <div className="flex flex-col gap-6">
              {/* <Radio value="a" className="font-normal text-sm">
                  Operators Logo
                </Radio> */}

              {/* <Radio value="b">Your Logo</Radio> */}
              <ImgCrop modalClassName=" modal-for-padding" cropShape="round" fillColor="transparent">
              <Upload
                name="avatar"
                listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              </ImgCrop>
            </div>
            {/* </Radio.Group> */}
          </div>
        </div>
        {/* <div className="flex gap-5 p-5">
          <div className="font-normal text-sm  w-[36%]">Online Status</div>
          <div className="flex flex-col w-[64%] gap-3 text-[#8692A6]">
            <div className="font-normal text-xs">
              Show this message in the chat widget when you're 'online' in your
              SellSwift panel
            </div>
            <Input.TextArea placeholder="Type here..." className="w-full" />
          </div>
        </div> */}

        <div className="flex gap-5 p-5">
          <div className="font-normal text-sm  w-[36%]">Widget Position</div>
          <div className="flex flex-col w-[64%] gap-3 text-[#8692A6]">
            <div className="font-normal text-xs">
              Select Widget location below
            </div>
            <div>
              <Radio.Group
                value={widgetPosition}
                onChange={(e) => {
                  setWidgetPosition(e.target.value);
                  dispatch(setPosition(e.target.value));
                }}
                className="flex items-center"
              >
                <Radio value={"left"}>Left</Radio>
                <div>
                  <img src={Laptop} alt="laptop" />
                </div>
                <Radio value={"right"}>Right</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>

        <div className="flex gap-5 p-5">
          <div className="font-normal text-sm  w-[36%]">Welcome Message</div>
          <Input.TextArea
            placeholder="Type here..."
            value={welcomeMessage}
            onChange={(e) => {
              setWelcomeMessage(e.target.value);
              dispatch(setMessageChat(e.target.value));
            }}
            className="w-[64%]"
          />
        </div>

        <div className="flex gap-5 p-5">
          <div className="font-normal text-sm  w-[36%]">Button Label</div>

          <Switch
            value={buttonSwitch}
            onChange={(e) => {
              setButtonSwitch(e);
              dispatch(setButtonSwitchRedux(e));
            }}
          />
        </div>

        <div className="flex gap-5 p-5">
          <div className="font-normal text-sm  w-[36%]">Button Size</div>

          <Radio.Group
            value={buttonSize}
            onChange={(e) => {
              setButtonSize(e.target.value);
              dispatch(setButtonSizes(e.target.value));
            }}
          >
            <Radio value="small">Small</Radio>
            <Radio value="medium">Medium</Radio>
            <Radio value="large">Large</Radio>
          </Radio.Group>
        </div>

        {buttonSwitch && (
          <div className="flex gap-5 p-5">
            <div className="font-normal text-sm  w-[36%]">Label Text</div>
            <Input
              className="w-[64%]"
              value={labelText}
              onChange={(e) => {
                setLabelText(e.target.value);
                dispatch(setButtonLabel(e.target.value));
              }}
            />
          </div>
        )}

        {/* <div className="mt-5">
          <Button
            type="primary"
            loading={loading}
            onClick={onFinish}
            className="w-36 bg-[#19334E]"
          >
            Save
          </Button>
        </div> */}

        {/* <div className="flex gap-5 p-5">
          <div className="font-normal text-sm  w-[36%]">
            Enable Widget Sounds
          </div>

          <Switch />
        </div> */}
      </div>
    </>
  );
}

export default WidgetAppearenceContainer;
const { TextArea } = Input;
