import "./App.css";
import { ApiService } from "./Service/ApiService";
import AppView from "./Views/AppViews/AppView";
import AuthView from "./Views/AuthViews/AuthView";

function App() {
  ApiService();
  return <>{localStorage.getItem("token") ? <AppView /> : <AuthView />}</>;
}

export default App;
