import {
  Button,
  Checkbox,
  Input,
  Menu,
  Modal,
  Table,
  Tabs,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { FileUpload, WebsiteUpload } from "../../../../Assets/Export/Export";
import axios from "axios";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import Pdf from "../../../../Assets/ChatWidget/pdf_136522 1.png";
import Doc from "../../../../Assets/ChatWidget/doc.png";
import Excel from "../../../../Assets/ChatWidget/Layer_x0020_1.png";
import EllipsisDropdown from "../../../../Components/AppComponent/Ellipsoid/EllipsisDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { CHATBOTWIDGET } from "../../../../Constants/Routes/RoutesConstant";
import Navbar from "../../../../Components/AppComponent/Navbar/Navbar";

function DataSourceTable() {
  const [activeTab, setActiveTab] = useState("1");
  const [fileList, setFileList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [url, setUrl] = useState("");
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState("");
  const [fileBase64, setFileBase64] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data2, setData2] = useState([]);
  const [data, setData] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get("type");
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const getData = async () => {
    try {
      const res = await axios.get("conversation/trainging_files/");
      setData(res.data.training_files_data);
    } catch (err) {
      message.error("Error fetching data");
    }
  };

  const handleUpload2 = async () => {
    if (!url) {
      message.error("Please enter the URL");
      return;
    }
    setLoading(true);
    if (edit) {
      try {
        const res = await axios.put("/core/website_trainer/", {
          url: url,
          website_trainer_id: id,
        });
        console.log(res.data);
        message.success("Website updated successfully");
        setVisible2(false);
        setUrl("");
        setData2(res.data.website_trainers);
        setEdit(false);
      } catch (err) {
        message.error("Error updating website");
      }
      setLoading(false);
      return;
    }
    try {
      const res = await axios.post("/core/website_trainer/", {
        url: url,
      });
      console.log(res.data);
      message.success("Website added successfully");
      setVisible2(false);
      setUrl("");
      setData2(res.data.website_trainers);
    } catch (err) {
      message.error("Error adding website");
    }
    setLoading(false);
  };

  const deleteFile2 = async (id) => {
    try {
      const res = await axios.post(`/core/website_trainer_delete/`, {
        website_trainer_id: id
      })
      message.success("File deleted successfully");
      console.log(res.data);
      setData2(res.data.website_trainers);
    } catch (err) {
      console.log(err);
      message.error("Error deleting file");
    }
  };
  const deleteFile = async (id) => {
    if (data.length < 2) {
      message.error("Atleast one file should be present");
      return;
    }
    try {
      const res = await axios.post(`/conversation/trainging_file/delete/`, {
        training_file_id: id,
      });
      message.success("File deleted successfully");
      console.log(res.data);
      setData(res.data.training_files_data);
    } catch (err) {
      message.error("Error deleting file");
    }
  };

  const deleteFileArray = async () => {
    if (data.length == selectedRowKeys.length) {
      message.error("Atleast one file should be present");
      return;
    }
    try {
      const res = await axios.post(`/conversation/trainging_file/delete/`, {
        training_file_ids: selectedRowKeys.map((item) => item.record_id),
      });
      message.success("File deleted successfully");
      console.log(res.data);
      setData(res.data.training_files_data);
      setSelectedRowKeys([]);
    } catch (err) {
      message.error("Error deleting file");
    }
  };

  const deleteWebsiteArray = async () => {
    // if (data2.length == selectedRowKeys.length) {
    //   message.error("Atleast one Website should be present");
    //   return;
    // }
    try {
      const res = await axios.post(`/core/website_trainer_delete/`, {
        website_trainer_ids: selectedRowKeys.map((item) => item.record_id),
      }
      );
      message.success("Website deleted successfully");
    console.log(res.data);
    getData2();
  } catch (err) {
    message.error("Error deleting file");
  }
};

const handleUpload = async () => {
  setLoading(true);
  var data = [];
  const promises = fileList.map(
    (file) =>
      new Promise((resolve) => {
        getBase64(file.originFileObj, (base64) => {
          data.push({
            name: file.name.split(".")[0],
            type: file.type.split("/")[1],
            content: base64,
          });
          resolve();
        });
      })
  );

  await Promise.all(promises);

  try {
    const res = await axios.post("conversation/upload/training_files/", data);
    console.log(res.data);
    message.success("File uploaded successfully");
    setVisible(false);
    setFileList([]);
    setData(res.data.training_files_data);
  } catch (err) {
    message.error("Error uploading file");
  }
  setLoading(false);
};

const handleChange = (info) => {
  if (info.fileList) {
    setFileList(info.fileList);
  }
};

const getBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => {
    const resultWithoutPrefix = reader.result.replace(
      /^data:[^;]+;base64,/,
      ""
    );

    callback(resultWithoutPrefix);
  });
  reader.readAsDataURL(file);
};

const getData2 = async () => {
  try {
    const res = await axios.get("core/website_trainer/");
    console.log(res.data.website_trainers);
    setData2(res.data.website_trainers);
  } catch (err) {
    message.error("Error fetching data");
  }
};

useEffect(() => {
  getData();
  getData2();
}, []);

useEffect(() => {
  if (type === "website") {
    setActiveTab("2");
  }
}, [type]);

const columns = [
  {
    title: "File Name",
    dataIndex: "name",
    render: (text, record) => {
      return (
        <div className="flex gap-3">
          <div>
            <img
              src={
                record.type === "pdf"
                  ? Pdf
                  : record.type === "docx"
                    ? Doc
                    : Excel
              }
              alt="pdf"
              className="w-8 h-8"
            />
          </div>
          <div>
            <div className="font-bold">{text}</div>
            <div>{record.created_at_formatted}</div>
          </div>
        </div>
      );
    },
  },
  {
    title: "Uploaded By",
    dataIndex: "agent",
    render: (text, record) => {
      return <div>{text.name}</div>;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text, record) => {
      if (record.status === "not_trained") {
        return <Tag color="red">Not Trained</Tag>;
      } else {
        return <Tag color="green">Trained</Tag>;
      }
    },
  },
  {
    title: "Action",
    align: "center",
    render: (text, record) => {
      return (
        <>
          <EllipsisDropdown
            menu={
              <Menu>
                <Menu.Item
                  className="cursor-pointer"
                  onClick={() => {
                    window.open(record.path, "_blank");
                  }}
                >
                  <span>
                    <EyeOutlined className="mr-2" />
                    View
                  </span>
                </Menu.Item>
                <Menu.Item
                  className="cursor-pointer"
                  onClick={() => {
                    Modal.confirm({
                      className: "modal-for-padding",
                      title: "Are you sure you want to delete this file?",
                      onOk() {
                        deleteFile(record.record_id);
                      },
                    });
                  }}
                >
                  <span>
                    {" "}
                    <DeleteOutlined className="mr-2 " />
                    Delete
                  </span>
                </Menu.Item>
              </Menu>
            }
          />
        </>
      );
    },
  },
];

const columns2 = [
  {
    title: "Title",
    dataIndex: "url",
    render: (text, record) => {
      return (
        <div>
          <div className="font-bold">{text}</div>
          <div>{record.created_at_formatted}</div>
        </div>
      );
    },
  },

  {
    title: "Uploaded By",
    dataIndex: "agent",
    render: (text, record) => {
      return <div>{text.name}</div>;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text, record) => {
      if (record.status === "not_trained") {
        return <Tag color="red">Not Trained</Tag>;
      } else {
        return <Tag color="green">Trained</Tag>;
      }
    },
  },
  {
    title: "Action",
    align: "center",
    render: (text, record) => {
      return (
        <>
          <EllipsisDropdown
            menu={
              <Menu>
                <Menu.Item
                  className="cursor-pointer"

                >
                  <a href={record.url} target="_blank" rel="noreferrer">
                    <span>
                      <EyeOutlined className="mr-2 " />
                      View
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item
                  className="cursor-pointer"
                  onClick={() => {
                    setVisible2(true);
                    setUrl(record.url);
                    setEdit(true);
                    setId(record.record_id);
                  }}
                >
                  <span>
                    <EditOutlined className="mr-2 " />
                    Edit
                  </span>
                </Menu.Item>
                <Menu.Item
                  className="cursor-pointer"
                  onClick={() => {
                    Modal.confirm({
                      className: "modal-for-padding",
                      title: "Are you sure you want to delete this url?",
                      onOk() {
                        deleteFile2(record.record_id);
                      },
                    });
                  }}
                >
                  <span>
                    {" "}
                    <DeleteOutlined className="mr-2 " />
                    Delete
                  </span>
                </Menu.Item>
              </Menu>
            }
          />
        </>
      );
    },
  },
];

// const data2 = [
//   {
//     website:"www.google.com",
//     agent:"Agent 1",
//     status:"not_trained",
//     created_at_formatted:"Uploaded on 28 Aug 2023, 3:28 Pm"
//   },
//   {
//     website:"www.facebook.com",
//     agent:"Agent 2",
//     status:"trained",
//     created_at_formatted:"Uploaded on 28 Aug 2023, 3:28 Pm"
//   },
//   {
//     website:"www.twitter.com",
//     agent:"Agent 3",
//     status:"not_trained",
//     created_at_formatted:"Uploaded on 28 Aug 2023, 3:28 Pm"
//   }
// ]

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    // console.log(`selectedRowKeys: ${selectedRowKeys}`);
    setSelectedRowKeys(selectedRows);
  },
};

return (
  <>
    <Navbar text="Data Sources" image />
    <div className="flex flex-col gap-3 w-full p-5">
      <div className="flex justify-between">
        <div className="text-base">
          SellSwift will use the knowledge you add here to answer customer
          questions.
        </div>
        <div>
          <Button
            onClick={() => {
              if (activeTab === "1") {
                setVisible(true);
              }
              if (activeTab === "2") {
                setVisible2(true);
                setEdit(false);
              }
            }}
            type="primary"
            icon={<PlusOutlined />}
            className="bg-[#19334E]"
          >
            Add More Knowledge
          </Button>
        </div>
      </div>

      <div className="w-full">
        <Tabs
          defaultActiveKey="1"
          onChange={(e) => {
            setActiveTab(e);
            // if (type) {
            //   navigate(CHATBOTWIDGET);
            // }
          }}
          activeKey={activeTab}
          tabBarExtraContent={<Button disabled={selectedRowKeys.length === 0} onClick={() => {
            Modal.confirm({
              className: "modal-for-padding",
              title: "Are you sure you want to delete these files?",
              onOk() {
                if (activeTab === "1") {
                  deleteFileArray();
                } else {
                  deleteWebsiteArray();
                }
              },
            });
          }}>Delete</Button>}
        >
          <Tabs.TabPane tab="Files" key="1"></Tabs.TabPane>
          <Tabs.TabPane tab="Website" key="2"></Tabs.TabPane>
        </Tabs>

        {activeTab === "1" && (
          <div className="bg-[#fff] h-[300px] w-full">
            {data.length === 0 ? (
              <div className="flex justify-center items-center flex-col w-[70%] m-auto gap-3 h-full">
                <div>
                  <FileUpload />
                </div>
                <div className="text-lg font-bold">
                  You have no files uploaded yet
                </div>
                <div className="text-center">
                  Empower SellSwift capabilities by providing it with a wealth
                  of information. Choose from the options below to enhance its
                  knowledge base. “Remember, the more you feed it, the sharper
                  its responses become."
                </div>

                <Button
                  onClick={() => {
                    setVisible(true);
                  }}
                  type="primary"
                  className="bg-[#19334E]"
                >
                  Upload Files
                </Button>
              </div>
            ) : (
              <div className="h-full overflow-y-scroll">
                <Table
                  pagination={false}
                  dataSource={data}
                  columns={columns}
                  rowKey={"record_id"}
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                />
              </div>
            )}
          </div>
        )}
        {activeTab === "2" && (
          <div className="bg-[#fff] h-[300px] w-full ">
            {data2.length === 0 ? (
              <div className="flex justify-center items-center flex-col w-[70%] m-auto gap-3 h-full">
                <div>
                  <WebsiteUpload />
                </div>
                <div className="text-lg font-bold">
                  You have no websites URLs yet
                </div>
                <div className="text-center">
                  Add your first website URL to feed SellSwift with
                  knowledge.
                </div>

                <Button
                  onClick={() => {
                    setVisible2(true);
                    setEdit(false);
                  }}
                  type="primary"
                  className="bg-[#19334E]"
                >
                  Add Now
                </Button>
              </div>
            ) : (
              <div className="h-full overflow-y-scroll">
                <Table
                  pagination={false}
                  dataSource={data2}
                  columns={columns2}
                  rowKey={"record_id"}
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        title="Upload File"
        visible={visible}
        onOk={handleUpload}
        confirmLoading={loading}
        onCancel={() => {
          setVisible(false);
        }}
        className="modal-for-padding"
      >
        <Dragger
          multiple
          onChange={handleChange}
          fileList={fileList}
          accept=".pdf, .docx, .doc, .pptx, .xls, .xlsx"
          beforeUpload={() => false}
          showUploadList={true}
        >
          <p className="ant-upload-drag-icon">
            <i className="fas fa-cloud-upload-alt"></i>
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p>
          <p className="ant-upload-hint">
            Supported file types: .pdf, .docx, .doc, .pptx, .xls, .xlsx
          </p>
        </Dragger>
      </Modal>
      <Modal
        title="Add website content from URL"
        visible={visible2}
        onOk={handleUpload2}
        confirmLoading={loading}
        onCancel={() => {
          setVisible2(false);
        }}
        className="modal-for-padding"
      >
        <div className="my-4">
          Provide a URL of your website and we will fetch a content to feed
          SellSwift with knowledge.
        </div>
        <Input
          onChange={(e) => {
            setUrl(e.target.value);
          }}
          value={url}
          placeholder="Enter URL of your website"
        />
      </Modal>
    </div>
  </>
);
}

export default DataSourceTable;
