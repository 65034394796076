import React from "react";
import BackgroundComponent from "../../../Components/AuthComponent/BackgroundComponent";
import { Button, Checkbox, Divider, Form, Input } from "antd";
import { GoogleIcon } from "../../../Assets/Export/Export";

function CreateNewAccount() {
  return (
    <BackgroundComponent>
      <div className="flex justify-center items-center flex-col">
        <div className="text-[#19334E] font-bold text-[29px]">
          Create a free account
        </div>
        <Form layout="vertical" className="mt-10">
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input
              className="login-input login-input-text"
              placeholder="Email Address"
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
              {
                min: 8,
                message: "Password must be minimum 8 characters.",
              },
            ]}
          >
            <Input.Password
              className="login-input login-input-text"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Input
              className="login-input login-input-text"
              placeholder="Website"
            />
          </Form.Item>
          <Form.Item>
            <Checkbox className="login-input-text">
              I agree to terms & conditions
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="bg-[#19334E] w-full h-[50px]"
            >
              Register Account
            </Button>
          </Form.Item>
          <Divider>
            <span className="text-[#bababa]">or</span>
          </Divider>
          <Button
            icon={<GoogleIcon />}
            className="h-[50px] rounded-md boxShadowGoogle flex justify-center items-center w-full"
          >
            Register With Google
          </Button>
        </Form>
        <div className="text-[#bababa] mt-10">
          Already have an account?
          <span className="text-[#0766FF]"> Login here</span>
        </div>
      </div>
    </BackgroundComponent>
  );
}

export default CreateNewAccount;
