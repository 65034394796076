import { message } from "antd";
import axios from "axios";

export const LeaveConversations = async (type,id) => {
  try {
    const response = await axios.post("conversation/assign_unassign/", {
      assignment_type:type,
      conversation_id: id,
    });
    if(type === "un-assignment"){
      message.success("Conversation left successfully");
    }
    else{
      message.success("Conversation assigned successfully");
    }
    return response.data;
  } catch (err) {
    message.error("Failed to leave conversation");
  }
};
