import React, { useEffect } from 'react'
import EllipsisDropdown from '../../../../Components/AppComponent/Ellipsoid/EllipsisDropdown';
import { Button, Menu, Table, Tabs } from 'antd';
import { EditOutlined, DeleteOutlined ,RedoOutlined} from '@ant-design/icons';
import { Form, Modal, message } from 'antd';
import { Input, Select, Switch } from 'antd';
// import axios from 'axios';
import { useState } from 'react';
import axios from 'axios';
import Navbar from '../../../../Components/AppComponent/Navbar/Navbar';

function ChatBotAgent() {
  const [visible2, setVisible2] = useState(false);
  const [typeId, setTypeId] = useState(null);
  const [activeTab,setActiveTab] = useState('1');
  const [form2] = Form.useForm();
  // const [agents, setAgents] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [agentData, setAgentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const id = localStorage.getItem("client_id");
  const [deletedData,setDeletedData] = useState([]);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAgentData(id)
    showDeleted(id);
  }, []);

  const showDeleted = async(id)=>{
    try{
      const res = await axios.get("user/agent/show-deleted/?client_id="+id)
      console.log(res)
      setDeletedData(res.data.agents)
      
    }catch(err){
      message.error('Cant fetch');
    }
  }

  const restoreAgent = async(id)=>{
    try{
      const res = await axios.post('user/agent/restore/',{
        record_id:id
      })
      // console.log(res.data)
      showDeleted(clientId);
      getAgentData(clientId);
      message.success("Agent Restored Successfully");
    }catch(err){
      message.error("Agent Restoring Failed")
    }
  }

  const handleDelete = async (id) => {
    try {
      const res = await axios.post("/user/agent/delete/", {
        record_id: id
      })
      message.success("Agent deleted successfully")
      getAgentData(clientId);
    
    } catch (err) {
      message.error("Error deleting agent")
    }
  }

  const handleEdit = async (id) => {
    try {
      const res = await axios.post("/user/agent/update/", {
        record_id: id,
        name: form2.getFieldValue("name"),
        email: form2.getFieldValue("email"),
        phone: form2.getFieldValue("phone"),
        role: form2.getFieldValue("role"),
        is_active: form2.getFieldValue("is_active"),
        add_in_cc: form2.getFieldValue("add_in_cc"),
        can_assign: form2.getFieldValue("can_assign")
      })
      message.success("Agent edited successfully")
      setVisible2(false);
      form2.resetFields();
      getAgentData(clientId);
    } catch (err) {
      message.error("Error editing agent")
    }
  }

  const onFinish2 = async (values) => {
    if (!typeId) {
      try {
        setLoading(true);
        const res = await axios.post("/user/agent/", {
          name: values.name,
          email: values.email,
          phone: values.phone,
          password: values.password,
          confirm_password: values.confirm_password,
          client_id: clientId,
          role: values.role,
          is_active: values.is_active,
        });
        message.success("Agent added successfully");
        setVisible2(false);
        setLoading(false);
        form2.resetFields();
        getAgentData(clientId);
      } catch (err) {
        message.error(err.response.data.error[0]);
        setLoading(false);
      }
    } else {
      handleEdit(typeId);
    }
  };


  const getAgentData = async (id) => {
    setClientId(id);
    try {
      const res = await axios.get(`/user/agent?client_id=${id}`);
      setAgentData(res.data.agents);
    } catch (err) {
      message.error("Error fetching data");
    }
  };

  const columnsAgent = [
    {
      title: "ID",
      dataIndex: "record_id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "User Name",
      dataIndex: "user_name",
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active) {
          return <span className="text-green-500">Active</span>;
        } else {
          return <span className="text-red-500">Inactive</span>;
        }
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (item) => {
        return item?.toUpperCase()
      }
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <>
            <EllipsisDropdown
              menu={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setVisible2(true)
                      setTypeId(record.record_id)
                      form2.setFieldsValue({
                        name: record.name,
                        email: record.email,
                        phone: record.phone,
                        role: record.role,
                        is_active: record.is_active,
                        add_in_cc: record.add_in_cc,
                        can_assign:record.can_assign
                      })
                    }}
                  >
                    <span>
                      {" "}
                      <EditOutlined className="mr-2 " />
                      Edit
                    </span>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure you want to delete this agent?',
                        okText: 'Yes',
                        okType: 'danger',
                        className: "modal-for-padding",
                        cancelText: 'No',
                        onOk: () => handleDelete(record.record_id)
                      })
                    }}
                  >
                    <span>
                      {" "}
                      <DeleteOutlined className="mr-2 " />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
            />
          </>
        );

      }
    }
  ];

  const deletedColumnsAgent=[
    {
      title: "ID",
      dataIndex: "record_id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "User Name",
      dataIndex: "user_name",
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active) {
          return <span className="text-green-500">Active</span>;
        } else {
          return <span className="text-red-500">Inactive</span>;
        }
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (item) => {
        return item?.toUpperCase()
      }
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <>
            <EllipsisDropdown
              menu={
                <Menu>
                  <Menu.Item
                    onClick={()=>{
                      restoreAgent(text.record_id)
                    }}
                  >
                    <span>
                      {" "}
                      <RedoOutlined className="mr-2 " />
                      Restore
                    </span>
                  </Menu.Item>
               
                </Menu>
              }
            />
          </>
        );

      }
    }
  ]
  return (
    <>
      <Navbar text={"Assignee"} image />
      <div className='w-full py-3 px-3'>
        <div className="flex justify-between items-center mb-3">
          <div>
            <Tabs
              activeKey={activeTab} 
              onChange={(e)=>{
                setActiveTab(e);
                if(e==='2'){
                  showDeleted(id)
                }
              }}
              
              >
              <Tabs.TabPane tab="Active Agents" key="1"></Tabs.TabPane>
              <Tabs.TabPane tab="Deleted Agents" key="2"></Tabs.TabPane>
            </Tabs>
          </div>
          {activeTab==='1' && <Button
            type="primary"
            onClick={() => {
              setVisible2(true);
              setTypeId(null);
              form2.resetFields();
            }}
          >
            Add Agent
          </Button>}
        </div>
       {activeTab=='1' ? <Table dataSource={agentData} columns={columnsAgent} /> : <Table dataSource={deletedData} columns={deletedColumnsAgent} />}
        <Modal
          visible={visible2}
          onCancel={() => {
            setVisible2(false);
          }}
          title="Add Agent"
          confirmLoading={loading}
          centered
          onOk={() => {
            form2.submit();
          }}
          className="modal-for-padding"
        >
          <Form form={form2} layout="vertical" onFinish={onFinish2}>
            <Form.Item
              label="Agent Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input agent name!",
                },
              ]}
            >
              <Input placeholder="Agent Name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input email!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input phone!",
                },
              ]}
            >
              <Input placeholder="Phone" />
            </Form.Item>
            <Form.Item
              name="role"
              label="Role"
              rules={[{
                required: true,
                message: "Please select a role"
              }]}
            >
              <Select
                placeholder="Select a role"
              >
                <Select.Option value="agent">Agent</Select.Option>
                <Select.Option value="admin">Admin</Select.Option>
              </Select>
            </Form.Item>
            <div className='flex justify-between'>
              <Form.Item
                label="Status"
                name="is_active"
              >
                <Switch
                  defaultChecked
                />
              </Form.Item>
              <Form.Item
                label="Can Assign"
                name="can_assign"
              >
                <Switch
                  defaultChecked
                />
              </Form.Item>
              <Form.Item
                label="Add in CC"
                name="add_in_cc"
              >
                <Switch
                  defaultChecked
                />
              </Form.Item>
            </div>
            {
              !typeId
              && <>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name={"confirm_password"}
                  rules={[
                    {
                      required: true,
                      message: "Please input confirm password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
              </>
            }
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default ChatBotAgent