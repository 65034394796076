import { configureStore } from '@reduxjs/toolkit';
import SettingSlice from './Slice/SettingSlice';
import appearenceSlice from './Slice/appearenceSlice';
import conversationSlice from './Slice/conversationSlice';
import selectedConversation from './Slice/selectedConversation';
import ChatBotSlice from './Slice/ChatBotSlice';
import ChatConversationslice from './Slice/ChatConversationslice';
import IntegrationSlice from './Slice/IntegrationSlice';
import ChatComponent from './Slice/ChatComponent';


const store = configureStore({
  reducer: {
    setting: SettingSlice,
    appearence: appearenceSlice,
    conversation: conversationSlice,
    selectChat: selectedConversation,
    chatBot: ChatBotSlice,
    ChatConversation: ChatConversationslice,
    Integration: IntegrationSlice,
    chatComponent:ChatComponent
  },
});

export default store;