import React, { useEffect } from "react";
import { CheckOutlined } from "@ant-design/icons";

function Tabs({ id, name, active, setActive, done }) {
  const handleChange = () => {
    if(done===true){
      return;
    }
    setActive(id);
  };

  return (
    <>
      <div
        style={{
          boxShadow:
            active === id
              ? " 0px -1px 0px 1px rgba(0, 0, 0, 0.10) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.10) inset"
              : "none",
        }}
        onClick={handleChange}
        className={`tabs ${
          active === id ? "bg-[#fff]" : ""
        } ${done?" cursor-not-allowed ":"cursor-pointer "} relative z-20 h-10 p-5 flex justify-start items-center gap-2 rounded-2xl `}
      >
        <div
          className={`${
            done ? "bg-[#179763]" : "bg-[#19334E]"
          } rounded-3xl flex justify-center items-center w-[20px] h-[20px]`}
        >
          {done ? (
            <div className="text-[#fff]">
              <CheckOutlined />
            </div>
          ) : (
            <div className="font-bold text-base text-[#fff]">{id}</div>
          )}
        </div>
        <div className="font-bold text-base text-[#000]">{name}</div>
      </div>
    </>
  );
}

export default Tabs;
