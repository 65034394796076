import React from "react";
import { CompanyLogo } from "../../Assets/Export/Export";

function BackgroundComponent({ children }) {
  return (
    <div className="h-full w-full relative">
      <div className="login-app">
        <div className="w-full h-full relative">
          <div className="absolute left-7 top-5">
            <CompanyLogo />
          </div>

          <div className="ellipse flex justify-center items-center">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BackgroundComponent;
