import React from "react";
import { Checkbox, Form, Input } from "antd";

const CheckboxGroup = Checkbox.Group;

function Steps3() {
  return (
    <div>
      <div className="flex flex-col justify-center items-center mt-7 gap-3">
        <div className="text-xl font-medium">
          Shape your experience-it will only take few steps.
        </div>
        <div className="text-3xl font-extrabold mt-6 mb-12">
          Get setup for success
        </div>
        <div className="w-[60%]">
          <div className="w-[100%]">
            <div className="text-xl font-bold mb-2">
              Which channel do you plan to use SellSwift on for interacting
              with customer?
            </div>
            <Form.Item>
              <div className="text-lg font-medium">
                <CheckboxGroup className="flex justify-between flex-wrap gap-4">
                  {RadioMap.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="bg-[#F5F7F9] rounded-md p-[15px] w-[49%]"
                      >
                        <Checkbox value={item.id}>{item.value}</Checkbox>
                      </div>
                    );
                  })}
                </CheckboxGroup>
              </div>
            </Form.Item>
          </div>
          <div className="w-[100%]">
            <div className="text-xl font-bold mb-2">
              When would you like to have SellSwift up & running?
            </div>
            <Form.Item>
              <div className="text-lg font-medium">
                <CheckboxGroup className="flex justify-between flex-wrap gap-4">
                  {RadioMap2.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="bg-[#F5F7F9] rounded-md p-[15px] w-[49%]"
                      >
                        <Checkbox value={item.id}>{item.value}</Checkbox>
                      </div>
                    );
                  })}
                </CheckboxGroup>
              </div>
            </Form.Item>
          </div>
          <div className="w-[100%]">
            <div className="text-xl font-bold mb-2">
              What are you using today?
            </div>
            <Form.Item>
              <div className="text-lg font-medium">
                <CheckboxGroup className="flex justify-between flex-wrap gap-4">
                  {RadioMap3.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="bg-[#F5F7F9] rounded-md p-[15px] w-[49%]"
                      >
                        <Checkbox value={item.id}>{item.value}</Checkbox>
                      </div>
                    );
                  })}
                </CheckboxGroup>
              </div>
            </Form.Item>
          </div>
          <div className="w-[100%]">
            <div className="text-xl font-bold mb-2">
              Where did you learn about SellSwift?
            </div>
            <Form.Item>
              <div className="text-lg font-medium">
                <CheckboxGroup className="flex justify-between flex-wrap gap-4">
                  {RadioMap4.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="bg-[#F5F7F9] rounded-md p-[15px] w-[49%]"
                      >
                        <Checkbox value={item.id}>{item.value}</Checkbox>
                      </div>
                    );
                  })}
                </CheckboxGroup>
              </div>
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Steps3;

const RadioMap = [
  {
    id: 1,
    value: "Facebook",
  },
  {
    id: 2,
    value: "Instagram",
  },
  {
    id: 3,
    value: "Whatsapp",
  },
  {
    id: 4,
    value: "Email",
  },
  {
    id: 5,
    value: "Tiktok",
  },
  {
    id: 6,
    value: "Other",
  },
];

const RadioMap2 = [
  {
    id: 1,
    value: "As soon as possible",
  },
  {
    id: 2,
    value: "Within 1 month",
  },
  {
    id: 3,
    value: "Within 3 months",
  },
  {
    id: 4,
    value: "Just Playing Around",
  },
];

const RadioMap3 = [
  {
    id: 1,
    value: "Zendesk",
  },
  {
    id: 2,
    value: "Zoho",
  },
  {
    id: 3,
    value: "Intercomm",
  },
  {
    id: 5,
    value: "Other",
  },
];

const RadioMap4 = [
  {
    id:1,
    value:"Google"
  },
  {
    id:2,
    value:"Facebook"
  },
  {
    id:3,
    value:"Linkedin"
  },
  {
    id:4,
    value:"Instagram"
  },
  {
    id:5,
    value:"Other"
  }
];
