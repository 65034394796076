//APP ROUTES
export const GETTING_STARTED = '/getting-started';
export const MY_CONVERSATION = '/my-conversation';
export const UNASSIGNED = '/unassigned';
export const ALLCONVERSATION = '/all-conversation';
export const CLOSED = '/closed';
export const SCHEDULE = '/schedule';
export const UPCOMING = "/upcoming";
export const COMPLETED = "/completed";
export const CANCELLED = "/cancelled";
export const SETTINGS = '/settings';
export const APPEARANCE = '/appearance';
export const CHATBOTWIDGET = '/chatbot-widget';
export const DASHBOARD = '/dashboard';
export const SUCCESSFULLYANSWERED = '/successfully-answered';
export const UNANSWERED = '/unanswered';
export const TRANSFER = '/transfer';
export const TOTAL_BOOKINGS = '/total-bookings';
export const ALL_BOOKINGS_CONFIRMED = '/all-bookings-confirmed';
export const MANUAL_BOOKING = '/manual-booking';
export const HUB = '/hub';
export const DATASOURCE = '/datasource';
export const TRIAL = '/trial';
export const ADMIN = '/admin';
export const EMAIL = '/email-filter';
export const FACEBOOK = '/facebook-filter';
export const INSTAGRAM = '/instagram-filter';
export const TELEGRAM = '/telegram-filter'
export const WHATSAPP_FILTER = '/whatsapp-filter';


export const APPEARANCE_SETTING = '/appearance-setting';
export const TRANSLATION_SETTING= '/installation-setting';
export const CHAT_PAGE = '/chat-page';
export const EMAIL_INTEGRATION = '/email-integration';
export const WHATSAPP_INTEGRATION = '/whatsapp-integration';
export const FACEBOOK_INTEGRATION = '/facebook-integration';
export const INSTAGRAM_INTEGRATION = '/instagram-integration';
export const ASSIGNEE=  '/assignee';
export const ASSIGNEE_AGENT = '/assignee-agent';
export const CONFIGURE = '/configure';
export const TAG = '/tag';
export const ACCOUNT = '/account';
export const UNREADMESSAGE = '/unread-message-badge';
export const KEYWORD = '/keyword';
export const TELEGRAM_INTEGRATION = '/telegram-integration';
export const MAXIMUM_INTENT = '/maximum-intent';
export const CONVERSATION_MESSAGE = "/assign-conversation-message"



//OTHER ROUTES
export const WHATSAPP = "app-booking"
export const CHAT_WIDGET = "chat-widget"




export const SIGN_UP = '/signup';
export const LOG_IN = '/login';
export const SETUP_ACCOUNT = '/setup-account';