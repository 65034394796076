import { useEffect } from 'react';
import gsap from 'gsap';

const useGsap = (target, animationConfig, dependencies = []) => {
  useEffect(() => {
    const animation = gsap.from(target, animationConfig);

    return () => {
      animation.kill();
    };
  }, dependencies);
};

export default useGsap;
