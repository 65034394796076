import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ProtectedRoute({ children }) {

    const navigate = useNavigate();

    useEffect(()=>{
       
        if(localStorage.getItem("user_id") == 3 || localStorage.getItem("role") === "admin"){
        }
        else{
            navigate("/");
        }
    },[])

  return (
   <>{children}</>
  );
}

export default ProtectedRoute;
