import Card from "antd/es/card/Card";
import React, { useEffect, useState } from "react";
// import { GearShapeWithoutColor } from "../../../../Assets/Export/Export";
import { Button, Form, Input, Modal, Select, Switch, message } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import Navbar from "../../../../../Components/AppComponent/Navbar/Navbar";


function KeyWords() {
  const [form] = Form.useForm();
  const [starters, setStarters] = useState([]);
    const [conversation, setConversation] = useState("");


  const getData = async () => {
    try {
      const response = await axios.get("user/client/integration/status?channel=CUSTOM_KEYWORDS");
      console.log(response);
    

      setStarters(response.data.data?.keywords ? response.data.data?.keywords : []);
    
    } catch (err) {
      message.error("Error while fetching data");
    }
  };

  const onFinish = async () => {
    try {
        
        const res = await axios.post("user/client/save-booking-keywords/", {
            keywords: starters,
        })

      message.success("Data saved successfully");
    } catch (err) {
      message.error("Error while saving data");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="flex flex-col gap-3">
      <Navbar text="Meeting Keyword" image />
      <div className="w-full h-full  flex justify-between px-5 ">
        <Card className="w-full h-[95%]">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="w-[80%]"
          >
           
            <Form.Item
              label="Meeting Prompt Keywords"
              // name={"add_new_response"}
            >
              <div className="flex">
                <Input
                  onChange={(e) => {
                    setConversation(e.target.value);
                  }}
                  value={conversation}
                  placeholder="Add meeting prompt keywords"
                />
                <Button
                  onClick={(e) => {
                    setStarters([...starters, conversation]);
                    setConversation("");
                  }}
                  type="primary"
                  disabled={conversation === "" ? true : false}
                >
                  <PlusOutlined />
                </Button>
              </div>
            </Form.Item>
            <div className="flex flex-col gap-3">
              {starters.map((item, index) => {
                return (
                  <div className="flex">
                    <Input value={item} disabled />
                    <Button
                      onClick={() => {
                        setStarters(starters.filter((_, i) => i !== index));
                      }}
                      type="danger"
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                );
              })}
            </div>

         
          </Form>
        </Card>
      </div>
      <div className=" px-5 py-4">
        <Button
          onClick={() => {
            form.submit();
          }}
          type="primary"
          className="bg-[#19334E]"
        >
          Save
        </Button>
      </div>
      
    </div>
  );
}

export default KeyWords;
