import React, { useEffect, useState } from "react";
import DropdownAnimationComponent from "../../../Components/AppComponent/DropDownAnimationComponent/DropdownAnimationComponent";
import DropdownPng from "../../../Assets/GetStarted/Intercom2 1.png";
import DropdownPng2 from "../../../Assets/GetStarted/Selector2.png";
import DropdownPng3 from "../../../Assets/GetStarted/selector3.png";
import ChatWidget from "./Selector/ChatWidget/ChatWidget";
import ConfigureCommunication from "./Selector/ConfigureCommunication/ConfigureCommunication";
import SetupSwiftSales from "./Selector/SetupSwiftSales/SetupSwiftSales";
import TestChatBot from "./Selector/TestChatBot/TestChatBot";
import Navbar from "../../../Components/AppComponent/Navbar/Navbar";
import { useSelector } from "react-redux";

function Home() {
  const ClientInformation = useSelector(
    (state) => state?.Integration?.client_information
  );
  const [drop, setDrop] = useState([
    {
      id: 1,
      image: DropdownPng,
      title: "Setup the Chat widget",
      description:
        "The widget enables you to engage with customers and leverage our chatbots for efficient communication",
      container: <ChatWidget />,
    },
    {
      id: 2,
      image: DropdownPng2,
      title: "Configure communication channels",
      description:
        "Integrate your messaging platforms to streamline and centralise all your conversations in one unified hub.",
      container: <ConfigureCommunication />,
    },
    {
      id: 3,
      image: DropdownPng3,
      title: "Enable AI support",
      description:
        "Address up to 80% of customer issues using the power of SellSwift AI.",
      container: <SetupSwiftSales />,
    },
    {
      id: 4,
      image: DropdownPng,
      title: "Test the chatbot",
      description: "We built a chatbot for you. Please review it now.",
      container: <TestChatBot />,
    },
  ]);

  const setupChatWidget = useSelector(
    (state) => state?.Integration?.setup_chat_widget
  );
  const configure_communication_channels = useSelector(
    (state) => state?.Integration?.configure_communication_channels
  );
  const enable_ai_support = useSelector(
    (state) => state?.Integration?.enable_ai_support
  );
  const test_chatbot = useSelector((state) => state?.Integration?.test_chatbot);

  useEffect(() => {
    if (
      setupChatWidget?.add_chat_widget &&
      setupChatWidget?.simulate_first_conversation
    ) {
      const temp = [...drop];
      temp[0].close = true;
      setDrop(temp);
    }

    if (
      configure_communication_channels?.whatsapp_connection &&
      configure_communication_channels?.instagram_connection &&
      configure_communication_channels?.facebook_connection
    ) {
      const temp = [...drop];
      temp[1].close = true;
      setDrop(temp);
    }

    if (enable_ai_support?.setup_ai) {
      const temp = [...drop];
      temp[2].close = true;
      setDrop(temp);
    }

    if (test_chatbot?.test_chatbot) {
      const temp = [...drop];
      temp[3].close = true;
      setDrop(temp);
    }

  }, [
    setupChatWidget,
    configure_communication_channels,
    enable_ai_support,
    test_chatbot,
  ]);

  return (
    <>
      <Navbar text={"Getting Started"} image/>
      <div className="flex w-full py-[30px] px-[40px]  ">
        <div className="w-[100%]">
          <div className="text-[#8692A6] mb-1">
            Hi {ClientInformation?.name}!
          </div>
          <div className="text-[#01050F] text-2xl mb-2 font-semibold">
            Welcome to SellSwift
          </div>
          <div className="text-[#01050F] text-base font-normal mb-5">
            Prepare to impress your customers with a quick and effortless setup
            process that will only consume a few minutes of your time.
          </div>
          <div className="flex flex-col gap-4">
            {drop.length !== 0 &&
              drop?.map((item) => {
                console.log(drop);
                return (
                  <DropdownAnimationComponent
                    image={item.image}
                    key={item.id}
                    title={item.title}
                    description={item.description}
                    close={item.close}
                  >
                    {item.container}
                  </DropdownAnimationComponent>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
