import React, { useEffect } from 'react'

function ChatWidgets() {
    useEffect(()=>{
        localStorage.removeItem("token");
        window.location.reload();
    },[])

  return (
    <div>ChatWidget</div>
  )
}

export default ChatWidgets