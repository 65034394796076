import React, { useEffect, useRef, useState } from "react";
import {
  ClosedSidebar,
  EmailSidebar,
  FacebookSidebar,
  InstagramSidebar,
  MessageSideBar,
  TelegramSidebar,
  ToggleDown,
  UnassignedSidebar,
  WhatsappSidebar,
} from "../../../../Assets/Export/Export";
import gsap from "gsap";
import { EMAIL, EMAIL_INTEGRATION, FACEBOOK, FACEBOOK_INTEGRATION, INSTAGRAM, INSTAGRAM_INTEGRATION, TELEGRAM, WHATSAPP_FILTER, WHATSAPP_INTEGRATION } from "../../../../Constants/Routes/RoutesConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectChat } from "../../../../Redux/Slice/selectedConversation";

function Channel() {
  const [active, setActive] = useState(true);
  const livetoggle = useRef(null);
  const ToggleIcon = useRef(null);
  const dispatch = useDispatch();
  const handleChange = () => {
    setActive((active) => !active);
  };

  const navigate  = useNavigate();

  useEffect(() => {
    const twin = gsap.to(livetoggle.current, {
      duration: 0.5,
      height: active ? "auto" : 0,
      ease: "power3.inOut",
      autoAlpha: active ? 1 : 0,
      opacity: active ? 1 : 0,
    });
    const twin2 = gsap.to(ToggleIcon.current, {
      duration: 0.5,
      rotate: active ? 0 : 180,
      ease: "power3.inOut",
    });
    return () => {
      twin2.kill();
      twin.kill();
    };
  }, [active]);

  const location = useLocation();
  const DrodpownOption = [
    {
      icon: <EmailSidebar />,
      url: EMAIL,
      name: "Email",
      message: 1,
      active : location.pathname === EMAIL
    },
    {
      icon: <FacebookSidebar />,
      url: FACEBOOK,
      name: "Facebook",
      message: 3,
      active : location.pathname === FACEBOOK
    },
    {
      icon: <InstagramSidebar />,
      url:INSTAGRAM,
      name: "Instagram",
      message: 4,
      active : location.pathname === INSTAGRAM
    },
    {
      icon: <WhatsappSidebar />,
      url: WHATSAPP_FILTER,
      name: "Whatsapp",
      message: 4,
      active : location.pathname === WHATSAPP_FILTER
    },
    {
      icon:<TelegramSidebar/>,
      url:TELEGRAM,
      name:"Telegram",
      message:5,
      active:location.pathname===TELEGRAM
    }
  ];
  return (
    <>
      <div className="px-[20px] py-[20px]">
        <div className="flex  items-center gap-2 mb-3 cursor-pointer">
          <div className={`font-medium text-lg text-[#ACB8CB]`}>Channels</div>
          <div
            ref={ToggleIcon}
            onClick={handleChange}
            className="flex justify-center items-center"
          >
            {" "}
            <ToggleDown />
          </div>
        </div>
        <div>
          <div ref={livetoggle} className="flex flex-col gap-2">
            {DrodpownOption.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={()=>{
                    navigate(item.url)
                    dispatch(
                      setSelectChat({
                        selectedChat: null,
                        name: null,
                        recordId: null,
                        status: null
                      })
                    );
                  }}
                  className={`${
                    item.active ? "bg-[#E1EEFF0D]" : ""
                  } cursor-pointer rounded flex py-[8px] px-[17px] justify-between w-full items-center gap-2`}
                >
                  {item.icon}
                  <div
                    className={`font-bold flex-1 text-base ${
                      item.active ? "text-[#fff]" : "text-[#ACB8CB]"
                    }`}
                  >
                    {item.name}
                  </div>
                  {/* <div className="bg-[#E71333] rounded-full w-[30px] h-[20px] flex justify-center items-center">
                    <div className="font-normal text-[12px] text-[#fff]">
                      {item.message}
                    </div>
                  </div> */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Channel;
