import React, { useEffect, useState } from "react";
import Chevron from "../../../Assets/ChatComponent/chevron.down.png";
import Circle from "../../../Assets/ChatComponent/Circle.png";
import Styles from "./ChatComponent.module.css";
import { message } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";

function ChatComponent() {
  const Chat = useSelector((state) => state.chatComponent);

  useEffect(() => {
    document.getElementById("sell-swift-react-header").style.backgroundImage =
      Chat?.backgroundColor;


  }, [Chat]);



  const getData = async () => {
    try {
      const res = await axios.get("/conversation/widget/settings/");
      console.log(res.data.widget_settings);
      // const temp = [...messageChat];
      // temp[0].message = res.data.widget_settings.welcome_message;
      // setMessageChat(temp);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className={Styles.chatContainer}>
        <div id={"sell-swift-react-header"} className={Styles.header}>
          <div
            style={{
              width: "70px",
              height: "70px",
            }}
            id="sell-swift-react-avatar"
          >
            <img style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            }} className="w-full" src={Chat?.avatar ? Chat?.avatar : Circle} />
          </div>
          <div
            style={{
              display: "flex",
              gap: "3px",
              flexDirection: "column",
              flex: "1",
            }}
          >
            <div
              style={{
                fontSize: "14px",
              }}
            >
              Chat With
            </div>
            <div
              style={{
                fontSize: "16px",
              }}
            >
              {Chat?.botname}
            </div>
          </div>
          <div
            style={{
              cursor: "pointer",
            }}
          >
            <img src={Chevron} />
          </div>
        </div>

        <div
          style={{
            overflowY: "auto",
            flex: "1",
          }}
          id="chat-history"
        >
          {/* <template v-for="chat in chats"> */}
          <>
            {Chat?.messageChat?.map((chat) => {
              if (chat.message_type === "response") {
                return (
                  <div
                    style={{
                      margin: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "70%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          fontSize: "12px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{chat.agent ? chat.agent.name : "Chatbot"}</div>
                        <div>{chat.created_at_formatted}</div>
                      </div>
                      <div>
                        {(chat.message ===
                          "Would you like to make an appointment?" ||
                          chat.message ===
                          "Please schedule your appointment from below") && (
                            <>
                              <div
                                style={{
                                  backgroundColor: "#f1f0f0",
                                  padding: "10px",
                                  borderTopLeftRadius: "15px",
                                  borderTopRightRadius: "15px",
                                }}
                              >
                                {chat.message}
                              </div>
                              <div
                                style={{
                                  textAlign: "center",
                                  fontWeight: "700",
                                  cursor: "pointer",
                                  color: "#2832DF",
                                  backgroundColor: "#fff",
                                  padding: "10px",
                                  borderBottomLeftRadius: "15px",
                                  border: "1px solid #e0e0e0",
                                  borderBottomRightRadius: "15px",
                                }}
                              >
                                Scheduled Date
                              </div>
                            </>
                          )}
                        <div
                          style={{
                            backgroundColor: "#f1f0f0",
                            padding: "10px",
                            borderRadius: "15px",
                          }}
                        >
                          {chat.message}
                        </div>
                        {/* </template> */}
                      </div>
                    </div>
                  </div>
                );
              } else if (chat.message_type === "request") {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "10px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#3047EC",
                        color: "#fff",
                        textAlign: "right",
                        padding: "10px",
                        borderRadius: "15px",
                      }}
                    >
                      {/* {{chat.message}} */}
                    </div>
                  </div>
                );
              }
            })}
          </>

          {/* </template> */}
        </div>

        {/* 
       

<div v-if="chat_container"
    style="display: flex; justify-content: space-between; border-top: 1px solid #efefef;padding: 10px; ">
    <div style="flex: 1;">
        <input placeholder="Type you message here...." class="input-send-message" type="text"
            id="message_input_box" v-model='message_input' />

    </div>
    <div style="width: 50px;">
        <img v-on:click="send_message" style="width: 100%;" src="./send.png" />
    </div>
</div>

<template v-if="slots_container">
    <div style="display: flex; flex: 1; flex-direction: column; ">
        <div style=" width: 70%;margin-top: 10px;margin-left: 10px;">
            <div style="background-color: #f1f0f0; padding: 10px; border-radius: 20px; ">What time works
                best
                for
                you?
            </div>
        </div>
        <div style="flex:1; padding: 10px; margin-top: 20px; overflow: auto;">
            <div style="display: flex; flex-wrap: wrap; gap: 10px">
                <div v-for="event in eventData" v-on:click="handleEventClick(event.id, event.time)"
                    :class="getClass(event.id)"
                    style="font-size: 13px; font-weight: 700; width: 17%;background-color: #fff; padding:10px 10px;border: 1px solid #E0E0E0;  border-radius: 10px; cursor: pointer;">
                    {{event.time}}
                </div>
            </div>
            <div style="width: 80%;margin: 30px auto auto;text-align: center;"
                v-if="booking_information.selected_time != null">
                <button v-on:click="show_user_form"
                    style="width: 100%;  outline: none; border: none; padding: 18px 15px; border-radius: 10px; background-color: #3047EC; color: #fff; font-size: 16px; font-weight: 600; cursor: pointer;">
                    Continue
                </button>
            </div>
        </div>
    </div>
</template>

<template v-if="user_form_container">
    <div style=" width: 70%;margin-top: 10px;margin-bottom: 20px; margin-left: 10px;">
        <div style="background-color: #f1f0f0; padding: 10px; border-radius: 20px; ">Please enter your
            personnel
            details below
        </div>
    </div>
    <div style="flex:1;margin-left: 10px;width: 90%; display: flex; flex-direction: column; gap:7px;">
        <div style=" display:flex; flex-direction: column; gap: 8px; ">
            <div style="font-weight: 600; font-size: 16px;">First Name</div>
            <input autocomplete="name" name="name" id="chat_user_name_input" autocomplete="on" type="text" v-model="user_information.first_name" style="width: 95%; outline: none; border: 1px solid #21232C1A; padding: 14px 15px; border-radius: 10px;" placeholder="Type here...">

        </div>

        <div style=" display:flex; flex-direction: column; gap: 10px; ">
            <div style="font-weight: 600; font-size: 16px;">Last Name</div>
            <input autocomplete="lastName" id="chat_user_last_name_input" type="text" v-model="user_information.last_name"
                style="width: 95%; outline: none; border: 1px solid #21232C1A; padding: 14px 15px; border-radius: 10px;"
                placeholder="Type here...">
        </div>

        <div style=" display:flex; flex-direction: column; gap: 8px; ">
            <div style="font-weight: 600; font-size: 16px;">Email Address</div>
            <input id="chat_user_email_input" type="text" v-model="user_information.email"
                style="width: 95%; outline: none; border: 1px solid #21232C1A; padding: 14px 15px; border-radius: 10px;"
                placeholder="Type here...">
        </div>

        <div style=" display:flex; flex-direction: column; gap: 8px; ">
            <div style="font-weight: 600; font-size: 16px;">Mobile Number</div>
            <div style="display: flex;">
                <select
                v-model="user_information.country_code"
                    style="width: 20%; outline: none; border: 1px solid #21232C1A; padding: 5px; border-radius: 10px;">

                    <option value="+65">+65</option>
                    <option value="+91">+91</option>
                    <option value="+1">+1</option>
                    <option value="+44">+44</option>
                    <option value="+61">+61</option>
                    <option value="+64">+64</option>
                    <option value="+81">+81</option>
                    <option value="+86">+86</option>
                    <option value="+82">+82</option>
                    <option value="+852">+852</option>
                    <option value="+60">+60</option>
                    <option value="+66">+66</option>
                    <option value="+84">+84</option>
                    <option value="+62">+62</option>
                    <option value="+63">+63</option>
                    <option value="+82">+82</option>
                    <option value="+965">+965</option>

                </select>
                <input onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    id="chat_user_mobile" type="text" v-model="user_information.mobile"
                    style="width: 95%; outline: none; border: 1px solid #21232C1A; padding: 14px 15px; border-radius: 10px;"
                    placeholder="Type here...">
            </div>
        </div>

    </div>
    <div style="text-align: center;">
        <button v-on:click="create_booking"
            style="width: 93%;  outline: none; border: none; padding: 18px 15px; border-radius: 10px; background-color: #3047EC; color: #fff; font-size: 16px; font-weight: 600; cursor: pointer;">
            Submit
        </button>
    </div>
</template>


<template v-if="calendar_container">
    <div style="flex:1; display: flex; flex-direction: column;">
        <div style=" width: 70%;margin-top: 10px;margin-left: 10px;">
            <div style="background-color: #f1f0f0; padding: 10px; border-radius: 20px; ">Please select your
                preferred date</div>
        </div>
        <div style="flex: 1; width: 100%;" id='calendar'></div>
    </div>
</template>


<template v-if="booking_confirmed">
    <div
        style="display: flex; flex:1; justify-content: center; gap: 2px; align-items: center; flex-direction: column;">
        <div> <img style="width: 100%; height: 100%;" src="./success 1.png" /></div>
        <div style="font-weight: 700; font-size: 24px;">Booking Confirmed</div>
        <div style="font-weight: 400; font-size: 16px;">Your appointment booked successfully </div>
        <div style="width: 80%; margin-top: 10px; text-align: center;" v-on:click="show_chats">
            <button
                style="width: 100%;  outline: none; border: none; padding: 18px 15px; border-radius: 10px; background-color: #3047EC; color: #fff; font-size: 16px; font-weight: 600; cursor: pointer;">
                Go Back
            </button>
        </div>
    </div>

</template>



<div style="width: 90%; margin-bottom: 10px;" v-if="chatContainer">
    <div style="width: 95%; height: 30px;display: flex; justify-content: flex-end ; align-items: flex-end;">
        <img style="width: 120px;text-align: right;" src="./PoweredBySellSwift.png" />
    </div>
</div> */}
      </div>
    </>
  );
}

export default ChatComponent;
