import React, { useState } from "react";
import Navbar from "../../../Components/AuthComponent/Navbar";
import { Button, Form, Steps } from "antd";
import Steps1 from "./Steps/Steps1";
import Steps2 from "./Steps/Steps2";
import Steps3 from "./Steps/Steps3";

function SetupAccount() {
  const [current, setCurrent] = useState(0);

  const next = () => {
    if (current !== 2) {
      setCurrent((current) => current + 1);
    }
  };

  const prev = () => {
    if (current !== 0) {
      setCurrent((current) => current - 1);
    }
  };

  return (
    <div className="w-full h-full bg-white">
      <Navbar />

      <div className="w-96 m-auto">
        <Steps current={current}>
          <Steps.Step />
          <Steps.Step />
          <Steps.Step />
        </Steps>
      </div>
      <Form>
        {current === 0 && <Steps1 />}
        {current === 1 && <Steps2 />}
        {current === 2 && <Steps3 />}
      </Form>
      <div className="py-[20px] footer bg-[#fff] sticky left-0 bottom-0 w-full d-flex justify-center items-center">
        <div className="flex justify-center items-center gap-3">
          {current !== 0 && (
            <div>
              <Button onClick={prev} className="w-[250px] h-[40%]">
                Go Back
              </Button>
            </div>
          )}
          <div>
            <Button
              onClick={next}
              className="bg-[#0766FF] w-[250px] h-[40%]"
              type="primary"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetupAccount;
