import React, { useEffect, useState } from "react";
import { Card, Form, Upload, message, Input, Select, Modal, Button } from "antd";
import Navbar from "../../../../Components/AppComponent/Navbar/Navbar";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";

function Account() {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState(null);
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");

  const getData = async () => {
    setName(localStorage.getItem("name"));
    setEmailId(localStorage.getItem("email"));
    setImageUrl(localStorage.getItem("base64Image"));
  }

  useEffect(() => {
    getData();
  }, [])

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChange = (info) => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (url) => {
      setImageUrl(url);
    });
  };

  const onFinish = async (values) => {
    setLoading(true)
    try {
      const res = await axios.post("user/reset/password/", {
        "old_password": values.oldPassword,
        "new_password": values.newPassword,
        "confirm_password": values.confirmPassword
      })
      message.success("Password Updated Successfully");
      form2.resetFields();
      setVisible(false);
    } catch (err) {
      message.error(err.response.data.message)
    }
    setLoading(false)
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handleFinish = async () => {
    setLoading(true)
    try {
      const res = await axios.post("user/profile/update/", {
        name: name,
        email: emailId,
        image_base64: imageUrl
      })
      message.success("Profile Updated Successfully");
      localStorage.setItem("name", name);
      localStorage.setItem("email", emailId);
      localStorage.setItem("base64Image", imageUrl)
    } catch (err) {
      message.error("Cannot Update");
    }
    setLoading(false)
  }
  return (
    <div>
      <Navbar text={"Account"} image />
      <div className="p-3 w-full">
        <div className="w-full">
          <div className="flex gap-1 items-center justify-between w-full bg-[#fff] py-3 px-2">
            <Form className="w-full flex flex-col gap-4">
              <div className="flex w-full font-bold">
                <div className="w-[15%]">Profile Picture</div>
                <div>
                  <Upload
                    name="avatar"
                    listType="picture-circle"
                    className="avatar-uploader"
                    showUploadList={false}
                    onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </div>
              </div>

              <div className="flex w-full  font-bold">
                <div className="w-[15%]">Name</div>
                <div className="w-[30%]">
                  <Input value={name} onChange={(e) => {
                    setName(e.target.value)
                  }} placeholder="Type here" />
                </div>
              </div>

              <div className="flex w-full  font-bold">
                <div className="w-[15%]">Email ID</div>
                <div className="w-[30%]">
                  <Input
                    value={emailId}
                    onChange={(e) => {
                      setEmailId(e.target.value)
                    }}
                    placeholder="Type here" />
                </div>
              </div>

              {/* <div className="flex w-full  font-bold">
                <div className="w-[15%]">Country</div>
                <div className="w-[30%]">
                  <Select className="w-full" />
                </div>
              </div>

              <div className="flex w-full  font-bold">
                <div className="w-[15%]">Language</div>
                <div className="w-[30%]">
                  <Select className="w-full" />
                </div>
              </div> */}

              <div className="flex w-full  font-bold">
                <div className="w-[15%]">Password</div>
                <div
                  className="w-[30%] underline cursor-pointer"
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  Change Password
                </div>
              </div>
              <div className="mt-5">
                <Button
                  type="primary"
                  loading={loading}
                  className="w-36 bg-[#19334E]"
                  onClick={handleFinish}
                >Save</Button>
              </div>
            </Form>
          </div>
          <Modal
            visible={visible}
            onCancel={() => {
              setVisible(false);
            }}
            title="Change Password"
            confirmLoading={loading}
            centered
            onOk={() => {
              form2.submit();
            }}
            className="modal-for-padding"
          >
            <Form form={form2} layout="vertical" onFinish={onFinish}>
              <Form.Item
                label="Old Password"
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input old password!",
                  },
                ]}
              >
                <Input placeholder="Old Password" />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input new password!",
                  },
                ]}
              >
                <Input placeholder="New Password" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input confirm password!",
                  },
                ]}
              >
                <Input placeholder="Confirm Password" />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>

    </div>
  );
}

export default Account;
