import { Button, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import ChatConversation from "../../../../../Components/AppComponent/ChatConversation/ChatConversation";
import {
  DropdonwIcon,
  GetStartedSetting,
  MobileWidget,
  Multilingual,
  WidgetAppearence,
  WidgetVisibilty,
} from "../../../../../Assets/Export/Export";
import AppearenceSelector from "./AppearenceSelector/AppearenceSelector";
import WidgetAppearenceContainer from "./SelectorComponent/WidgetAppearence";
import Navbar from "../../../../../Components/AppComponent/Navbar/Navbar";
import ChatComponent from "../../../../../Components/AppComponent/ChatComponent/ChatComponent";
import SendOutlined from "../../../../../Assets/ChatComponent/bubble.right.fill.png";
import { useSelector } from "react-redux";
import WidgetVisibilityComponent from "./SelectorComponent/WidgetVisibility";
import axios from "axios";

function Appearence() {
  const [selected, setSelected] = React.useState("chat");
  const Chat = useSelector((state) => state.chatComponent);
  const { appearence } = useSelector((state) => state);
  const [widgetVisibility, setWidgetVisibility] = React.useState(false);
  const [device, setDevice] = React.useState("all");
  const [loading, setLoading] = React.useState(false);
  const [botName, setBotName] = useState("");
  const [widgetPosition, setWidgetPosition] = useState("left");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [buttonSwitch, setButtonSwitch] = useState(false);
  const [buttonSize, setButtonSize] = useState("medium");
  const [labelText, setLabelText] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const onFinish = async () => {
    if (!welcomeMessage) {
      message.error("Welcome Message Cannot Be Empty");
      return;
    }

    if (buttonSwitch && !labelText) {
      message.error("Label Text Cannot Be Empty");
      return;
    }
    setLoading(true);


    try {
      const res = await axios.post("/conversation/widget/settings/", {
        widget_visibility: {
          display_widget: widgetVisibility,
          widget_devices: device,
        },
        widget_appearance: {
          color: appearence.color,
          image_base64: imageUrl,
          widget_position: widgetPosition,
          welcome_message: welcomeMessage,
          button_switch: buttonSwitch,
          label_text: labelText,
          name: botName,
          widget_bubble_size: buttonSize,
        },
      });
      message.success("Widget Updated");
      window.location.reload();
    } catch (err) {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  // console.log(Chat.buttonSize)
  const Options = [
    {
      id: 1,
      title: "Widget Appearance",
      icon: <WidgetAppearence />,
      Child: <WidgetAppearenceContainer botName={botName} setBotName={setBotName} widgetPosition={widgetPosition} setWidgetPosition={setWidgetPosition}
        welcomeMessage={welcomeMessage} setWelcomeMessage={setWelcomeMessage} buttonSwitch={buttonSwitch} setButtonSwitch={setButtonSwitch} buttonSize={buttonSize} setButtonSize={setButtonSize} labelText={labelText} setLabelText={setLabelText} onFinish={onFinish} loading={loading}
        imageUrl={imageUrl} setImageUrl={setImageUrl}
        />,
    },
    {
      id: 2,
      title: "Widget Visibility",
      icon: <WidgetVisibilty />,
      Child: <WidgetVisibilityComponent widgetVisibility={widgetVisibility} device={device} setDevice={setDevice} setWidgetVisibility={setWidgetVisibility} />,
    },
    // {
    //   id: 3,
    //   title: "Get Started",
    //   icon: <GetStartedSetting />,
    // },
    // {
    //   id: 4,
    //   title: "Mobile Widget",
    //   icon: <MobileWidget />,
    // },
    // {
    //   id: 5,
    //   title: "Mobile Widget",
    //   icon: <Multilingual />,
    // },
  ];

  return (
    <>
      <Navbar text={"Appearence"} image />
      <div className="w-full flex justify-between h-full relative">
        <div className="w-[49.9%] bg-[#fff] p-6  flex flex-col gap-5 sidebar-scroll">
          {Options.map((item) => {
            return (
              <AppearenceSelector
                key={item.id}
                icon={item.icon}
                title={item.title}
              >
                {item.Child}
              </AppearenceSelector>
            );
          })}
          <div className="mt-5">
          <Button
            type="primary"
            loading={loading}
            onClick={onFinish}
            className="w-36 bg-[#19334E]"
          >
            Save
          </Button>
        </div>
        </div>
        <div className="w-[50%] bg-[#fff] p-4 h-full">
          <div className="flex justify-between mb-5">
            <div className="font-extrabold text-lg">Preview</div>
            <div className="w-36">
              <Select
                value={selected}
                onChange={(e) => {
                  setSelected(e);
                }}
                className="w-full"
              >
                <Select.Option value="chat">Chat Open</Select.Option>
                <Select.Option value="widget">Chat Close</Select.Option>
              </Select>
            </div>
          </div>
          <div className="h-full">
            {selected === "chat" ? (
              <ChatComponent />
            ) : (
              <>
                {Chat?.visible && (
                  <div className="h-full relative">
                    {Chat?.buttonSwitch && (
                      <div
                        className={`absolute bottom-[78px] ${Chat?.position === "right" ? `${Chat?.buttonSize === "medium"
                          ? "right-20"
                          : Chat?.buttonSize === "small"
                            ? "right-16"
                            : " right-24"
                          }` : "left-20 "
                          } cursor-pointer box-shadow bg-[#fff] z-[100000000000000000] p-6 rounded-3xl`}
                        style={{
                          boxShadow: "#0000001A 0px 0px 10px",
                        }}
                      >
                        {Chat.buttonLabel}
                      </div>
                    )}
                    <div
                      className={`rounded-full p-2 ${Chat?.buttonSize === "medium"
                        ? "h-16 w-16"
                        : Chat?.buttonSize === "small"
                          ? "h-12 w-12"
                          : " h-20 w-20"
                        } absolute bottom-20 ${Chat?.position === "right" ? "right-2" : "left-2"
                        } `}
                      style={{ backgroundImage: Chat.backgroundColor }}
                    >
                      <img className="w-full" src={SendOutlined} />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Appearence;
