import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  progress: 0,
  client_information:{},
  configure_communication_channels: {},
  enable_ai_support: {},
  setup_chat_widget: {},
  test_chatbot: {},
};


const IntegrationSlice = createSlice({
    name: "Integration",
    initialState: initialState,
    reducers: {
        setProgress(state, action) {
        return {
            ...state,
            progress: action.payload,
        };
        },
        setConfigureCommunicationChannels(state, action) {
        return {
            ...state,
            configure_communication_channels: action.payload,
        };
        },
        setEnableAiSupport(state, action) {
        return {
            ...state,
            enable_ai_support: action.payload,
        };
        },
        setSetupChatWidget(state, action) {
        return {
            ...state,
            setup_chat_widget: action.payload,
        };
        },
        setTestChatbot(state, action) {
        return {
            ...state,
            test_chatbot: action.payload,
        };
        },
        setClientInformation(state, action) {
        return {
            ...state,
            client_information: action.payload,
        };
        
        }
    },
})


export const {
    setProgress,
    setConfigureCommunicationChannels,
    setEnableAiSupport,
    setSetupChatWidget,
    setTestChatbot,
    setClientInformation
} = IntegrationSlice.actions;

export default IntegrationSlice.reducer;