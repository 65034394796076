import React from 'react'
import { Routes,BrowserRouter as Router,Route, Navigate } from 'react-router-dom'
import Login from './Login/Login'
import "./AuthView.css"
import CreateNewAccount from './CreateNewAccount/CreateNewAccount'
import { CHAT_WIDGET, LOG_IN, SETUP_ACCOUNT, SIGN_UP, WHATSAPP } from '../../Constants/Routes/RoutesConstant'
import SetupAccount from './SetupAccount/SetupAccount'

function AuthView() {
  return (
    <>
        <Router>
            <Routes>
                <Route path={SIGN_UP} element={<CreateNewAccount />} />
                <Route path={LOG_IN} element={<Login/>} />
                <Route path={SETUP_ACCOUNT} element={<SetupAccount/>}/>
                <Route path={WHATSAPP} element={<></>}/>
                <Route path={CHAT_WIDGET} element={<></>}/>
                <Route path="*" element={<Navigate to={LOG_IN}/>}/>
            </Routes>
        </Router>
    </>
  )
}

export default AuthView