import { Layout } from "antd";
import React, { useEffect, useRef } from "react";
import { CollapsedMenu, DataSource, HubSellSwift, TrialSource } from "../../../Assets/Export/Export";
import gsap from "gsap";
import { ALL_BOOKINGS_CONFIRMED, DATASOURCE, HUB, MANUAL_BOOKING, TOTAL_BOOKINGS, TRIAL } from "../../../Constants/Routes/RoutesConstant";
import { Link, useLocation } from "react-router-dom";

function SearchCollapsed({ collapsed, setCollapsed }) {
  const option = useRef(null);

  useEffect(() => {
    if (collapsed) {
      const twin = gsap.to(`.sidebar5`, {
        duration: 0.5,
        width: 0,
        ease: "power3.inOut",
        autoAlpha: 0,
        opacity: 0,
      });

      option.current.style.display = "none";
      return () => {
        twin.kill();
      };
    } else {
      const twin = gsap.to(`.sidebar5`, {
        duration: 0.4,
        width: 220,
        ease: "power3.inOut",
        fontSize: "auto",
        autoAlpha: 1,
        onComplete: () => {
          option.current.style.display = "block";
        },
      });

      return () => {
        twin.kill();
      };
    }
  }, [collapsed]);

  const location = useLocation();


  return (
    <div className={`sidebar5 bg-[#fff] flex-col flex h-full absolute z-40 w-[220px]`} >
      <div className="flex justify-between items-center p-[19px] chat-collapsed">
        <div className="text-lg font-bold ">SellSwift</div>
        <div
          className="cursor-pointer "
          onClick={() => {
            setCollapsed((collapsed) => !collapsed);
          }}
        >
          <CollapsedMenu />
        </div>
      </div>
      <div ref={option} className="sidebar-scroll" style={{height:"100vh"}}>
        dsa
        </div>
    </div>
  );
}

export default SearchCollapsed;
