import React, { useEffect, useRef, useState } from "react";
import Tabs from "../../../../../Components/AppComponent/Tabs/Tabs";
import FirstImage from "../../../../../Assets/GetStarted/Frame 1261155002.png";
import SecondImage from "../../../../../Assets/GetStarted/instagram.png";
import ThirdImage from "../../../../../Assets/GetStarted/Group 1000013779.png";
import FourthImage from "../../../../../Assets/GetStarted/telegram.png";
import FifthImage from "../../../../../Assets/GetStarted/email.png";
import ScreenForSelector from "../../HomeComponent/ScreenForSelector";
import gsap from "gsap";
import useGsap from "../../../../../Hooks/useGsap";
import { useDispatch, useSelector } from "react-redux";
import { setClientInformation, setConfigureCommunicationChannels, setEnableAiSupport, setProgress, setSetupChatWidget, setTestChatbot } from "../../../../../Redux/Slice/IntegrationSlice";
import { UpdateIntegration } from "../../../../../Utils/UpdateIntegration";

function ConfigureCommunication() {
  const [active, setActive] = useState(1);
  const Screen = useRef(null);
  const setupChatWidget = useSelector(
    (state) => state?.Integration?.setup_chat_widget
  );
  const configure_communication_channels = useSelector(
    (state) => state?.Integration?.configure_communication_channels
  );
  const enable_ai_support = useSelector(
    (state) => state?.Integration?.enable_ai_support
  );
  const test_chatbot = useSelector((state) => state?.Integration?.test_chatbot);

  const Options = [
    {
      id: 1,
      name: "Connect Whatsapp",
      done: configure_communication_channels?.whatsapp_connection,
    },
    {
      id: 2,
      name: "Connect Instagram",
      done: configure_communication_channels?.instagram_connection,
    },
    {
      id: 3,
      name: "Connect Facebook",
      done: configure_communication_channels?.facebook_connection,
    },
    {
      id: 4,
      name: "Connect Telegram",
      done:configure_communication_channels?.telegram_connection,
    },
    {
      id: 5,
      name: "Connect Email",
      done:configure_communication_channels?.email_connection,
    }
  ];


  const dispatch = useDispatch();
  const onMarkAsDone = async (value) => {
    const res = await UpdateIntegration({
      setup_chat_widget: {
        ...setupChatWidget,
      },
      configure_communication_channels: {
        ...configure_communication_channels,
        [value]: true,
      },
      enable_ai_support: {
        ...enable_ai_support,
      },
      test_chatbot: {
        ...test_chatbot,
      },
    });
    dispatch(setProgress(res?.progress));
    localStorage.setItem("progress", (res?.progress));
    dispatch(
      setConfigureCommunicationChannels(
        res?.progress_information?.configure_communication_channels
      )
    );
    dispatch(setEnableAiSupport(res?.progress_information?.enable_ai_support));
    dispatch(setSetupChatWidget(res?.progress_information?.setup_chat_widget));
    dispatch(setTestChatbot(res?.progress_information?.test_chatbot));
    dispatch(setClientInformation(res?.client));
  };

  

  useEffect(() => {
    if(!configure_communication_channels?.whatsapp_connection){
      setActive(1)
      return;
    }
    if(!configure_communication_channels?.instagram_connection){
      setActive(2)
      return;
    }
    if(!configure_communication_channels?.facebook_connection){
      setActive(3)
      return;
    }
    if(!configure_communication_channels?.telegram_connection){
      setActive(4)
      return;
    }
    if(!configure_communication_channels?.email_connection){
      setActive(5)
      return;
    }

  }, [configure_communication_channels]);

  const SubOptions = [
    {
      id: 1,
      title:
        "Keep a direct line of communication open with your customers and followers through WhatsApp.",
      subTitle:
        "Integrate your WhatsApp Messages for seamless customer communication.",
      buttonText: "Connect Whatsapp",
      linkText: "Mark as done",
      image: FirstImage,
      onclick: () => onMarkAsDone("whatsapp_connection"),
      hide:true
    },
    {
      id: 2,
      title:
        "Maintain a connection with your customer base and audience on Instagram.",
      subTitle:
        "Link your Instagram account to engage with customers through direct messaging and enhance communication.",
      buttonText: "Connect Instagram",
      linkText: "Mark as Done",
      image: SecondImage,
      onclick: () => onMarkAsDone("instagram_connection"),
      hide:true
    },
    {
      id: 3,
      title:
        "Keep a close connection with your customers and followers through Facebook Messenger.",
      subTitle:
        "Integrate Facebook Messenger to engage with your customers through direct messaging for seamless communication.",
      buttonText: "Connect Facebook",
      linkText: "Mark as Done",
      image: ThirdImage,
      onclick: () => onMarkAsDone("facebook_connection"),
      hide:true
    },
    {
      id: 4,
      title:
        "Maintain a strong connection with your customers and followers through Telegram Messenger.",
      subTitle:
        "Integrate Telegram to engage with your customers through direct messaging for seamless communication.",
      buttonText: "Connect Telegram",
      linkText: "Mark as Done",
      image: FourthImage,
      onclick: () => onMarkAsDone("telegram_connection"),
      hide:true
    },
    {
      id: 5,
      title:
        "Integrate Email with Sellswift.",
      subTitle:
        "Integrate your email seamlessly to streamline customer communication through the unified sellswift Inbox experience!",
      buttonText: "Connect Email",
      linkText: "Mark as Done",
      image: FifthImage,
      onclick: () => onMarkAsDone("email_connection"),
      hide:true
    },
  ];
  useGsap(
    Screen.current,
    { duration: 0.5, opacity: 0, y: 100, ease: "ease-in" },
    [active]
  );

  return (
    <>
      <div className="flex justify-between items-center h-full p-10">
        <div className="flex w-[30%] flex-col bg-[#F3F3F3] rounded-2xl py-[24px] px-[14px] gap-8 h-[400px]">
          {Options.map((item, index) => {
            return (
              <Tabs
                image={item.image}
                active={active}
                setActive={setActive}
                name={item.name}
                key={index}
                id={item.id}
                done={item.done}
              />
            );
          })}
        </div>
        <div
          ref={Screen}
          className="flex-1 gap-3 flex flex-col justify-center items-center"
        >
          {SubOptions.map((item, index) => {
            return (
              <>
                {active === index + 1 && (
                  <ScreenForSelector
                    key={index}
                    image={item.image}
                    title={item.title}
                    subTitle={item.subTitle}
                    buttonText={item.buttonText}
                    linkText={item.linkText}
                    onclick={item.onclick}
                    hide={item.hide}
                  />
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ConfigureCommunication;
