import { message } from "antd";
import axios from "axios";

export const SolvedConversation = async (type,id) => {
  try {
    const response = await axios.post("conversation/status_change/", {
      status:type,
      conversation_id: id,
    }); 
    console.log(type)
    if(type === "closed"){
      message.success("Conversation Closed successfully");
    }
    else if(type==="resolved"){
      message.success("Conversation Resolved successfully");
    }
    else{
        message.success("Conversation Opened successfully");
    }
    return response.data;
  } catch (err) {
    message.error("Failed to Closed Conversation");
  }
};
