import Card from "antd/es/card/Card";
import React, { useEffect, useState } from "react";
// import { GearShapeWithoutColor } from "../../../../Assets/Export/Export";
import { Button, Form, Input, Modal, Select, Switch, message } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import Navbar from "../../../../../Components/AppComponent/Navbar/Navbar";


function MaximumIntent() {
  const [form] = Form.useForm();
  const [maximum, setMaximum] = useState(0);



  const getData = async () => {
    try {
      const response = await axios.get("user/client/integration/status?channel=MAX_SUCCESS_INTENT_COUNTER");
    

      setMaximum( response.data.data?.max_success_intent_counter);
    
    } catch (err) {
      message.error("Error while fetching data");
    }
  };

  const onFinish = async () => {
    try {
        
        const res = await axios.post("user/client/save-max-success-intent-counter/", {
            max_success_intent_counter: maximum,
        })

      message.success("Data saved successfully");
    } catch (err) {
      message.error("Error while saving data");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="flex flex-col gap-3">
      <Navbar text="Max Success Intent Counter" image />
      <div className="w-full h-full  flex justify-between px-5 ">
        <Card className="w-full h-[95%]">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="w-[80%]"
          >
           
            <Form.Item
              label="Max Success Intent Counter"
              // name={"add_new_response"}
            >
              <div className="flex">
                <Input
                  onChange={(e) => {
                    setMaximum(e.target.value);
                  }}
                  value={maximum}
                  placeholder="Add meeting MaximumIntent"
                />

              </div>
            </Form.Item>
           

         
          </Form>
        </Card>
      </div>
      <div className=" px-5 py-4">
        <Button
          onClick={() => {
            form.submit();
          }}
          type="primary"
          className="bg-[#19334E]"
        >
          Save
        </Button>
      </div>
      
    </div>
  );
}

export default MaximumIntent;
