import React from "react";
import Navbar from "../../../Components/AppComponent/Navbar/Navbar";
import SettingSidebar from "./SettingSidebar/SettingSidebar";
import Appearence from "./SettingComponents/Appearence/Appearence";
import { useSelector } from "react-redux";
import Configure from "./SettingComponents/Configure";

function Settings() {
  const setting  = useSelector((state) => state.setting);

 const SettingComponents =()=>{
    switch (setting.settingRoute) {
      case "appearence":
        return <Appearence />;
      case "sidebar":
        return <div>Sidebar</div>;
      case "translation":
        return <div>Translation</div>;
      case "configure":
        return <Configure/>;
      default:
        return <Appearence />;
    }
 }

  return (
    <>
      <Navbar text={"Settings"} image />
      <div className="w-full flex h-[88%] justify-between">
       
        <div className="flex w-full h-full p-4 pb-10 justify-center items-center">
            {/* {SettingComponents()} */}
        </div>
      </div>
    </>
  );
}

export default Settings;
