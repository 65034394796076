import React from "react";
import { Button } from "antd";

function ScreenForSelector({
  image,
  title,
  subTitle,
  buttonText,
  linkText,
  onclick,
  hide,
  buttonClick
}) {
  return (
    <>
      <div>
        <img src={image} />
      </div>
      <div className="font-bold text-2xl  text-center w-[86%]">{title}</div>
      <div className="font-normal text-sm text-center w-[86%]">{subTitle}</div>
     {!hide &&   <div>
        <Button
          type="primary"
          onClick={buttonClick}
          className="bg-[#19334E] border-none h-11 w-48 text-[#fff] font-normal text-base"
        >
          {buttonText}
        </Button>
      </div>}
      <div>
        <Button 
          onClick={onclick}
        className="bg-none underline w-48 text-[#19334E] font-normal text-base">
          {linkText}
        </Button>
      </div>
    </>
  );
}

export default ScreenForSelector;
