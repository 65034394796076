import { createSlice } from "@reduxjs/toolkit";
import Circle from "../../Assets/ChatComponent/Circle.png";

const initialState = {
  backgroundColor: "",
  avatar: Circle,
  welcomeMessage: "",
  buttonLabel: "",
  buttonSwitch: false,
  position: "right",
  visible: true,
  botname:"Jenny",
  buttonSize:"medium",
  messageChat: [
    {
      record_id: "xxx",
      created_at_formatted: "",
      message: "Hello! How can I assist you today?",
      message_type: "response",
      agent: null,
    },
  ],
};

const chatComponentSlice = createSlice({
  name: "chatComponent",
  initialState,
  reducers: {
    setBackgroundColor: (state, action) => {
      state.backgroundColor = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setWelcomeMessage: (state, action) => {
      state.welcomeMessage = action.payload;
    },
    setButtonLabel: (state, action) => {
      state.buttonLabel = action.payload;
    },
    setMessageChat: (state, action) => {
      state.messageChat[0].message = action.payload;
    },
    setButtonSwitchRedux: (state, action) => {
      state.buttonSwitch = action.payload;
    },
    setPosition: (state, action) => {
      state.position = action.payload;
    },
    setVisible: (state, action) => {
      state.visible = action.payload;
    },
    setChatBotName:(state,action)=>{
      state.botname = action.payload;
    },
    setButtonSizes:(state,action)=>{
      state.buttonSize = action.payload;
    }
  },
});

export const {
  setBackgroundColor,
  setAvatar,
  setWelcomeMessage,
  setButtonLabel,
  setChatBotName,
  setMessageChat,
  setButtonSwitchRedux,
  setPosition,
  setVisible,
  setButtonSizes
} = chatComponentSlice.actions;
export default chatComponentSlice.reducer;
