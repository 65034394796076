import React, { useEffect, useState } from 'react'
import Navbar from '../../../Components/AppComponent/Navbar/Navbar'
import { Button, Card, Divider, Switch, message } from 'antd'
import { AllConversationBadge, ClosedBadge, MyConversationBadge, UnassignedBadge } from '../../../Assets/Export/Export'
import axios from 'axios'

function UndreadMessage() {

    const [switchArrary, setSwitchArray] = useState({
        allConversation: true,
        myConversation: true,
        unassigned: true,
        closed: true

    })

    const [loading, setLoading] = useState(false)


    const getData = async () => {
        try {
            const res = await axios.get("core/dashboard/settings")
            setSwitchArray({
                allConversation: res.data.dashboard_settings.show_all_unread_counter,
                myConversation: res.data.dashboard_settings.show_my_unread_counter,
                unassigned: res.data.dashboard_settings.show_unassigned_unread_counter,
                closed: res.data.dashboard_settings.show_closed_unread_counter
            })
        } catch (err) {
            message.error(err.response.data.message)
        }
    }


    const updateData = async () => {
        try {
            setLoading(true)
            const res = await axios.post("core/dashboard/settings/", {
                show_all_unread_counter: switchArrary.allConversation,
                show_my_unread_counter: switchArrary.myConversation,
                show_unassigned_unread_counter: switchArrary.unassigned,
                show_closed_unread_counter: switchArrary.closed
            })
            message.success("Data saved successfully")
            window.location.reload()
            setLoading(false)
        } catch (err) {
            setLoading(false)
            message.error(err.response.data.message)
        }

    }

    useEffect(() => {
        getData();
    }, [])
    return (
        <div>
            <Navbar text={"Unread message badge"} image />
            <div className="p-3 w-full">
                <div className="w-full">
                    <Card
                        title="Choose where unread message indicators appear for a tailored notification experience."
                        className="w-full ">
                        <div className='w-[30%] '>
                            <div className='flex justify-between items-center'>
                                <div className='flex gap-3'>
                                    <div><AllConversationBadge /></div>
                                    <div className='font-bold text-base'>All Conversation</div>
                                </div>
                                <Switch checked={switchArrary.allConversation} onChange={(e) => {
                                    setSwitchArray({ ...switchArrary, allConversation: e })

                                }} />
                            </div>
                            <Divider />
                            <div className='flex justify-between items-center'>
                                <div className='flex gap-3'>
                                    <div><MyConversationBadge /></div>
                                    <div className='font-bold text-base'>My Conversation</div>
                                </div>
                                <Switch checked={switchArrary.myConversation} onChange={(e) => {
                                    setSwitchArray({ ...switchArrary, myConversation: e })

                                }} />
                            </div>
                            <Divider />
                            <div className='flex justify-between items-center'>
                                <div className='flex gap-3'>
                                    <div><UnassignedBadge /></div>
                                    <div className='font-bold text-base'>Unassigned</div>
                                </div>
                                <Switch checked={switchArrary.unassigned} onChange={(e) => {
                                    setSwitchArray({ ...switchArrary, unassigned: e })

                                }} />
                            </div>
                            <Divider />
                            <div className='flex justify-between items-center'>
                                <div className='flex gap-3'>
                                    <div><ClosedBadge /></div>
                                    <div className='font-bold text-base'>Closed</div>
                                </div>
                                <Switch checked={switchArrary.closed} onChange={(e) => {
                                    setSwitchArray({ ...switchArrary, closed: e })

                                }} />
                            </div>
                            <Divider />

                        </div>
                        <div className='text-right'>
                            <Button loading={loading} onClick={updateData} className='w-[100px]'>Save</Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default UndreadMessage