import { message } from "antd";
import axios from "axios"

export const Integration = async() => {
    try{
        const res = await axios.get("/user/client/progress/");
        return res?.data?.onboarding_progress;
    }catch(err){
        console.log(err);
        message.error("Something went wrong");
    }
}