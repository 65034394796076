import { createSlice } from "@reduxjs/toolkit";

const initialState={
    selectedChat:null,
    recordId:null,
    name:null,
    status:null
}

const selectedChatSlice=createSlice({
    name:"selectedConversation",
    initialState:initialState,
    reducers:{
        setSelectChat(state,action){
            console.log(action)
            state.selectedChat=action.payload.selectedChat;
            state.name=action.payload.name;
            state.recordId=action.payload.recordId;
            state.status=action.payload.status;


        }
    }
})

export const {setSelectChat}=selectedChatSlice.actions;
export default selectedChatSlice.reducer;