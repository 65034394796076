import React, { useEffect, useRef, useState } from "react";
import { DropdonwIcon } from "../../../Assets/Export/Export";

import gsap from "gsap";
import { Tag } from "antd";

function DropdownAnimationComponent({
  children,
  image,
  title,
  description,
  close,
}) {
  const [show, setShow] = useState(false);
  const el = useRef(null);
  const box = useRef(null);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(()=>{
    if(close){
      setShow(false);
    }
  },[close])

  useEffect(() => {
    const tl = gsap.timeline();

    tl.to(el.current, { rotation: show ? "+=180" : "0" }).to(
      box.current,
      {
        maxHeight: show ? box.current.scrollHeight : 0,
        autoAlpha: show ? 1 : 0,
        ease: "power4.out",
        duration: 1,
      },
      0
    );
  }, [show]);

  return (
    <div>
      <div className="bg-white relative mb-2 z-10 py-[27px] px-[30px] flex gap-10 items-center rounded-2xl ">
        <div>
          <img src={image} alt="Icon" />
        </div>
        <div className="flex flex-col gap-4 flex-1">
          <div className="font-bold text-xl text-[#01050F]">{title}</div>
          <div className="font-normal text-sm text-[#8692A6]">
            {description}
          </div>
        </div>
        <div>
          {
            close && <Tag color="success">Done</Tag>
          }
        </div>
        <div
          className={`${close?"cursor-not-allowed":"cursor-pointer"}`}
          onClick={() => {
            if (!close) {
              clearTimeout(timeoutId);
              setTimeoutId(
                setTimeout(() => {
                  setShow(!show);
                }, 200)
              );
            }
          }}
          ref={el}
        >
          <DropdonwIcon />
        </div>
      </div>
      <div
        className={`bg-white rounded-2xl`}
        ref={box}
        style={{ overflow: "hidden" }}
      >
        {children}
      </div>
    </div>
  );
}

export default DropdownAnimationComponent;
