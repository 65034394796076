import React, { useEffect, useRef, useState } from "react";
import {
  CancelledSidebar,
  ClosedSidebar,
  CompletedSidebar,
  MessageSideBar,
  ScheduleIcon,
  ToggleDown,
  UnassignedSidebar,
  UpcomingSlider,
} from "../../../../Assets/Export/Export";
import gsap from "gsap";
import { CANCELLED, COMPLETED, SCHEDULE, TOTAL_BOOKINGS, UPCOMING } from "../../../../Constants/Routes/RoutesConstant";
import { Link, useLocation } from "react-router-dom";

function Bookings() {
  const [active, setActive] = useState(true);
  const livetoggle = useRef(null);
  const ToggleIcon = useRef(null);
  const location = useLocation();
  const handleChange = () => {
    setActive((active) => !active);
  };

  useEffect(() => {
    const twin = gsap.to(livetoggle.current, {
      duration: 0.5,
      height: active ? "auto" : 0,
      ease: "power3.inOut",
      autoAlpha: active ? 1 : 0,
      opacity: active ? 1 : 0,
    });
    const twin2 = gsap.to(ToggleIcon.current, {
      duration: 0.5,
      rotate: active ? 0 : 180,
      ease: "power3.inOut",
    });
    return () => {
      twin2.kill();
      twin.kill();
    };
  }, [active]);
  const DrodpownOption = [
    {
      icon:<div className="mx-1"><ScheduleIcon /></div>,
      name:"Schedule",
      url: SCHEDULE,
      active: location.pathname === SCHEDULE,
      
    },
    {
      icon: <UpcomingSlider/> ,
      name: "Upcoming",
      message: 1,
      url: UPCOMING,
      active: location.pathname === UPCOMING,
    },
    {
      icon: <CompletedSidebar/>,
      name: "Completed",
      message: 3,
      url:COMPLETED,
      active:location.pathname === COMPLETED
    },
    {
      icon: <CancelledSidebar/>,
      name: "Cancelled",
      message: 4,
      url:CANCELLED,
      active:location.pathname===CANCELLED
    },
    
  ];
  return (
    <>
      <div className="px-[20px] py-[20px]">
        <div className="flex  items-center gap-2 mb-3 cursor-pointer">
          <div className={`font-medium text-base text-[#ACB8CB]`}>Bookings</div>
          <div
            ref={ToggleIcon}
            onClick={handleChange}
            className="flex justify-center items-center"
          >
            {" "}
            <ToggleDown />
          </div>
        </div>
        <div>
          <div  ref={livetoggle} className="flex flex-col gap-2">
            {DrodpownOption.map((item, index) => {
              return (
                <Link to={item.url} key={index}>
                <div
                  key={index}
                  className={`${
                    item.active ? "bg-[#E1EEFF0D]" : ""
                  } cursor-pointer rounded flex py-[8px] px-[17px] justify-between w-full items-center gap-2`}
                >
                  {item.icon}
                  <div
                    className={`font-bold flex-1 text-sm ${
                      item.active ? "text-[#fff]" : "text-[#ACB8CB]"
                    }`}
                  >
                    {item.name}
                  </div>
                  {/* <div className="bg-[#E71333] rounded-full w-[30px] h-[20px] flex justify-center items-center">
                    <div className="font-normal text-[12px] text-[#fff]">
                      {item.message}
                    </div>
                  </div> */}
                </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Bookings;
