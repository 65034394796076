import React from "react";
import BackgroundComponent from "../../../Components/AuthComponent/BackgroundComponent";
import { Button, Divider, Form, Input, message } from "antd";
import { GoogleIcon } from "../../../Assets/Export/Export";
import { useForm } from "antd/es/form/Form";
import axios from "axios";

function Login() {
  const [form] = Form.useForm();
  const onFinish = async() => {
    try{
      const res = await axios.post("/user/login/",{
        ...form.getFieldsValue()
      })
      localStorage.setItem("token",res.data.user_token);
      localStorage.setItem("user_id",res.data.user_id);
      localStorage.setItem("user_name",res.data.user_name);
      localStorage.setItem("name",res.data.name);
      localStorage.setItem("email",res.data.email);
      localStorage.setItem("widget_client_web_id",res.data.client_web_id);
      localStorage.setItem("role",res.data.role);
      localStorage.setItem("client_id",res.data.client_id);
      localStorage.setItem("progress",res.data.onboarding_progress.progress);
      localStorage.setItem("base64Image",res.data.image_base64)
      window.location.href = "/";
      message.success("Login Successfully");
    }catch(err){
     message.error(err.response.data.error);
    }
  };


  return (
    <>
      <BackgroundComponent>
        <div className="flex justify-center items-center flex-col">
          <div className="text-[#19334E] font-bold text-[29px]">Log In</div>
          <Form
            form={form}
            layout="vertical"
            className="mt-10"
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
                // {
                //   type: "email",
                //   message: "The input is not valid E-mail!",
                // },
              ]}
            >
              <Input
                className="login-input login-input-text"
                placeholder="Email Address"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
                
              ]}
            >
              <Input.Password
                className="login-input login-input-text"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="bg-[#19334E] w-full h-[50px]"
              >
                Log In
              </Button>
            </Form.Item>
            {/* <Form.Item>
            <Button
            
              className="border-0 text-[#0766FF] w-full h-[50px]"
            >
              Forgot Password ?
            </Button>
          </Form.Item>
          <Divider>
            <span className="text-[#bababa]">or</span>
          </Divider>
          <Button
            icon={<GoogleIcon />}
            className="h-[50px] rounded-md boxShadowGoogle flex justify-center items-center w-full"
          >
            Register With Google
          </Button> */}
          </Form>
          {/* <div className="text-[#bababa] mt-10 font-medium text-sm">
          Don't have an account?
          <span className="text-[#0766FF] underline"> Sign Up</span>
        </div> */}
        </div>
      </BackgroundComponent>
    </>
  );
}

export default Login;
