import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/AppComponent/Navbar/Navbar";
import Conversation from "../../../Components/AppComponent/Conversation/Conversation";
import { Button, Dropdown } from "antd";
import Profile from "../../../Assets/Conversation/Circle.png";
import ChatInput from "../MyConversation/ChatInput/ChatInput";
import ProfileTab from "../../../Components/AppComponent/ProfileTab/ProfileTab";
import { fetchConversations } from "../../../Redux/Slice/conversationSlice";
import { useDispatch, useSelector } from "react-redux";
import ConversationComponent from "../../../Components/AppComponent/ConversationComponent/ConversationComponent";
import { Filter, LeaveConversation, MessageCheck, MessageCheckRead } from "../../../Assets/Export/Export";
import { LeaveConversations } from "../../../Utils/LeaveConversatio";
import { setSelectChat } from "../../../Redux/Slice/selectedConversation";
import ProfileDrawer from "../../../Components/AppComponent/ProfileDrawer/ProfileDrawer";
import { SolvedConversation } from "../../../Utils/SolvedConversation";
import { Input } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import Icon from "@ant-design/icons";
import AssignModal from "../../../Components/AppComponent/AssignModal/AssignModal";
import { LoadingOutlined } from "@ant-design/icons";
import { RightOutlined } from "@ant-design/icons";
import axios from "axios";

function ClosedConversationOriginal() {
  const dispatch = useDispatch();
  const conversation = useSelector((state) => state.conversation);
  const selectChat = useSelector((state) => state.selectChat);
  const [visible, setVisible] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [clear, setClear] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [filter, setFilter] = useState("");
  const ClientInformation = useSelector(
    (state) => state?.Integration?.client_information
  );
  const [type, setType] = useState("allFilter");
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [tagLoading, setTagLoading] = useState(false);
  const [tagData, setTagData] = useState([])
  const [assigneeData, setAssigneeData] = useState([])


  const avatarMenu = (()=>{


    if (type === 'tag') {
      return <>
        <Menu>
          {
            tagLoading ? <LoadingOutlined /> :
             <>
             
              {
                tagData.map((item, index) => {
                  return <Menu.Item
                    key={index}
                    onClick={() => {
                      setFilter(item.name);
                    }}
                    className={`${filter === item.name ? "bg-slate-400 classForFilter" : ""} `}
                  >
                    <span
                      className={`d-flex align-items-center ${filter === item.name ? " " : ""
                        } `}
                    >
                      {item.name}
                    </span>
                  </Menu.Item>
                })
              }
             </>
          }
        </Menu>
      </>
    } else if (type === 'assignee') {
      return <>
        <Menu>
          {
            tagLoading ? <LoadingOutlined /> :
             <>
             
              {
                assigneeData.map((item, index) => {
                  return <Menu.Item
                    key={index}
                    onClick={() => {
                      setFilter(item.name);
                    }}
                    className={`${filter === item.name ? "bg-slate-400 classForFilter" : ""} `}
                  >
                    <span
                      className={`d-flex align-items-center ${filter === item.name ? " " : ""
                        } `}
                    >
                      {item.name}
                    </span>
                  </Menu.Item>
                })
              }
             </>
          }
        </Menu>
      </>
    } else {
      return <Menu>
      <Menu.Item
        onClick={() => {
          setFilter("");
        }}
        className={`${filter === "" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          All Conversation
        </span>
      </Menu.Item>

      {/* <Menu.Item
        onClick={() => {
          setFilter("closed");
        }}
        className={`${filter === "closed" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "closed" ? " " : ""
          } `}
        >
          Closed Conversation
        </span>
      </Menu.Item> */}

      <Menu.Item
        onClick={() => {
          setFilter("FACEBOOK");
        }}
        className={`${filter === "FACEBOOK" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "FACEBOOK" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Facebook
        </span>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          setFilter("INSTAGRAM");
        }}
        className={`${filter === "INSTAGRAM" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "INSTAGRAM" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Instagram
        </span>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          setFilter("WHATSAPP");
        }}
        className={`${filter === "WHATSAPP" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "WHATSAPP" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Whatsapp
        </span>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          setFilter("EMAIL");
        }}
        className={`${filter === "EMAIL" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "EMAIL" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Email
        </span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setFilter("TELEGRAM");
        }}
        className={`${filter === "TELEGRAM" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "TELEGRAM" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Telegram
        </span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setFilter("WEB");
        }}
        className={`${filter === "WEB" ? "bg-slate-400 classForFilter" : ""} `}
      >
        <span
          className={`d-flex align-items-center ${
            filter === "WEB" ? " " : ""
          } `}
        >
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Web
        </span>
      </Menu.Item>
      <Menu.Item
          onClick={() => {
            getTags()
            // setFilter("WEB");
          }}
        // className={`${filter === "WEB" ? "bg-slate-400" : ""} `}
        >
          <span
            className={`d-flex align-items-center `}
          >
            {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
            <div className="flex justify-between items-center"><div>Tags</div>
              <div><RightOutlined /></div>
            </div>
          </span>
        </Menu.Item>
    </Menu>
        }
      }
  );

  const leave = async () => {
    const res = await LeaveConversations(
      "un-assignment",
      selectChat?.selectedChat
    );

    if (selectChat?.selectedChat) {
      dispatch(setSelectChat({ selectedChat: null }));
    }
  };

  const searchFunction = (value) => {
    let data = [];
    conversation?.conversations?.forEach((item, index) => {
      if (
        item.status === "closed" &&
        item.chatbot_user.name.toLowerCase().includes(value.toLowerCase())
      ) {
        data.push(item);
      }
    });
    setSelectedConversation(data);
  };

  const [selectedConversation, setSelectedConversation] = useState([]);
  useEffect(() => {
    let data = [];
    conversation?.conversations?.forEach((item, index) => {
      let flag = false;
      (item.flags.forEach((items)=>{
        if(items.name== filter){
          flag= true;          
        }
      }))

      if(item.agent?.name== filter){
        flag = true
      }
    
      if (item.status === "closed" && 
      (filter === "" || item.channel === filter || flag)
    ) {
      data.push(item);
    }
    });
    setSelectedConversation(data);
  }, [conversation,filter]);

  const getTags = async () => {
    setType("tag");
    setTagLoading(true);
    try{
      const res = await axios.get('conversation/flags/');
      setTagData(res.data);

    }catch(e){
      console.log(e);
    }
   
    setTagLoading(false);


  }


  return (
    <>
      <div className="flex h-full">
        <div className="w-[300px] flex flex-col">
        <div>
            <div
              className={`bg-white  flex justify-between items-center p-[24px]`}
              style={{
                borderBottom: "1px solid #E6E8EA",
              }}
            >
          {!searchActive ? (
            <>
              <div className="text-[#01050F] text-xl font-semibold flex justify-center items-center gap-3">
                <div>Closed</div>
              </div>
              <div className="flex gap-3">
                <div className="cursor-pointer">
                  <SearchOutlined
                    className="text-xl"
                    onClick={() => {
                      setSearchActive(true);
                    }}
                  />
                </div>
                <Dropdown
                      className="mr-3"
                      overlay={avatarMenu}
                      trigger={["click"]}
                      visible={filterDropdown}
                      onVisibleChange={(event) => {
                        setFilterDropdown(event)
                        if (event === false) {
                          setType("allFilter")
                        }
                      }}
                    >
                      <Icon component={Filter} />
                    </Dropdown>
              </div>
            </>
          ) : (
            <>
              <div className="flex gap-7">
                <Input
                  onChange={(e) => {
                    clearTimeout(clear);
                    setClear(
                      setTimeout(() => {
                        searchFunction(e.target.value);
                      }, 1000)
                    );
                  }}
                  className="w-full h-full flex-1"
                  placeholder="Search in Inbox"
                  style={{
                    border: "none",
                    borderBottom: "1px solid #E6E8EA",
                    width: "220px",
                  }}
                />

                <CloseOutlined
                  onClick={() => {
                    setSearchActive(false);
                  }}
                  className="cursor-pointer"
                />
              </div>
            </>
          )}
        </div>
        </div>
          <div className="h-[100%] overflow-x-hidden ">
            <div className="flex flex-col overflow-y-auto sidebar-scroll h-[100%] bg-[#fff] gap-4 ">
            <>
                {selectedConversation.length !== 0 ? (
                  <>
                    {selectedConversation.map((item, index) => {
                      return <Conversation key={index} {...item} />;
                    })}
                  </>
                ) : (
                  <div className="w-full h-full flex justify-center items-center">
                    You have no conversations at the moment
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
        <div className="flex-1 flex-col flex h-full">
          <div className="">
            <div
              className="bg-white p-[20px] flex justify-between"
              style={{
                border: "1px solid #E6E8EA",
              }}
            >
              <div className="flex justify-between w-full">
                <div className="flex gap-2">
                  {
                    <>
                      {selectChat?.selectedChat && (
                        <Button
                          className="flex justify-center items-center gap-1"
                          onClick={() => {
                            SolvedConversation(
                              "open",
                              selectChat?.selectedChat
                            );
                          }}
                        >
                          <MessageCheck />
                          Mark as Open
                        </Button>
                      )}

                      {/* {selectChat?.recordId ==
                      localStorage.getItem("user_id") ? (
                        <Button
                          onClick={() => {
                            leave();
                          }}
                          icon={<LeaveConversation />}
                        >
                          Leave Conversation
                        </Button>
                      ) : (
                        ""
                      )} */}
                         {selectChat?.selectedChat &&  <Button
                          className="flex justify-center items-center gap-1"
                          onClick={() => {
                            setModalVisible(true)
                          }}
                        >
                          <MessageCheckRead />
                          Assign Conversation
                        </Button>}
                    </>
                  }
                </div>
                <div
            onClick={()=>{
              setVisible(true)
            
            }}
            className="w-[43px] h-[43px] rounded-full relative cursor-pointer"
          >
            <img style={{
              cursor: "pointer",
              width:"100%",
              borderRadius:"100%"
            }} src={localStorage.getItem("base64Image")?localStorage.getItem("base64Image"):`https://api.dicebear.com/7.x/initials/svg?seed=${ClientInformation?.name}`}/>
          </div>
              </div>
            </div>
          </div>
          {selectChat?.selectedChat ? (
            <ConversationComponent />
          ) : (
            <div className="flex justify-center items-center h-full flex-col">
              <div className="text-[#000] font-bold text-lg">
                Welcome to Closed Conversation! We’re glad you’re here.
              </div>
              <div className="text-[#242424] text-sm">
                Please select a chat from chat sidebar.{" "}
              </div>
            </div>
          )}
        </div>
        <ProfileDrawer visible={visible} setVisible={setVisible} />
        <AssignModal visible={modalVisible} setVisible={setModalVisible} conversation_id={selectChat?.selectedChat} />
      </div>
    </>
  );
}

export default ClosedConversationOriginal;
