import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  conversations: [],
  
};

const conversationSlice = createSlice({
  name: "conversation",
  initialState: initialState,
  reducers: {
    setConversations(state, action) {
      console.log(action.payload)
      return {
        ...state,
        conversations: action.payload,
      };
    },
 
  },
});

export const {
  setConversations

} = conversationSlice.actions;


export default conversationSlice.reducer;

