import { Modal, Select, message } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'

function AssignModal({ visible, setVisible, conversation_id }) {
  const [loading, setLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  const [agentId, setAgentId] = useState(null);

  const getAgentData = async (id) => {

    try {
      const res = await axios.get(`/user/agent?client_id=${id}`);
      setAgents(res.data.agents);
    } catch (err) {
      message.error("Error fetching data");
    }
  };
  const id = localStorage.getItem("client_id");
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {

      getAgentData(id)
      setAgentId(null);
      
    }
  }, [visible]);


  const handleAgentAssign = async () => {
    try {
      setLoading(true);
      const res = await axios.post("/conversation/transfer_to_agent/", {
        conversation_id: conversation_id,
        agent_to_be_assigned: agentId
      });
      message.success("Agent assigned successfully");
      setVisible(false);
      setLoading(false);
      setAgentId(null);
    } catch (err) {
      message.error("Error assigning agent");
      setLoading(false);
    }
  }
  return (
    <div>
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        title="Assign Agent"
        //  confirmLoading={loading}
        centered
        onOk={() => {
          handleAgentAssign();
        }}
        className="modal-for-padding"
      >
        <Select value={agentId} onChange={(e)=>{
          setAgentId(e);
        }}  className='w-full' placeholder="Select Agent">
          {agents.map((agent) => {
            return <Select.Option value={agent.record_id}>{agent.name}</Select.Option>
          })}
        </Select>

      </Modal>
    </div>
  )
}

export default AssignModal