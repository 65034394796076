import React, { useEffect, useState } from "react";
import ChatInput from "../../../Views/AppViews/MyConversation/ChatInput/ChatInput";
import ProfileTab from "../ProfileTab/ProfileTab";
import { message } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchConversations,
  setConversations,
} from "../../../Redux/Slice/conversationSlice";
import { setSelectChat } from "../../../Redux/Slice/selectedConversation";
import { CalendarIcon } from "../../../Assets/Export/Export";
import AddEvent from "../../../Views/AppViews/Schedule/AddEvent/AddEvent";

function ConversationComponent() {
  const selectChat = useSelector((state) => state.selectChat);
  const [event,setEvent] = useState(false);
  // const [chatHistory, setChatHistory] = useState([]);
  // const [conversationType, setConversationType] = useState({});
  const ChatConversation = useSelector((state) => state.ChatConversation);
  const conversation = useSelector((state) => state.conversation);
  const dispatch = useDispatch();



  return (
    <>
      <div className="flex h-full ">
        <ChatInput
          chatHistory={ChatConversation?.conversations}
          conversationType={ChatConversation?.conversationType}
          loadingForChat={ChatConversation?.loadingForChat}
          name={selectChat.name}
        />

        <div
          className="w-[300px]  flex flex-col gap-2"
          style={{
            border: "1px solid #E6E8EA",
          }}
        >
          <div className="p-2 w-full bg-[#fff]">
            <div>
              <ProfileTab conversation={ChatConversation?.conversationType} />
            </div>
          </div>
          <div onClick={()=>{
            setEvent(true)
          }}  className="p-4 w-full bg-[#fff] flex items-center gap-2 cursor-pointer ">
           <div> <CalendarIcon /></div>
           <div>Add a Booking</div>
          </div>
        </div>
        <AddEvent visible={event} setVisible={setEvent} conversation_id={ChatConversation?.conversationType}/>
      </div>
    </>
  );
}

export default ConversationComponent;
