import React from "react";
import Navbar from "../../../Components/AppComponent/Navbar/Navbar";
import { ChatPageExport } from "../../../Assets/Export/Export";
import { Card } from "antd";
import ChatComponent from "../../../Components/AppComponent/ChatComponent/ChatComponent";

function ChatPage() {
  return (
    <div>
      <Navbar
        text={
          <div className="flex gap-3 items-center justify-center">
            <div>
              <ChatPageExport />
            </div>
            <div>Chat Page</div>
          </div>
        }
      />

        <div className="flex gap-3 p-5">
        <Card className="w-[48%]" >
        <div >

        </div>
        </Card>
        <div className="w-[48%]">
            <ChatComponent/>
        </div>
        </div>
    

    </div>
  );
}

export default ChatPage;
