import React, { useState } from "react";
import Instagram from "../../../../Assets/Integration/Group (2).png";
import Navbar from "../../../../Components/AppComponent/Navbar/Navbar";
import { Button, Card, Divider, Input, message } from "antd";
import { CheckOutlined, ClockCircleOutlined } from "@ant-design/icons";
import MainImage from "../../../../Assets/Integration/instagramintegration.png";
import First from "../../../../Assets/Integration/div.framer-yfbble.png";
import Second from "../../../../Assets/Integration/div.framer-yfbble (1).png";
import Third from "../../../../Assets/Integration/div.framer-yfbble (2).png";
import { UserInfo } from "../../../../Assets/Export/Export";
import axios from "axios";
import { useEffect } from "react";

function InstagramIntegration() {
  const [data, setData] = useState({});
  const [loggedIn,setLoggedIn] = useState(false);

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "332976796379617",
        cookie: true,
        xfbml: true,
        version: "v2.7",
        // config_id: '1337308220312436',
        // response_type: "code",
        // override_default_response_type: true,
        // scope:
        //   "",
      });

      window.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
      // $(".fwb").textContent = "connect";
    })(document, "script", "facebook-jssdk");
  }, []);

  function checkLoginState() {
    window.FB.getLoginStatus(function (response) {
      console.log(response);
      statusChangeCallback(response);
    });
  }

  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = window.jQuery.trim(cookies[i]);
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  function handleClick() {
    // window.FB.login(());
    window.FB.login(
      function(resp){
          checkLoginState(resp);
      }.bind(this),{ scope : 'instagram_basic,instagram_manage_messages,pages_manage_metadata,business_management' });
  }

  async function handleInstagramDisconnect (){
    try{
      const res = await axios.post("core/disconnect/instagram/",{
        client_id:localStorage.getItem("client_id"),
      });
      message.success("Instagram Disconnected Successfully")
    }catch(err){
      message.error("Cannot Disconnect")
    }
  }
  function statusChangeCallback(response,page=false) {
    console.log("statusChangeCallback");
    console.log(response);
    let pageData = {}
    if(page){
      let pageData = {
        selected_page_name: page
      }
    }
    
    window.$.ajax({
      async: false,
      url: "/api/core/connect/instagram/",
      data: {
        access_token: response.authResponse.accessToken,
        user_id: response.authResponse.userID,
        client_id:localStorage.getItem("client_id"),
        ...pageData
      },
      type: "POST",
      beforeSend: function (xhr) {
        // Show loading icon
        xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
      },
      complete: function () {
        // Hide loading icon
      },
      success: function (dashboard_api_response) {
        console.log("dashboard_api_response", dashboard_api_response);
        if (
          dashboard_api_response &&
          dashboard_api_response.status === "page_name_required"
        ) {
          var pages = dashboard_api_response.pages; // ['page_name_1', 'page_name_2']
          statusChangeCallback(response,pages[0]);
          return;
        }
        if (dashboard_api_response && dashboard_api_response.status === "ok") {
          // Refresh the page
          getData();
          return;
        }
        console.log("Error connecting the instagram account. Please try again");
      },
      error: function (xhr, ajaxOptions, thrownError) {
        alert("Some exception occurred. Please try again");
      },
    });
  }
  const getData = async () => {
    try {
      const res = await axios.get(
        "user/client/integration/status?channel=INSTAGRAM"
      );
      setData(res.data.data);
      if(res.data.data.status!=="Pending"){
        setLoggedIn(true)
      }
    } catch (err) {
      message.error("Error while fetching data");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Navbar text={"Instagram Integration"} image />
      <div className="p-3">
        <Card
          title={
            <div className="flex gap-1 items-center">
              <div>
                <img src={Instagram} />
              </div>
              <div>Instagram Integration</div>
            </div>
          }
        >
          {loggedIn? (
            <div className="w-full">
              <div className="flex gap-2 bg-[#E8F2FF] w-[70%] p-3 items-center">
                {data?.status === "Approved" ? (
                  <>
                    <div className="text-[#fff] bg-[#58cb6a] rounded-full w-6 h-6 flex justify-center items-center">
                      <CheckOutlined />
                    </div>
                    <div className="text-base font-bold">
                      Congratulations! Your Instagram has been seamlessly
                      integrated with SellsSwift
                    </div>
                  </>
                ) : (
                  <>
                    <div className={`rounded-full text-xl flex justify-center items-center ${loggedIn?"text-[#58cb6a]":"text-[#ffeb66] "}`}>
                      {loggedIn?<CheckOutlined/>:<ClockCircleOutlined />}
                    </div>
                    <div className="text-base font-bold">
                      Instagram integration {loggedIn ? "Done":"pending for approval. Contact Support"}
                    </div>
                  </>
                )}
              </div>
              <div className="mt-5">
                <div className="flex items-center">
                  <UserInfo /> Account
                </div>
                <Divider />
                <div className="flex flex-col gap-3">
                  <div className="flex gap-2">
                    <div className="w-[20%] font-bold">
                      Instagram Account Name
                    </div>
                    <Input value={data?.instagram_account_name} disabled />
                  </div>

                  <div className="flex gap-2">
                    <div className="w-[20%] font-bold">Integration Status</div>
                    <div
                      className={`text-left font-bold ${
                        data?.status === "Connected"
                          ? "text-[#58cb6a]"
                          : "text-red-700"
                      }`}
                    >
                      {data?.status === "Connected" ? data?.status : "Pending"}
                    </div>
                  </div>
                </div>
                <Divider />
                <Button type="primary" onClick={handleInstagramDisconnect} className="bg-[#19334E]" >Cancel Integration</Button>
              </div>
            </div>
          ) : (
            <>
              <div className="flex justify-center w-[70%] m-auto  flex-col items-center gap-5">
                <div>
                  <img src={MainImage} />
                </div>
                <div className="font-bold text-base">
                  Integrate Instagram with SellSwift 
                </div>
                <div className="font-normal text-sm text-center">
                  "Streamline your Instagram chats within your SellSwift inbox,
                  effortlessly responding to customer queries. Minimise platform
                  hopping for increased focus and productivity."
                </div>
                <div className="flex gap-3 justify-center">
                  <div className="text-center w-[33%]">
                    <div>
                      <img src={First} />
                    </div>
                    <div>Respond to all private messages from customers</div>
                  </div>
                  <div className="text-center w-[33%]">
                    <div>
                      <img src={Second} />
                    </div>
                    <div>
                      Ensure responses to customer messages are addressed within
                      a 24-hour timeframe.
                    </div>
                  </div>
                  <div className="text-center w-[33%]">
                    <div>
                      <img src={Third} />
                    </div>
                    <div>
                      Enhance your efficiency with automated bots streamlining
                      everyday conversations.
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-8">
                <Button
                  className="w-[30%] m-auto bg-[#19334E]"
                  type="primary"
                  onClick={() => {
                    handleClick();
                    // setActive(1)
                  }}
                >
                  Integrate Instagram
                </Button>
              </div>
            </>
          )}
        </Card>
      </div>
    </>
  );
}

export default InstagramIntegration;
