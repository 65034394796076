import React, { useEffect, useRef, useState } from "react";
import {
  CancelledSidebar,
  ClosedSidebar,
  CompletedSidebar,
  MessageSideBar,
  ScheduleIcon,
  ToggleDown,
  UnassignedSidebar,
  UpcomingSlider,
} from "../../../../Assets/Export/Export";
import gsap from "gsap";
import { ASSIGNEE_AGENT, CANCELLED, COMPLETED, SCHEDULE, TOTAL_BOOKINGS, UPCOMING } from "../../../../Constants/Routes/RoutesConstant";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { setSelectChat } from "../../../../Redux/Slice/selectedConversation";
import { useDispatch } from "react-redux";

function Assignee() {
  const [active, setActive] = useState(true);
  const livetoggle = useRef(null);
  const ToggleIcon = useRef(null);
  const dispatch = useDispatch();
  const [agents, setAgents] = useState([]);
  const location = useLocation();
  const handleChange = () => {
    setActive((active) => !active);
  };


  const getAgentData = async (id) => {

    try {
      const res = await axios.get(`/user/agent?client_id=${id}`);
      const data = res.data.agents;
      setAgents(()=>{
        return data.map((item)=>{
          return({
            icon: `https://api.dicebear.com/7.x/initials/svg?seed=${item?.name}`,
            name: item?.name,
            url: `${ASSIGNEE_AGENT}/${item?.name.split(" ")[0]}/${item?.record_id}`,
            active: location.pathname.includes(item?.name.split(" ")[0])
          })
        })
      })
    } catch (err) {
      message.error("Error fetching data");
    }
  };

  const id = localStorage.getItem("client_id");
  useEffect(() => {
    getAgentData(id)
  },[])

  useEffect(()=>{
    if(location.pathname.includes(ASSIGNEE_AGENT)){
      setAgents((agents)=>{
        return agents.map((item)=>{
          return({
            ...item,
            active:location.pathname.includes(item?.name.split(" ")[0])
          })
      })})
    }else{
      setAgents((agents)=>{
        return agents.map((item)=>{
          return({
            ...item,
            active:false
          })
      })})
    }
  
  },[location.pathname])


  useEffect(() => {
    const twin = gsap.to(livetoggle.current, {
      duration: 0.5,
      height: active ? "auto" : 0,
      ease: "power3.inOut",
      autoAlpha: active ? 1 : 0,
      opacity: active ? 1 : 0,
    });
    const twin2 = gsap.to(ToggleIcon.current, {
      duration: 0.5,
      rotate: active ? 0 : 180,
      ease: "power3.inOut",
    });
    return () => {
      twin2.kill();
      twin.kill();
    };
  }, [active]);
 
  return (
    <>
      <div className="px-[20px] py-[20px]">
        <div className="flex  items-center gap-2 mb-3 cursor-pointer">
          <div className={`font-medium text-base text-[#ACB8CB]`}>Assignee</div>
          <div
            ref={ToggleIcon}
            onClick={handleChange}
            className="flex justify-center items-center"
          >
            {" "}
            <ToggleDown />
          </div>
        </div>
        <div>
          <div  ref={livetoggle} className="flex flex-col gap-2">
            {agents.map((item, index) => {
              return (
                <Link to={item.url} key={index} onClick={()=>{
                  dispatch(
                    setSelectChat({
                      selectedChat: null,
                      name: null,
                      recordId: null,
                      status: null
                    })
                  );
                }}>
                <div
                  key={index}
                  className={`${
                    item.active ? "bg-[#E1EEFF0D]" : ""
                  } cursor-pointer rounded flex py-[8px] px-[17px] justify-between w-full items-center gap-2`}
                >
                  <img style={{
                    width:"25px",
                    height:"25px",
                    borderRadius:"100%"
                  }} src={item.icon}/>
                  <div
                    className={`font-bold flex-1 text-sm ${
                      item.active ? "text-[#fff]" : "text-[#ACB8CB]"
                    }`}
                  >
                    {item.name}
                  </div>
                  {/* <div className="bg-[#E71333] rounded-full w-[30px] h-[20px] flex justify-center items-center">
                    <div className="font-normal text-[12px] text-[#fff]">
                      {item.message}
                    </div>
                  </div> */}
                </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Assignee