import React, { useEffect, useState } from "react";
import { QuestionMark } from "../../../../Assets/Export/Export";
import { Button, Card, Divider, Tooltip, message } from "antd";
import Files from "../../../../Assets/Hub/files.png"
import Website from "../../../../Assets/Hub/website urls.png"
import { CHATBOTWIDGET, DATASOURCE, HUB, SUCCESSFULLYANSWERED, TRANSFER, UNANSWERED } from "../../../../Constants/Routes/RoutesConstant";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setRouteChatBot } from "../../../../Redux/Slice/ChatBotSlice";
import Navbar from "../../../../Components/AppComponent/Navbar/Navbar";

function Hub() {
  const navigate = useNavigate();
  const [Label,setLabel] = useState([
    {
      color: " bg-[#3d8dae]",
      text: "Successfully Answered",
      tooltip:"Number of inquiries where SellSwift furnished responses.",
      viewNow:()=>{
        navigate(SUCCESSFULLYANSWERED);
      }
    },
    {
        color:"bg-[#17494D]",
        text:"Unanswered",
        tooltip:"Number of inquiries where SellSwift fell short in delivering responses",
        viewNow:()=>{
          navigate(UNANSWERED);
        }
    },
    {
        color:"bg-[#587052]",
        text:"Transferred to operator",
        tooltip:"Number of inquiries where visitors requested to connect with a representative.",
        viewNow:()=>{
          navigate(TRANSFER);
        }
    },
    {
        color:"bg-[#AE3D73]",
        text:"Conversation limit",
    }
  ]);

  const getData = async () => {
    try{
      const response = await axios.get("conversation/metrics/summary/");
      console.log(response.data);
      const temp = Label;
      temp[0].number = response.data?.successful_messages;
      temp[1].number = response.data?.unanswered_messages;
      temp[2].number = response.data?.transferred_conversations;
      temp[3].number = "Unlimited";

      setLabel([...temp]);
    }catch(err){
      message.error("Something went wrong")
    }
  }

  const dispatch = useDispatch();

  useEffect(()=>{
    getData();
  },[])

  return (
    <>
    <Navbar text="Hub" image/>
    <div className="flex flex-col gap-3 w-full p-4">

      <div className="flex justify-between">
    
      </div>
      <div className="flex justify-between">
        {
            Label.map((item,index)=>{
                return(
                    <div className={`${item.color} p-3 w-[23%] text-[#fff] flex gap-3 flex-col rounded`}>
                    <div className="flex gap-2">
                      <div className="text-sm">{item.text}</div>
                    {
                        item.tooltip && <Tooltip title={item.tooltip}>
                        <div className="cursor-pointer">
                          <QuestionMark/>
                        </div>
                      </Tooltip>
                    }
                    </div>
                    <div className="text-2xl font-extrabold">{item.number}</div>
                    {item.viewNow && <div
                      className="cursor-pointer"
                      style={{
                        textDecoration: "underline",
                      }}
                      onClick={item.viewNow}
                    >
                      View Now
                    </div>}
                  </div>
                )
            })
        }
      </div>
      <Card>
        <div className="text-xl font-bold">Data sources management</div>
        <Divider/>
        <div>These information outlets serve as SellSwift informational foundation. SellSwift  will leverage the provided content synthesise responses.</div>
        <div className="mt-5">
            <div className="flex justify-between gap-3">
              <div>
                <img src={Files} alt="Files"/>
              </div>
              <div className="flex-1">
                <div className="text-base font-bold">Files</div>
                <div>Document Content</div>
              </div>
              <div
              
              >
               <Button   onClick={()=>{
                 navigate(DATASOURCE);
                }}>
                Manage
               </Button>
              </div>
            </div>
            <Divider/>
            <div className="flex justify-between gap-3">
              <div>
                <img src={Website} alt="Website"/>
              </div>
              <div className="flex-1">
                <div className="text-base font-bold">Website URLs</div>
                <div>Web Content</div>
                </div>
                <div>
               <Button  onClick={()=>{
                  // dispatch(setRouteChatBot("datasource"));
                  navigate(DATASOURCE+"?type=website")
                }}>
                Manage
               </Button>
              </div>
            </div>
        </div>
      </Card>
    </div>
    </>
  );
}

export default Hub;
