import React, { useEffect, useRef, useState } from "react";
import { DropdonwIcon } from "../../../../../../Assets/Export/Export";
import gsap from "gsap";

function AppearenceSelector({ children, icon, title }) {
  const [show, setShow] = useState(true);
  const el = useRef(null);
  const box = useRef(null);
  const [timeoutId, setTimeoutId] = useState(null);
  useEffect(() => {
    const tl = gsap.timeline();

    tl.to(el.current, { rotation: show ? "+=180" : "0" }).to(
      box.current,
      {
        maxHeight: show ? box.current.scrollHeight : 0,
        autoAlpha: show ? 1 : 0,
        ease: "power4.out",
        duration: 1,
      },
      0
    );
  }, [show]);

  return (
    <div>
      <div
        className="flex justify-between items-center gap-4 pb-3"
        style={{
          borderBottom: "1px solid #EAE7E7",
        }}
      >
        {icon}
        <div className="flex-1 font-semibold text-lg">{title}</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            clearTimeout(timeoutId);
            setTimeoutId(
              setTimeout(() => {
                setShow(!show);
              }, 200)
            );
          }}
          ref={el}
        >
          <DropdonwIcon />
        </div>
      </div>
      <div
        ref={box}
        style={{ overflow: "hidden" }}
      >
        {children}
      </div>
    </div>
  );
}

export default AppearenceSelector;
