import React from 'react';
import { CSVLink } from 'react-csv';
import { Button } from "antd";

import Icon from "@ant-design/icons"
import { FileExcelOutlined } from "@ant-design/icons"

const Csv = (props) => {
  const { header, data, filename, handleClick, loading, refs } = props;

  return (
    <>
      <CSVLink
        ref={refs}
        headers={header}
        data={data}
        filename={filename}
        target='_blank'
        style={{
          display: "none"
        }}
      />
      <Button className="d-flex align-items-center ml-2" onClick={handleClick} loading={loading} >

        <FileExcelOutlined /> Export
      </Button>


    </>
  )
}

export default Csv;