import React from "react";
import { Checkbox, Form, Input } from "antd";

function Steps1() {
  const RadioMap = [
    {
      id: 1,
      value: "Boost sales & automate sales",
    },
    {
      id: 2,
      value: "Lorem ipsum is dummy text",
    },
    {
      id: 3,
      value: "Improve customer support",
    },
    {
      id: 4,
      value: "Lorem ipsum is dummy text",
    },
    {
      id: 5,
      value: "Build automatic workflows with chatbots",
    },
    {
      id: 6,
      value: "Lorem ipsum is dummy text",
    },
  ];

  const RadioMap2 = [
    {
      id: 1,
      value: "Customer success lead",
    },
    {
      id: 2,
      value: "Marketing lead",
    },
    {
      id: 3,
      value: "Owner",
    },
    {
      id: 4,
      value: "Customer success manager",
    },
    {
      id: 5,
      value: "Other",
    },
  ];
  return (
    <div>
      <div className="flex flex-col justify-center items-center mt-7 gap-3">
        <div className="text-xl font-medium">
          Shape your experience-it will only take few steps.
        </div>
        <div className="text-3xl font-extrabold mt-6">
          Tell us more about you
        </div>
        <div className="w-[60%]">
          <div className="w-[49%]">
            <div className="text-xl font-bold mb-2">What's Your Name?</div>
            <Form.Item>
              <Input
                className="login-input-text p-[15px]"
                placeholder="First Name"
              />
            </Form.Item>
          </div>
          <div className="w-[100%]">
            <div className="text-xl font-bold mb-2">
              In SellSwift I primarily want to...?
            </div>
            <Form.Item>
              <div className="text-lg font-medium">
                <CheckboxGroup className="flex justify-between flex-wrap gap-4">
                  {RadioMap.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="bg-[#F5F7F9] rounded-md p-[15px] w-[49%]"
                      >
                        <Checkbox value={item.id}>{item.value}</Checkbox>
                      </div>
                    );
                  })}
                </CheckboxGroup>
              </div>
            </Form.Item>
          </div>
          <div className="w-[100%]">
            <div className="text-xl font-bold mb-2">
              What best describe your job role?
            </div>
            <Form.Item>
              <div className="text-lg font-medium">
                <CheckboxGroup className="flex justify-between flex-wrap gap-4">
                  {RadioMap2.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="bg-[#F5F7F9] rounded-md p-[15px] w-[49%]"
                      >
                        <Checkbox value={item.id}>{item.value}</Checkbox>
                      </div>
                    );
                  })}
                </CheckboxGroup>
              </div>
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Steps1;
const CheckboxGroup = Checkbox.Group;
