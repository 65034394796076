import React, { useEffect, useRef, useState } from "react";
import {
  ClosedSidebar,
  MessageCheck,
  MessageSideBar,
  MyConversation,
  ToggleDown,
  UnassignedSidebar,
} from "../../../../Assets/Export/Export";
import gsap from "gsap";
import moment from "moment";
import {
  ALLCONVERSATION,
  CLOSED,
  MY_CONVERSATION,
  UNASSIGNED,
} from "../../../../Constants/Routes/RoutesConstant";
import { Link, useLocation } from "react-router-dom";
import {
  fetchConversations,
  setConversations,
} from "../../../../Redux/Slice/conversationSlice";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import selectedConversation, {
  setSelectChat,
} from "../../../../Redux/Slice/selectedConversation";
import axios from "axios";
import {
  setConversationsForChat,
  setLoadingForChat,
  setMessages,
} from "../../../../Redux/Slice/ChatConversationslice";

function LiveConversation() {
  const [active, setActive] = useState(true);
  const livetoggle = useRef(null);
  const ToggleIcon = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const selectChat = useSelector((state) => state.selectChat);
  const conversation = useSelector((state) => state.conversation);
  const [newMessage, setNewMessage] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);

  const [unreadCounter, setUnreadCounter] = useState(false);
  const [DrodpownOption, setDropdownOption] = useState([
    {
      icon: <MyConversation />,
      name: "My Conversation",
      message: 1,
      // url: MY_CONVERSATION,
      // active: location.pathname === MY_CONVERSATION,
    },


    {
      icon: <UnassignedSidebar />,
      name: "Unassigned",
      message: 3,
      // url: UNASSIGNED,
      // active: location.pathname === UNASSIGNED,
    },
    {
      icon: <MessageSideBar />,
      name: "All Conversation",
      message: 4,
      // url: ALLCONVERSATION,
      // active: location.pathname === ALLCONVERSATION,
    },
    {
      icon: <ClosedSidebar />,
      name: "Closed",
      message: 0,
      // url: CLOSED,
      // active: location.pathname === CLOSED
    }

  ]);


  useEffect(() => {
    if (newMessage) {
      if (selectChat.selectedChat === newMessage?.conversation?.id) {
        if (newMessage.message.message_type === "request") {

        }
        dispatch(setMessages(newMessage.message))
        setNewMessage(false);
      }
    }
  }, [newMessage])



  useEffect(() => {
    if (unreadCounter) {

      // need to add functionality for counter
      const conversations = [];
      conversation?.conversations?.forEach((item) => {
        if (item.id == unreadCounter?.conversation?.id) {

          conversations.push(unreadCounter.conversation);
        } else {

          conversations.push(item);
        }
      })

      dispatch(setConversations(conversations));
      findCountersForLabel(conversations,true);
      setUnreadCounter(false);
    }
  }, [unreadCounter])

  const callDashboardSettingApi = async () => {
    try {
      const res = await axios.get("core/dashboard/settings")
      return ([res.data.dashboard_settings.show_my_unread_counter,res.data.dashboard_settings.show_unassigned_unread_counter,res.data.dashboard_settings.show_all_unread_counter,res.data.dashboard_settings.show_closed_unread_counter]) 
          // allConversation: res.data.dashboard_settings.show_all_unread_counter,
          // myConversation: res.data.dashboard_settings.show_my_unread_counter,
          // unassigned: res.data.dashboard_settings.show_unassigned_unread_counter,
          // closed: res.data.dashboard_settings.show_closed_unread_counter
      // })
  } catch (err) {
      message.error(err.response.data.message)
  }
  }

  const findCountersForLabel = async(conversations,callApi) => {
    let show = [0,0,0,0];
    if(callApi){
     show = await callDashboardSettingApi();
    }
    console.log(show)
    const counters = [0, 0, 0, 0];
    conversations.forEach((item) => {
      if (item.unread_counter > 0) {
        counters[2] = counters[2] + 1;
      }
      if (
        item.agent?.record_id == localStorage.getItem("user_id") && item.status!=="closed"
      ) {
        if (item.unread_counter > 0) {
          counters[0] = counters[0] + 1;
        }
      }
      if (!item.is_assigned && item.status!=="closed") {
        if (item.unread_counter > 0) {
          console.log(item)
          counters[1] = counters[1] + 1;
        }
      }
      if (item.status === "closed") {
        if (item.unread_counter > 0) {

          counters[3] = counters[3] + 1;
        }
      }

    })
    setDropdownOption((prev) => {
      return prev.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            message: counters[0],
            url: MY_CONVERSATION,
            active: location.pathname === MY_CONVERSATION,
            show: show[0]
          }
        }
        if (index === 1) {
          return {
            ...item,
            message: counters[1],
            url: UNASSIGNED,
            active: location.pathname === UNASSIGNED,
            show: show[1]
          }
        }
        if (index === 2) {
          return {
            ...item,
            message: counters[2],
            url: ALLCONVERSATION,
            active: location.pathname === ALLCONVERSATION,
            show: show[2]
          }
        }
        if(index === 3){
          return {
            ...item,
            message: counters[3],
            url: CLOSED,
            active: location.pathname === CLOSED,
            show: show[3]
          }
        }
        return item; 
      })
    })
  }

  const getData = async () => {
    try {
      const response = await axios.get("conversation/chat_listing/");
      dispatch(setConversations(response.data.conversations));
      findCountersForLabel(response.data.conversations,true);
      return response.data.conversations;
      // console.log(response.data);
    } catch (err) {
      // console.log(err);
      message.error("Failed to fetch data");
    }
  };

  const getChatHistory = async () => {
    dispatch(setLoadingForChat(true));
    try {
      const res = await axios.get(
        `/conversation/chat_history/?conversation_id=${selectChat.selectedChat}&batch=1&pageSize=40`
      );
      // console.log(res.data);
      dispatch(setConversationsForChat(res.data));
      dispatch(setLoadingForChat(false));
    } catch (err) {
      message.error("Failed to get chat history");
      dispatch(setLoadingForChat(false));
    }
  };

  useEffect(() => {
    if (selectChat?.selectedChat) {
      getChatHistory();
    }
  }, [selectChat]);


  useEffect(() => {

    if (updateStatus) {
      conversation?.conversations.forEach((item) => {
        if (item.id == selectChat.selectedChat) {
          dispatch(setSelectChat({
            ...selectChat,
            status: updateStatus.status
          }))
        }
      })
    }
  }, [updateStatus])

  const handleChange = () => {
    setActive((active) => !active);
  };

  const callUnreadCounter = async () => {
    try {
      const response = await axios.post("conversation/clear_unread_counter/", {
        conversation_id: selectChat.selectedChat
      });


    } catch (err) {
      message.error("Failed to fetch data");
    }

  }



  useEffect(() => {
    const fetchDataAndWebSocket = async () => {
      let conv = []
      conv = await getData();

      var client_socket = new WebSocket(
        `wss://app.sellswift.ai/ws/client_dashboard/${localStorage.getItem("client_id")}/`
      );
      client_socket.onopen = function () {
        console.log("Client socket opened successfully");
      };



      client_socket.onmessage = function (e) {
        var message = JSON.parse(e.data)["message"];

        if (message.type === "new_message") {

          // setChatHistory(prev => [...prev, message.message]);
          let check = false;
          conv.forEach((item) => {
            if (item.id == message.conversation.id) {
              check = true;
              return;
            }
          });


          const updatedConversations = conv.map((item) =>
            item.id == message.conversation.id ? message.conversation : item
          );
          //for pushing and updating the conversations
          setNewMessage(message);
          if (check) {
            // dispatch(setMessages(message));

          } else {
            updatedConversations.push(message.conversation);
          }
          // dispatching to convert

          // for sorting conversation
          updatedConversations.sort((a, b) => {
            const timeA = moment(a.latest_message_time, "HH:mm:ss DD-MM-YYYY");
            const timeB = moment(b.latest_message_time, "HH:mm:ss DD-MM-YYYY");
            return timeB.diff(timeA);
          });
          dispatch(setConversations(updatedConversations));

        }

        if (message.type === "assignment_update") {
          const updatedConversations = conv.map((item) => {
            if (item.id == message.conversation.id) {
              console.log(item.id, message.conversation.id)
              if (
                item.id == selectChat.selectedChat &&
                !message.conversation.is_assigned
              ) {
                dispatch(
                  setSelectChat({
                    selectedChat: null,
                    name: null,
                    recordId: null,
                    status: null
                  })
                );
              } else {
                // getChatHistory();
                dispatch(
                  setSelectChat({
                    selectedChat: message.conversation.id,
                    name: message.conversation?.chatbot_user?.name,
                    recordId: message.conversation.agent?.record_id,
                    status: message.conversation.status,
                  })
                );
              }
              if (message.conversation.status === "closed") {
                dispatch(
                  setSelectChat({
                    selectedChat: null,
                    name: null,
                    recordId: null,
                    status: null
                  })
                );
              }
              return message.conversation;
            }
            return item;
          });
          dispatch(setConversations(updatedConversations));
        }

        if (message.type === "status_change") {
          const updatedConversations = conv.map((item) => {
            if (item.id == message.conversation.id) {
              return message.conversation;
            }
            return item;
          });
          setUpdateStatus(message.conversation);
          dispatch(
            setSelectChat({
              selectedChat: null,
              name: null,
              recordId: null,
              status: null
            })
          );
          dispatch(setConversations(updatedConversations));
        }
        if (message.type === "unread_counter_update") {
          setUnreadCounter(message);
        }
      };

      client_socket.onclose = function (e) {


        console.error("Client socket closed unexpectedly");
      };

      return () => {
        client_socket.close();
      };

    };

    // Call the async function
    fetchDataAndWebSocket();

  }, []);

  const itemActive = (data)=>{
    return location.pathname === data.url
  }

  useEffect(() => {
    const twin = gsap.to(livetoggle.current, {
      duration: 0.5,
      height: active ? "auto" : 0,
      ease: "power3.inOut",
      autoAlpha: active ? 1 : 0,
      opacity: active ? 1 : 0,
    });
    const twin2 = gsap.to(ToggleIcon.current, {
      duration: 0.5,
      rotate: active ? 0 : 180,
      ease: "power3.inOut",
    });
    return () => {
      twin2.kill();
      twin.kill();
    };
  }, [active]);
  return (
    <>
      <div className="px-[20px] py-[20px]">
        <div className="flex  items-center gap-2 mb-3 cursor-pointer">
          <div className={`font-medium text-base text-[#ACB8CB]`}>
            Live Conversation
          </div>
          <div
            ref={ToggleIcon}
            onClick={handleChange}
            className="flex justify-center items-center"
          >
            {" "}
            <ToggleDown />
          </div>
        </div>
        <div>
          <div ref={livetoggle} className="flex flex-col gap-2">
            {DrodpownOption.map((item, index) => {
              return (
                <Link
                  to={item.url}
                  key={index}
                  onClick={() => {
                    dispatch(
                      setSelectChat({
                        selectedChat: null,
                        name: null,
                        recordId: null,
                        status: null
                      })
                    );
                  }}
                >
                  <div
                    className={`${ itemActive(item) ? "bg-[#E1EEFF0D]" : ""
                      } cursor-pointer rounded flex py-[8px] px-[17px] justify-between w-full items-center gap-2`}
                  >
                    {item.icon}
                    <div
                      className={`font-bold flex-1 text-sm ${itemActive(item)  ? "text-[#fff]" : "text-[#ACB8CB]"
                        }`}
                    >
                      {item.name}
                    </div>
                   {
                    item.message> 0 && item.show === true &&
                    <div className="bg-[#E71333] rounded-full w-[30px] h-[20px] flex justify-center items-center">
                    <div className="font-normal text-[12px] text-[#fff]">
                      {item.message}
                    </div>
                  </div>
                   }
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default LiveConversation;
