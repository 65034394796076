import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  color: "linear-gradient(90deg, #2731DE, #05B9FC)",
};

const appearenceSlice = createSlice({
  name: "setting",
  initialState: initialState,
  reducers: {
    setColor(state, action) {
      state.color = action.payload;
    },
  },
});

export const { setColor } = appearenceSlice.actions;
export default appearenceSlice.reducer;
