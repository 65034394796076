import { Button, Input, Select, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
import {
  BuildingInfo,
  CrossIcon,
  EmailInfo,
  LocationInfo,
  PhoneInfo,
  TagInfo,
  UserInfo,
} from "../../../Assets/Export/Export";
import axios from "axios";
import {
  setConversationsForChat,
  setLoadingForChat,
} from "../../../Redux/Slice/ChatConversationslice";
import { useDispatch } from "react-redux";
import { connectDisconnectFlag } from "../../../Utils/connectDisconnectFlag";
import { setConversations } from "../../../Redux/Slice/conversationSlice";

function ProfileTab({ conversation }) {
  const [data, setData] = useState([]);
  const [notes, setNotes] = useState("");
  const [notesArray, setNotesArray] = useState([

  ]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (conversation.id) {
      getNotes();
    }
    if (conversation?.flags) {
      getData();
    }
  }, [conversation]);

  const getNotes = async () => {
    try {
      const res = await axios.get(`conversation/notes/?conversation_id=${conversation.id}`);
      console.log(res.data);
      setNotesArray(res.data.notes?JSON.parse(res.data.notes):[]);
    } catch (err) {
      message.error("Failed to fetch notes");
    }
  }


  const postNotes = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`conversation/notes/`, {
        conversation_id: conversation.id,
        notes: JSON.stringify([...notesArray, notes])
      });
      getNotes();
      setNotes('');
      message.success("Note added successfully");
      // setNotes([]);
      // console.log(res.data);
    } catch (err) {
      message.error("Failed to post notes");
    }
    setLoading(false);
  }

  const postNotesArray = async (notesArrayFunction) => {
    setLoading(true);
    try {
      const res = await axios.post(`conversation/notes/`, {
        conversation_id: conversation.id,
        notes: JSON.stringify([...notesArrayFunction])
      });
      // setNotes([]);
      getNotes();
      setNotes('');
      message.success("Note Deleted successfully");
      // console.log(res.data);
    } catch (err) {
      message.error("Failed to post notes");
    }
    setLoading(false);
  }

  const getDataListing = async () => {
    try {
      const response = await axios.get("conversation/chat_listing/");
      dispatch(setConversations(response.data.conversations));
      // findCountersForLabel(response.data.conversations,true);
      // return response.data.conversations;
      // console.log(response.data);
    } catch (err) {
      // console.log(err);
      message.error("Failed to fetch data");
    }
  };

  const getChatHistory = async () => {
    dispatch(setLoadingForChat(true));
    try {
      const res = await axios.get(
        `/conversation/chat_history/?conversation_id=${conversation.id}`
      );
      // console.log(res.data);
      dispatch(setConversationsForChat(res.data));
      dispatch(setLoadingForChat(false));
    } catch (err) {
      message.error("Failed to get chat history");
      dispatch(setLoadingForChat(false));
    }
  };

  const getData = async () => {
    try {
      const res = await axios.get("conversation/flags/");
      // setData(res.data);
      const res1 = res.data;

      setData(() => {
        return res1.filter(
          (item) =>
            !conversation.flags
              .map((flag) => flag.record_id)
              .includes(item.record_id)
        );
      });
    } catch (err) {
      message.error(err.response.data.message);
    }
  };

  return (
    <div>
      <Tabs defaultActiveKey="1" >
        <Tabs.TabPane tab="Info" key="1">
          <div className="flex flex-col gap-2 p-2">
            <div className="flex items-center gap-3 font-medium text-base">
              <UserInfo />
              <div className="text-sm">{conversation?.chatbot_user?.name}</div>
            </div>
            {conversation?.chatbot_user?.email && (
              <div className="flex items-center gap-3 font-medium text-base">
                <EmailInfo />
                <div className="text-sm">
                  {conversation?.chatbot_user?.email}
                </div>
              </div>
            )}
            {/* <div className="flex items-center gap-3 font-medium text-base">
              <LocationInfo />
              <div>Bedok, Singapore</div>
            </div> */}
            {conversation?.chatbot_user?.whatsapp_id && (
              <div className="flex items-center gap-3 font-medium text-base">
                <PhoneInfo />
                <div className="text-sm">
                  {conversation?.chatbot_user?.whatsapp_id}
                </div>
              </div>
            )}
            {/* <div className="flex items-center gap-3 font-medium text-base">
              <BuildingInfo />
              <div>acme co</div>
            </div> */}
            <div className="flex items-start gap-3 font-medium text-base ">
              <TagInfo />
              <div className="">
                <div className="text-sm flex gap-2 flex-wrap">
                  {conversation?.flags?.map((item) => (
                    <div
                      style={{
                        backgroundColor: item.color,
                        // display: 'inline-block',
                      }}
                      className={` px-2 rounded-md flex items-center gap-2`}
                    >
                      <div key={item.record_id} style={{}}>
                        {item.name}
                      </div>
                      <div className="cursor-pointer" onClick={async() => {
                        const res = await connectDisconnectFlag(
                          item.record_id,
                          conversation.id,
                          "disconnect"
                        );
                        if (res) {
                          getChatHistory();
                          getDataListing();
                        }
                      }}>
                        <CrossIcon /></div>
                    </div>
                  ))}
                </div>
                <div>
                  <Select
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    bordered={false}
                    style={{
                      padding: "0px",
                    }}
                    placeholder="Add a Tag..."
                    value={[]}
                    onChange={async(value) => {
                      const res = await connectDisconnectFlag(
                        value,
                        conversation.id,
                        "connect"
                      );
                      if (res) {
                        getChatHistory();
                        getDataListing();
                      }
                    }}
                  >
                    {data.map((item) => (
                      <Select.Option key={item.id} value={item.record_id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Notes(${notesArray.length})`} key="2">
          <div className="flex gap-1 flex-col">
            <div>
              <Input.TextArea placeholder="Add Note" style={{
                resize: 'none'
              }} value={notes} onChange={(e) => {
                setNotes(e.target.value);
              }} />
            </div>
            <div>
              <Button type="primary" disabled={notes == ''} loading={loading} onClick={postNotes}>+</Button>
            </div>
            <div className="flex flex-col gap-1">
              {
                notesArray.map((item) => (
                  <div className="flex gap-1">
                    <div className="flex gap-2 flex-1">
                      <Input.TextArea style={{
                        resize: 'none'
                      }} value={item} disabled />

                    </div>
                    <div style={{
                      cursor: 'pointer'
                    }} onClick={() => {
                      setNotesArray(notesArray.filter((note) => note !== item));
                      postNotesArray(notesArray.filter((note) => note !== item));
                    }}>
                      <DeleteOutlined />
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default ProfileTab;
