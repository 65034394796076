import { Layout } from "antd";
import React, { useEffect, useRef } from "react";
import { All_Booking_Confirmed, CollapsedMenu, Manual_Booking, Total_Booking } from "../../../Assets/Export/Export";
import gsap from "gsap";
import { ALL_BOOKINGS_CONFIRMED, MANUAL_BOOKING, TOTAL_BOOKINGS } from "../../../Constants/Routes/RoutesConstant";
import { Link, useLocation } from "react-router-dom";

function CollapsedSidebarDashboard({ collapsed, setCollapsed }) {
  const option = useRef(null);

  useEffect(() => {
    if (collapsed) {
      const twin = gsap.to(`.sidebar${collapsed}`, {
        duration: 0.5,
        width: 0,
        ease: "power3.inOut",
        autoAlpha: 0,
        opacity: 0,
      });

      option.current.style.display = "none";
      return () => {
        twin.kill();
      };
    } else {
      const twin = gsap.to(`.sidebar${collapsed}`, {
        duration: 0.4,
        width: 220,
        ease: "power3.inOut",
        fontSize: "auto",
        autoAlpha: 1,
        onComplete: () => {
          option.current.style.display = "block";
        },
      });

      return () => {
        twin.kill();
      };
    }
  }, [collapsed]);

  const location = useLocation();

  const DrodpownOption = [
    {
      name: "Total Bookings",
      url: TOTAL_BOOKINGS,
      active: location.pathname === TOTAL_BOOKINGS,
      icon:Total_Booking
    },
    {
      name:"All Bookings",
      url:ALL_BOOKINGS_CONFIRMED,
      active: location.pathname === ALL_BOOKINGS_CONFIRMED,
      icon:All_Booking_Confirmed
    },
    {
      name:"Manual Bookings",
      url:MANUAL_BOOKING,
      active: location.pathname === MANUAL_BOOKING,
      icon:Manual_Booking
    }
  ]

  return (
    <div className={`sidebar${collapsed} bg-[#21324E] flex-col flex `} collapsed={false}>
      <div className="flex justify-between items-center p-[19px] chat-collapsed">
        <div className="text-lg font-bold text-[#fff]">Dashboard</div>
        <div
          className="cursor-pointer "
          onClick={() => {
            setCollapsed((collapsed) => !collapsed);
          }}
        >
          <CollapsedMenu />
        </div>
      </div>
      <div ref={option} className="sidebar-scroll" style={{height:"100vh"}}>
      <div className="px-[20px] py-[20px]">
          <div  className="flex flex-col gap-2">
            {DrodpownOption.map((item, index) => {
              return (
                <Link
                  to={item.url}
                  key={index}
                 
                >
                  <div
                    className={`${
                      item.active ? "bg-[#E1EEFF0D]" : ""
                    } cursor-pointer rounded flex py-[8px] px-[17px] justify-between w-full items-center gap-2`}
                  >
                     {<item.icon color={item.active ? "#189CFC" : "#8692A6"} />}
                    <div
                      className={`font-bold flex-1 text-sm ${
                        item.active ? "text-[#fff]" : "text-[#ACB8CB]"
                      }`}
                    >
                      {item.name}
                    </div>
                    {/* <div className="bg-[#E71333] rounded-full w-[30px] h-[20px] flex justify-center items-center">
                      <div className="font-normal text-[12px] text-[#fff]">
                        {item.message}
                      </div>
                    </div> */}  
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        {/* <Channel/> */}
      </div>
    </div>
  );
}

export default CollapsedSidebarDashboard;
