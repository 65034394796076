import Card from "antd/es/card/Card";
import React, { useEffect, useState } from "react";
import { GearShapeWithoutColor } from "../../../../Assets/Export/Export";
import { Button, Form, Input, Modal, Select, Switch, message } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import Navbar from "../../../../Components/AppComponent/Navbar/Navbar";

function Configure() {
  const [form] = Form.useForm();
  const [starters, setStarters] = useState([]);
  const [instruction, setInstruction] = useState(true);
  const [password, setPassword] = useState("");
  const [instructionModal, setInstructionModal] = useState(false);
  const [conversation, setConversation] = useState("");
  const [address, setAddress] = useState("");
  const [locationArray, setLocationArray] = useState([]);

  const getData = async () => {
    try {
      const response = await axios.get("/core/training_model/");
      console.log(response);
      form.setFieldsValue({
        name: response.data.training_model.name,
        description: response.data.training_model.description,
        instructions: response.data.training_model.instructions,
        get_data_from_internet:
          response.data.training_model.get_data_from_internet,
        chatbot_type: response.data.training_model.chatbot_response_type,
        size_of_response: response.data.training_model.size_of_response,
        unrecognized_response:
          response.data.training_model.unrecognized_response,
        conversation_closing_time:
          response.data.training_model.conversation_closing_time,
        closing_message: response.data.training_model.closing_message,
        closing_confirm_message:
          response.data.training_model.closing_confirm_message,
        closing_reject_message:
          response.data.training_model.closing_reject_message,
        booking_confirmation_message:
          response.data.training_model.booking_confirmation_message,
        booking_prompt_message:
          response.data.training_model.booking_prompt_message,
          appointment_message :response.data.training_model.appointment_message 
      });

      setStarters(response.data.training_model.opening_messages_list);
      setLocationArray(response.data.training_model.booking_locations_list);
    } catch (err) {
      message.error("Error while fetching data");
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await axios.post("/core/training_model/", {
        name: values.name,
        description: values.description,
        closing_confirm_message: values.closing_confirm_message,
        closing_reject_message: values.closing_reject_message,
        unrecognized_response: values.unrecognized_response,
        instructions: values.instructions,
        get_data_from_internet: values.get_data_from_internet,
        chatbot_response_type: values.chatbot_type,
        size_of_response: values.size_of_response,
        opening_messages_list: starters,
        conversation_closing_time: values.conversation_closing_time,
        booking_locations_list: locationArray,
        closing_message: values.closing_message,
        booking_prompt_message: values.booking_prompt_message,
        booking_confirmation_message: values.booking_confirmation_message,
        appointment_message:values.appointment_message 
      });
      message.success("Data saved successfully");
    } catch (err) {
      message.error("Error while saving data");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="flex flex-col gap-3">
      <Navbar text="Configure ChatBot" image />
      <div className="w-full h-full  flex justify-between px-5 ">
        <Card className="w-full h-[95%]">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="w-[80%]"
          >
            {/* <Form.Item label="Name" name={"name"}>
              <Input placeholder="Name your ChatBot" />
            </Form.Item> */}
            <Form.Item label="Description" name={"description"}>
              <Input placeholder="Describe your ChatBot" />
            </Form.Item>
            <Form.Item
              label="Unrecognized Response"
              name={"unrecognized_response"}
            >
              <Input placeholder="Enter Unrecognized Response" />
            </Form.Item>
            <Form.Item
              label={
                <div className="w-full">
                  <div className="flex justify-between w-full gap-14">
                    <div>Instruction</div>
                    <div className="cursor-pointer" onClick={()=>{
                      setInstructionModal(true)
                    }}>
                      <EditOutlined />
                    </div>
                  </div>
                </div>
              }
              name={"instructions"}
            >
              <Input.TextArea
                disabled={instruction}
                style={{
                  height: "300px",
                  resize: "none",
                }}
                placeholder="Write instructions for your ChatBot"
              />
            </Form.Item>
            <Form.Item
              label="Get Data From Internet"
              name="get_data_from_internet"
            >
              <Switch defaultChecked={true} />
            </Form.Item>
            <Form.Item label="ChatBot Type" name={"chatbot_type"}>
              <Select placeholder="Select Chatbot Type">
                <Select.Option value="casual">Casual</Select.Option>
                <Select.Option value="formal">Formal</Select.Option>
                <Select.Option value="semi_formal">Semi Formal</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="ChatBot Response Limit" name={"size_of_response"}>
              <Select placeholder="Select ChatBot Limit">
                <Select.Option value="small">Small (20 Words)</Select.Option>
                <Select.Option value="medium">Medium (50 Words)</Select.Option>
                <Select.Option value="large">Large (100 Words)</Select.Option>
                <Select.Option value="no_limit">No Limit</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Appointment Message"
              name={"appointment_message"}
              rules={[
                {
                  required: true,
                  message: "Please enter the appointment message",
                },
              ]}
            >
              <Input placeholder="Enter Apppointment Message" />
            </Form.Item>
            <Form.Item
              label="Booking Prompt Message"
              name={"booking_prompt_message"}
              rules={[
                {
                  required: true,
                  message: "Please enter the booking prompt message",
                },
              ]}
            >
              <Input placeholder="Enter Booking Promp Message" />
            </Form.Item>
            <Form.Item
              label="Booking Confirmation Message"
              name={"booking_confirmation_message"}
              rules={[
                {
                  required: true,
                  message: "Please enter the booking confirmation message",
                },
              ]}
            >
              <Input placeholder="Enter Booking Confirmation Message" />
            </Form.Item>

            <Form.Item
              label="Conversation Closing Time"
              name={"conversation_closing_time"}
              rules={[
                {
                  required: true,
                  message: "Please enter the conversation closing time",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "Please enter a valid number",
                },
              ]}
            >
              <Input suffix={"Minutes"} placeholder="Enter In Minutes" />
            </Form.Item>
            <Form.Item
              label="Closing Message"
              name={"closing_message"}
              rules={[
                {
                  required: true,
                  message: "Please enter the closing message",
                },
              ]}
            >
              <Input placeholder="Add Closing Message" />
            </Form.Item>
            <Form.Item
              label="Closing Confirm Message"
              name={"closing_confirm_message"}
              rules={[
                {
                  required: true,
                  message: "Please enter the Confirm Closing Message",
                },
              ]}
            >
              <Input placeholder="Confirm Closing Message" />
            </Form.Item>
            <Form.Item
              label="Closing Reject Message"
              name={"closing_reject_message"}
              rules={[
                {
                  required: true,
                  message: "Please enter the Closing Reject Message",
                },
              ]}
            >
              <Input placeholder="Closing Reject Message" />
            </Form.Item>
            <Form.Item
              label="Conversation Starters"
              // name={"add_new_response"}
            >
              <div className="flex">
                <Input
                  onChange={(e) => {
                    setConversation(e.target.value);
                  }}
                  value={conversation}
                  placeholder="Add Conversation Starters"
                />
                <Button
                  onClick={(e) => {
                    setStarters([...starters, conversation]);
                    setConversation("");
                  }}
                  type="primary"
                  disabled={conversation === "" ? true : false}
                >
                  <PlusOutlined />
                </Button>
              </div>
            </Form.Item>
            <div className="flex flex-col gap-3">
              {starters.map((item, index) => {
                return (
                  <div className="flex">
                    <Input value={item} disabled />
                    <Button
                      onClick={() => {
                        setStarters(starters.filter((_, i) => i !== index));
                      }}
                      type="danger"
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                );
              })}
            </div>

            <Form.Item
              className="mt-5"
              label="Location"
              // name={"add_new_response"}
            >
              <div className="flex">
                <Input
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  value={address}
                  placeholder="Add Location"
                />
                <Button
                  onClick={(e) => {
                    setLocationArray([...locationArray, address]);
                    setAddress("");
                  }}
                  type="primary"
                  disabled={address === "" ? true : false}
                >
                  <PlusOutlined />
                </Button>
              </div>
            </Form.Item>
            <div className="flex flex-col gap-3">
              {locationArray.map((item, index) => {
                return (
                  <div className="flex">
                    <Input value={item} disabled />
                    <Button
                      onClick={() => {
                        setLocationArray(
                          locationArray.filter((_, i) => i !== index)
                        );
                      }}
                      type="danger"
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                );
              })}
            </div>
          </Form>
        </Card>
      </div>
      <div className=" px-5 py-4">
        <Button
          onClick={() => {
            form.submit();
          }}
          type="primary"
          className="bg-[#19334E]"
        >
          Save
        </Button>
      </div>
      <Modal
        title="Password"
        visible={instructionModal}
        className="modal-for-padding"
        onOk={() =>{
          if(password === "reddotinnovative123"){
            setInstructionModal(false)
            setInstruction(false)
            setPassword("")
          }
          else{
            message.error("Incorrect Password")
          }
        
        }}
        onCancel={() => setInstructionModal(false)}
      >
        <div>
          <div>Enter Password</div>
          <Input
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Configure;
