import React from "react";
import Navbar from "../../../Components/AppComponent/Navbar/Navbar";
import { useSelector } from "react-redux";
import DashboardSidebar from "./DashboardSidebar.js/DashboardSidebar";
import { DatePicker, Select } from "antd";
import BarChart from "../../../Components/AppComponent/BarChart/BarChart";

function Dashboard() {
  return (
    <>
      <Navbar text="Dashboard" image />
      <div className="w-full flex h-[88%] justify-between">
        <div className="w-full h-full p-4 pb-10 flex flex-col gap-3 ">
          <div className="flex gap-3">
            <DatePicker.RangePicker />
            <Select className="w-36" placeholder="All Channel" />
            <Select className="w-36" placeholder="Any Assignee" />
          </div>
          <BarChart/>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
